import { FactoringStatus } from 'modules/domain/company/types'
import { Comment } from 'views/components/Comments/hooks'

export enum DispatchingTypes {
  open = 'open',
  closed = 'closed',
}

export enum DispatchingStatuses {
  reconciling = 'reconciling',
  rate_reconciled = 'rate_reconciled',
  contract_signed = 'contract_signed',
  documents = 'documents',
  search = 'search',
  cpt = 'cpt',
  closed = 'closed',
  urgent = 'urgent',
  pause = 'pause',
  car_restrictions = 'car_restrictions',
  no_car_needed = 'no_car_needed',
}

export enum PaymentStatus {
  advance_payment = 'advance_payment',
  received_payment_info = 'received_payment_info',
  raiffeisen = 'raiffeisen',
  absolute = 'absolute',
  ak_bars = 'ak_bars',
  tinkoff = 'tinkoff',
  bank_factoring_payment_set = 'bank_factoring_payment_set',
  paid = 'paid',
  not_unloaded = 'not_unloaded',
  ak_bars_factoring = 'ak_bars_factoring',
  kam_kom_bank = 'kam_kom_bank',
}

export interface PaymentInfoItemType {
  value: PaymentStatus
  color: string
  borderColor?: string
  checkMarkColor?: string
}

export interface DispatchingItem {
  id: string
  status: DispatchingStatuses
  price_exw: number
  trips_expected: number | null
  payments: {
    [x: string]: {
      amount: number
      status: string
    }[]
  }
}

export interface DispatchingItemFormik {
  status: DispatchingStatuses | undefined
  price_exw: number | undefined
  trips_expected: number | null | undefined
  payments: {
    [x: string]: {
      amount: number
      status: string
    }[]
  }
}
export type DispatchingType = {
  id: string
  deal_id: string
  product_title: string
  status: DispatchingStatuses
  seller: {
    factoring_status: FactoringStatus
    id: string
    inn: string
    name: string
    state_reg_number: string
  }
  seller_address_title: string
  customer: {
    factoring_status: FactoringStatus
    id: string
    inn: string
    name: string
    state_reg_number: string
  }
  customer_address_title: string
  quantity: number
  planned_remaining_quantity: number
  price_exw: number
  cost: number
  remaining_cost: number
  logistician: {
    id: string
    is_active: boolean
    full_name: string
    phone: string
  }
  logisticians: {
    id: string
    is_active: boolean
    full_name: string
    phone: string
  }[]
  comments: Comment[]
  quantity_paid_for: number
  loaded_quantity: number
  delivered_quantity: number
  remaining_quantity: number
  average_quantity_percent_per_day: number
  trips_today: number
  trips_expected: number
  trips_tomorrow: number
  logistics_request_id: number
  specification_deadline: string
  purchase_specification_id: number
  without_documents_request: boolean
  without_purchase_specification: boolean
  documents_request_expected_complete_date: string
  payments: Record<string, { amount: number; status: PaymentStatus }>
}
