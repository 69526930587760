import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const TableWrapper = styled.div`
  margin-top: 1px;

  td.ant-table-cell,
  th.ant-table-cell {
    padding: 10px 12px 11px 12px !important;
    font-size: 14px !important;
  }

  .ant-pagination {
    display: flex;
    align-items: center;
    margin-top: 8px !important;
    ${Typography.bodyRegular};

    .ant-pagination-total-text {
      display: none;
    }

    li:not(.ant-pagination-item-active) > a {
      color: ${defaultTheme.color.textPrimary900};

      &:hover {
        color: ${defaultTheme.color.primary600};
      }
    }

    li.ant-pagination-item {
      min-width: 24px;
      height: 24px;
      border-radius: 4px;
      margin: 0;
      background-color: transparent;
    }

    li.ant-pagination-item-active {
      border-color: ${defaultTheme.color.primary50a};
      background-color: ${defaultTheme.color.primary16a};

      &:hover {
        background-color: ${defaultTheme.color.primary16a};
      }

      & > a {
        ${Typography.accentLarge};
        color: ${defaultTheme.color.primary600};
      }
    }

    li.ant-pagination-prev,
    li.ant-pagination-next,
    li.ant-pagination-disabled {
      width: 24px;
      min-width: 24px;
      height: 24px;
      margin: 0;
      background-color: transparent !important;

      &:hover {
        background-color: transparent;
      }
    }

    li.ant-pagination-disabled {
      & > svg > path {
        fill: ${defaultTheme.color.textTertiary30a};
      }
    }

    .ant-pagination-item-ellipsis {
      top: 5px;
    }

    .ant-pagination-item-link-icon {
      margin-top: 10px;
    }
  }
`
