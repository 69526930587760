import { ProfileType, User } from 'modules/domain/user/types'
import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'
import { AButton } from 'views/components/Analytics'
import { Icons, Text } from '@agro-club/agroclub-shared'
import { Card } from 'views/components/Card/Card'
import { refetchFunc } from 'modules/domain/common/hooks'
import { Column } from '../styled'
import { UserTraderModal } from './UserTraderModal'

type Props = {
  user: User
  refetch: refetchFunc
}

export const UserTrader: FC<Props> = ({ user, refetch }) => {
  const { t } = useTranslation('user')
  const [showModal, setShowModal] = useState(false)

  if (user.profile?.profile_type !== ProfileType.customer) {
    return
  }

  let tagsJsx

  if (!user.profile.trader) {
    tagsJsx = (
      <div style={{ marginTop: '4px' }}>
        <AButton
          id="addTraderForUser"
          Icon={Icons.IconAdd}
          intent="approve"
          size="medium"
          onClick={() => setShowModal(true)}
        >
          {t('common:add')}
        </AButton>
      </div>
    )
  } else {
    tagsJsx = (
      <Card.Container bordered>
        {user.profile.trader.full_name}
        <Card.EditButton onClick={() => setShowModal(true)} />
      </Card.Container>
    )
  }

  return (
    <>
      <Column>
        <Text color="secondary">{t('assignedTrader.title')}</Text>
        {tagsJsx}
      </Column>
      {showModal && <UserTraderModal user={user} onClose={() => setShowModal(false)} refetch={refetch} />}
    </>
  )
}
