import {
  CarrierType,
  LogisticsKRLoadingWarehouse,
  LogisticsKRUnloadingWarehouse,
  LogisticStatuses,
} from 'views/pages/LogisticsKR/types'
import { Region } from '../collection/types'
import { Team } from '../teams/types'
import { Profile } from '../user/types'
import { DispatchingStatuses } from 'views/pages/Dispatching/types'

export type Product = {
  id: string
  name: string
}

type Company = {
  id: string
  name: string
}

export type Assignee = {
  id: string
  phone: string
  short_name: string
}

interface AssigneeKR extends Assignee {
  full_name: string
  coordinator_type: string
}

export type LogisticRequest = {
  id: string
  deal_id: string
  created: string
  quantity: number
  distance: number
  customer: Customer
  source: string
  is_closed: boolean
  loading_company: Company
  unloading_company: Company
  price_logistics: number
  logistics_status: LogisticStatuses
  product: Product
  loading_warehouse: LogisticsKRLoadingWarehouse
  unloading_warehouse: LogisticsKRUnloadingWarehouse
  trip_remained_quantity: number
  executors: AssigneeKR[]
  assignee?: AssigneeKR
  assigned_team?: Team
  days_for_export: number
  cars_needed_count: number
  new_trip_offers_count: number
  trips_on_approval_count: number
  trips_count: number
  trips_loaded_quantity: number
  trips_unloaded_quantity: number
  deal_dispatching_status: DispatchingStatuses
  start_date: string
  close_date: string
}

export enum DealType {
  direct = 'direct',
  agent = 'agent',
  forwarding_company = 'forwarding_company',
}

export type BaseCompany = {
  id: string
  inn: string
  name: string
  phone: string
  contact_name?: string
  working_regions?: Region[]
  favorite_regions?: Region[]
}

export type Customer = BaseCompany
export type Carrier = BaseCompany & {
  is_in_charter: boolean
  favorite_regions: Region[]
  carrier_type: CarrierType
  has_vat: boolean
}

export interface Employee {
  id: string
  phone: string
  email: string
  is_active: boolean
  profile: Profile | null
}
