import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { CloseDealModalContentWrapper } from './styled'
import FormSelectShared from '../FormSelectShared'

import { endpoints } from 'modules/endpoints'
import { SharedModalContent } from '../SharedModal'
import { FormikFieldsType } from 'modules/domain/types'

interface Props {
  formik: FormikFieldsType
  value: string
}

export const CloseDealModalContent: FC<Props> = ({ formik, value }) => {
  const { t } = useTranslation('deal')

  return (
    <SharedModalContent>
      <CloseDealModalContentWrapper>
        {t('closeDealModal.text')}
        <FormSelectShared
          formik={formik}
          value={formik.values[value]}
          endpoint={endpoints.dealsCloseReasons()}
          fieldName={value}
          simpleApiFormat
          showSearch={false}
          getLabel={(dto) => dto.label}
          hideLabel
          disableRightPadding
          allowClear={false}
        />
      </CloseDealModalContentWrapper>
    </SharedModalContent>
  )
}
