import { ClientPaginationSmall } from 'views/components/ClientSmallPagination/ClientPaginationSmall'
import { AntTag, Progress, Spinner, Text } from '@agro-club/agroclub-shared'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { useSmsHistoryManager } from './useSmsHistoryManager'
import { SpinnerWrapper } from 'views/styled/common'
import { User } from 'modules/domain/user/types'
import { SmsInfo } from './SmsInfo'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Filters } from './Filters'
import { FC } from 'react'

const Tags = styled.div`
  display: flex;
  gap: 0 8px;
  margin-bottom: 8px;
`
const Content = styled.div`
  width: 100%;
`

type Props = {
  user: User
}

export const SmsHistory: FC<Props> = ({ user }) => {
  const { t } = useTranslation()
  const { data, progress, listRequestParams, updateFilterState, total, setListRequestParams } = useSmsHistoryManager(
    user.id,
  )

  const formatDate = useFormatDate()

  return (
    <>
      <Filters listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
      {progress === Progress.WORK ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <div>
          {!!data.length ? (
            data.map((item) => {
              return (
                <BorderedItem hover={false} key={item.id}>
                  <Content>
                    <Tags>
                      {!!item.statuses.length && <AntTag color="white">{item.statuses[0].status_label}</AntTag>}
                      <AntTag color="white">{formatDate(item.created, true)}</AntTag>
                    </Tags>
                    <SmsInfo product={item.product_title} tableData={item.message_parameters} />
                  </Content>
                </BorderedItem>
              )
            })
          ) : (
            <BorderedItem hover={false} justifyContent="center">
              <Text color="secondary">{t('common:not_found')}</Text>
            </BorderedItem>
          )}
        </div>
      )}
      <ClientPaginationSmall
        setCurrPage={(page) => setListRequestParams((prev) => ({ ...prev, page }))}
        currPage={listRequestParams.page}
        total={total}
      />
    </>
  )
}
