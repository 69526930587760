import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RightPanelComments } from 'views/components/Comments/styled'
import { CommentType } from 'modules/domain/comment/types'
import { CommonInfo } from './CommonInfo/CommonInfo'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { AntCollapse, Panel, Progress } from '@agro-club/agroclub-shared'
import AntCollapseHeader from 'views/components/AntCollapseHeader/AntCollapseHeader'
import { endpoints } from 'modules/endpoints'
import { AssigneesAccordion } from './AssigneesAccordion'
import AComments from 'views/components/Comments/AComments'
import { refetchFunc } from 'modules/domain/common/hooks'
import { SummaryComments } from 'views/components/AntCollapseHeader/SummaryComments'
import { SummaryAssigneeTeam } from './SummaryAssigneeTeam'
import { FilesTtn } from 'views/pages/LogisticsKR/LogisticRequests/RequestDetails/components/FilesTtn/FilesTtn.tsx'
import { TtnDocument } from 'views/pages/LogisticsKR/types.ts'
import { ListRequestParams } from 'modules/domain/types.ts'
import { SummaryThereIsNo } from 'views/components/AntCollapseHeader/SummaryThereIsNo.tsx'

interface Props {
  request: LogisticRequest
  refetchRequest?: refetchFunc
  defaultActiveKey?: string
  withoutShadow?: boolean
  ttnList?: TtnDocument[]
  ttnListProgress?: Progress
  ttnListTotal?: number
  ttnListParams?: ListRequestParams
  setTtnListParams?: Dispatch<SetStateAction<ListRequestParams>>
  ttnListRefetch?: refetchFunc
  refetchTripsTable?: () => void
}

export const RequestDetailsAccordion: FC<Props> = ({
  request,
  refetchRequest,
  defaultActiveKey = 'comments',
  withoutShadow,
  ttnList,
  ttnListProgress,
  ttnListTotal,
  ttnListParams,
  setTtnListParams,
  ttnListRefetch,
  refetchTripsTable,
}) => {
  const { t } = useTranslation('logisticsKR')
  const [commentsSummary, setCommentsSummary] = useState<string>('')

  const options = useMemo(
    () =>
      [
        { value: 'comments', title: t('details.accordion.comments'), isShown: request?.deal_id },
        { value: 'info', title: t('details.accordion.info'), isShown: true },
        // { value: 'documents', title: t('details.accordion.documents') },
        { value: 'assignees', title: t('details.accordion.assignees'), isShown: true },
        {
          value: 'ttnFiles',
          title: t('details.accordion.ttnFiles'),
          isShown: ttnListParams,
        },
      ].filter((item) => item.isShown),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const [activeKey, setActiveKey] = useState<string | string[]>(options[0].value)
  const components = {
    comments: (
      <RightPanelComments>
        <AComments
          id={request.deal_id}
          type={CommentType.deal}
          url={endpoints.dealComment(request.deal_id)}
          maxHeight={400}
          popSummary={setCommentsSummary}
        />
      </RightPanelComments>
    ),
    info: <CommonInfo request={request} />,
    // documents: '',
    assignees: <AssigneesAccordion request={request} refetchRequest={refetchRequest} />,
    ttnFiles: (
      <FilesTtn
        fileList={ttnList as TtnDocument[]}
        setListRequestParams={setTtnListParams as Dispatch<SetStateAction<ListRequestParams>>}
        listRequestParams={ttnListParams as ListRequestParams}
        fileListProgress={ttnListProgress as Progress}
        total={ttnListTotal as number}
        refetch={ttnListRefetch as refetchFunc}
        refetchTripsTable={refetchTripsTable as () => void}
      />
    ),
  }

  const summary = {
    comments: <SummaryComments summary={commentsSummary} />,
    info: '',
    // documents: '',
    assignees: <SummaryAssigneeTeam assignee={request.assignee?.full_name} assignedTeam={request.assigned_team} />,
    ttnFiles: <SummaryThereIsNo hasData={!!ttnListTotal} />,
  }

  return (
    <div>
      <AntCollapse
        withoutShadow={withoutShadow}
        accordion
        bordered={false}
        defaultActiveKey={defaultActiveKey}
        onChange={(key) => setActiveKey(key)}
      >
        {options.map((option) => {
          return (
            <Panel
              key={option.value}
              header={
                <AntCollapseHeader title={option.title} summary={activeKey !== option.value && summary[option.value]} />
              }
            >
              {components[option.value]}
            </Panel>
          )
        })}
      </AntCollapse>
    </div>
  )
}
