import { defaultTheme } from '@agro-club/agroclub-shared'
import { PaymentInfoItemType, PaymentStatus } from './types'

export const PaymentInfoItems: PaymentInfoItemType[] = [
  {
    value: PaymentStatus.advance_payment,
    color: defaultTheme.color.backgroundSecondary,
    borderColor: defaultTheme.color.secondary16a,
    checkMarkColor: defaultTheme.color.textSecondary60a,
  },
  {
    value: PaymentStatus.received_payment_info,
    color: defaultTheme.color.accentApprove600,
  },
  {
    value: PaymentStatus.raiffeisen,
    color: '#FADB14',
    checkMarkColor: defaultTheme.color.textSecondary60a,
  },
  {
    value: PaymentStatus.absolute,
    color: defaultTheme.color.accentNotify600,
  },
  {
    value: PaymentStatus.ak_bars,
    color: defaultTheme.color.primary700,
  },
  {
    value: PaymentStatus.bank_factoring_payment_set,
    color: defaultTheme.color.primary50a,
  },
  {
    value: PaymentStatus.paid,
    color: '#13C2C2',
  },
  {
    value: PaymentStatus.ak_bars_factoring,
    color: '#EB2F96',
  },
  {
    value: PaymentStatus.kam_kom_bank,
    color: '#722ED1',
  },
]

export const tableCellInfoItems = [
  {
    value: PaymentStatus.advance_payment,
    textColor: defaultTheme.color.textPrimary900,
    color: 'rgba(88, 103, 118, 0.16)',
  },
  {
    value: PaymentStatus.received_payment_info,
    color: defaultTheme.color.accentApprove24a,
    textColor: defaultTheme.color.accentApprove600,
  },
  {
    value: PaymentStatus.raiffeisen,
    color: 'rgba(250, 219, 20, 0.24)',
    textColor: '#AD8B00',
  },
  {
    value: PaymentStatus.absolute,
    color: 'rgba(255, 153, 0, 0.24)',
    textColor: defaultTheme.color.accentNotify600,
  },
  {
    value: PaymentStatus.ak_bars,
    color: 'rgba(3, 123, 255, 0.16)',
    textColor: defaultTheme.color.textForLink,
  },
  {
    value: PaymentStatus.bank_factoring_payment_set,
    color: defaultTheme.color.primary8a,
    textColor: defaultTheme.color.primary300,
  },
  {
    value: PaymentStatus.paid,
    color: 'rgba(19, 194, 194, 0.24)',
    textColor: '#13C2C2',
  },
  {
    value: PaymentStatus.not_unloaded,
    color: 'rgba(235, 47, 150, 0.24)',
    textColor: '#EB2F96',
  },
  {
    value: PaymentStatus.ak_bars_factoring,
    color: 'rgba(235, 47, 150, 0.24)',
    textColor: '#EB2F96',
  },
  {
    value: PaymentStatus.kam_kom_bank,
    color: 'rgba(114, 46, 209, 0.24)',
    textColor: '#722ED1',
  },
]
