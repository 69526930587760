import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled, { css } from 'styled-components'

export const box = css`
  background-color: ${defaultTheme.color.backgroundSecondary};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
`

export const boxBordered = css`
  border: 1px solid ${defaultTheme.color.secondary50};
  border-radius: 8px;
`

export const smallBox = css`
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
`

export const CustomScroll = css`
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.secondary50};
  }

  &::-webkit-scrollbar-thumb {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.secondary200};
    transition: 0.3s ease all;

    &:hover {
      background-color: ${({ theme }) => theme.color.secondary400};
    }
  }
  @media (max-width: ${defaultTheme.queries.mobile}) {
    scrollbar-width: none;
  }
`

export const HoverScrolling = css`
  &::-webkit-scrollbar {
    display: none;
  }

  &:hover::-webkit-scrollbar {
    display: block;
  }
`

export const HoverScrollingWithPadding = css`
  ${HoverScrolling}
  margin-right: -12px;
  padding-right: 12px;
`

/** @description Wrap a FormGroup */
export const ShortFormControl = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : '399px')};
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    width: auto;
  }
`

export const ShortFormControls = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : '399px')};
  display: flex;
  flex-direction: column;
  gap: 16px 16px;
`

/** @description For radio and checkboxes in form. Stretches the height of the block so that the controls are in the center */
export const FormItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  height: 40px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    flex-wrap: wrap;
    height: auto;
    margin-top: 8px;
  }
`
/** @description Wrap the add button and the pagination component. Used in accordion for long lists */
export const ListFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .ant-pagination {
    margin-top: 0 !important;
  }
`
export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

/** @description Wrapper for entity statuses(in Headers) */
export const StatusBlockWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  color: ${defaultTheme.color.textSecondary60a};
  ${Typography.buttonLarge}
`

export const PopoverContainer = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: ${({ width = 157 }) => `${width}px`};
`

export const EntityWithIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`

export const CommonTagsWrapper = styled.div`
  display: flex;
  gap: 8px;
`
export const MultilineText = styled.div`
  white-space: pre-line;
  word-break: break-word;
`

export const mobileMenuHeight = '77px'
