import '@agro-club/agroclub-shared/dist/esm/style.css'

import './i18n'

import { defaultTheme } from '@agro-club/agroclub-shared'
import analytics from 'analytics/initAnalytics'
import { StyleSheetManager, ThemeProvider } from 'styled-components'
import { AnalyticsProvider } from 'use-analytics'

import { HelmetProvider } from 'views/components/HelmetProvider/HelmetProvider'
import GlobalStyle from 'views/globalStyles'
import RootComponent from 'views/RootComponent'
import { Sentry } from './sentry'
import { AnalyticsContextProvider } from 'analytics/analyticsContext'
import { DEVELOPMENT, REACT_APP_VERSION } from 'env'
import { Component, ErrorInfo } from 'react'
import { AuthProvider } from 'modules/context/AuthContext'
import { BrowserRouter } from 'react-router-dom'
import { shouldForwardProp } from 'helpers/shouldForwardProp'

class App extends Component {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.handleReactException(error, errorInfo)
  }

  async componentDidMount() {
    if (DEVELOPMENT) return
    let meta: { version: string } | undefined
    const headers = new Headers({
      Pragma: 'no-cache',
      Expires: '-1',
      'Cache-Control': 'no-cache',
    })
    try {
      // check latest published version
      const response = await fetch('/version.json', { headers })
      meta = await response.json()
      console.log(meta)
      // it is version updater non critical to ignore errors
    } catch {}
    if (meta && meta.version !== REACT_APP_VERSION) {
      // force browsers to reset page cache
      await fetch(window.location.href, { headers })
    }
  }

  render() {
    return (
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <AnalyticsProvider instance={analytics}>
              <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                <ThemeProvider theme={defaultTheme}>
                  <GlobalStyle />
                  <AnalyticsContextProvider>
                    <RootComponent />
                  </AnalyticsContextProvider>
                </ThemeProvider>
              </StyleSheetManager>
            </AnalyticsProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    )
  }
}

export default App
