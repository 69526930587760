import { AButton } from 'views/components/Analytics'
import { Icons, Progress, Spinner } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FileTtnItem } from 'views/pages/LogisticsKR/LogisticRequests/RequestDetails/components/FilesTtn/FileTtnItem.tsx'
import { useParams } from 'react-router-dom'
import { endpoints } from 'modules/endpoints.ts'
import { Dispatch, FC, SetStateAction } from 'react'
import { ClientPaginationSmall } from 'views/components/ClientSmallPagination/ClientPaginationSmall.tsx'
import { ListRequestParams } from 'modules/domain/types.ts'
import { apiClient } from 'modules/utils/httpClient.ts'
import { useAProgress } from 'hooks/useAProgress.ts'
import { SpinnerWrapper } from 'views/pages/LogisticsKR/components/styled.ts'
import { TtnDocument } from 'views/pages/LogisticsKR/types.ts'
import { refetchFunc } from 'modules/domain/common/hooks.ts'

const SpinnerWrapperStyled = styled(SpinnerWrapper)`
  height: 335px;
`
const MarginWrapper = styled.div`
  margin-top: 12px;
`

type Props = {
  fileList: TtnDocument[]
  fileListProgress: Progress
  total: number
  listRequestParams: ListRequestParams
  setListRequestParams: Dispatch<SetStateAction<ListRequestParams>>
  refetch: refetchFunc
  refetchTripsTable: () => void
}

export const FilesTtn: FC<Props> = ({
  fileList,
  fileListProgress,
  setListRequestParams,
  listRequestParams,
  total,
  refetch,
  refetchTripsTable,
}) => {
  const { id } = useParams()
  const { t } = useTranslation('logisticsKR')

  const [downloadProgress, downloadHandler] = useAProgress(
    async () =>
      await apiClient.get(
        endpoints.LogisticsKR.downloadAllTtn(id),
        {},
        {
          responseType: 'blob',
        },
      ),
    { eventName: 'downloadAllTtnFiles' },
  )

  const handleChangePage = (page: number) => setListRequestParams((prev) => ({ ...prev, page }))

  return (
    <>
      {!!total && (
        <AButton
          id="downloadAllTtn"
          onClick={downloadHandler}
          progress={downloadProgress}
          intent="secondary"
          Icon={Icons.IconDownload}
        >
          {t('details.accordion.downloadAllFiles')}
        </AButton>
      )}
      <MarginWrapper>
        {fileListProgress === Progress.WORK ? (
          <SpinnerWrapperStyled>
            <Spinner />
          </SpinnerWrapperStyled>
        ) : (
          fileList?.map((file) => (
            <FileTtnItem refetchTripsTable={refetchTripsTable} refetch={refetch} file={file} key={file.id} />
          ))
        )}
      </MarginWrapper>
      <ClientPaginationSmall total={total} currPage={listRequestParams.page} setCurrPage={handleChangePage} />
    </>
  )
}
