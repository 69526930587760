import { FormSelectProps } from '@agro-club/agroclub-shared'
import FormSelectShared from '../../FormSelectShared'
import { WrapperFormControl } from '../styled'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

export const AddressCommon: FC<FormSelectProps> = ({ ...props }) => {
  const { t } = useTranslation()

  return (
    <WrapperFormControl>
      <FormSelectShared
        placeholder={t('bid:form.userAddressPlaceholder')}
        getLabel={(dto) => dto.address}
        showSearch={false}
        simpleApiFormat
        {...props}
      />
    </WrapperFormControl>
  )
}
