import { AddressesBlockWrapper } from './styled'
import { Icons, Progress } from '@agro-club/agroclub-shared'
import { FC, useEffect, useState } from 'react'
import { AddAddressModal } from './AddAddressModal'
import { User } from 'modules/domain/user/types'
import { useTranslation } from 'react-i18next'
import { AddressItem } from './AddressItem'
import { AButton } from 'views/components/Analytics'
import { refetchFunc } from 'modules/domain/common/hooks'
import { Address } from 'modules/domain/types'

type Props = {
  setSummary: (arg: number) => void
  user: User
  addressesRefetch: refetchFunc
  addresses: Address[]
  progress: Progress
}

export const Addresses: FC<Props> = ({ user, setSummary, progress, addresses, addressesRefetch }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const { t } = useTranslation('user')

  const showRemoveIcon = addresses && addresses?.length > 1

  useEffect(() => {
    if (progress === Progress.SUCCESS) setSummary(addresses.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses.length])

  return (
    <>
      {addresses && (
        <AddressesBlockWrapper>
          {addresses.map((address) => (
            <AddressItem
              addressesRefetch={addressesRefetch}
              showRemoveIcon={showRemoveIcon}
              address={address}
              key={address.id}
              user={user}
            />
          ))}
        </AddressesBlockWrapper>
      )}
      {showModal && (
        <AddAddressModal onClose={() => setShowModal(false)} userId={user.id} addressesRefetch={addressesRefetch} />
      )}

      <AButton id="addAddress" Icon={Icons.IconAdd} intent="approve" size="medium" onClick={() => setShowModal(true)}>
        {t('form.addAddress')}
      </AButton>
    </>
  )
}
