import { FC, useEffect, useState } from 'react'
import { UserContact } from 'modules/domain/userContact/types'
import { Icons, Progress, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { User } from 'modules/domain/user/types'
import { ContactItem } from './ContactItem'
import { AddContactModal } from './AddContactModal'
import { AButton } from 'views/components/Analytics'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'

type Props = {
  setSummary: (arg: number) => void
  user: User
}

export const Wrapper = styled.div`
  ${Typography.bodyLarge}
  margin: 1px 0 12px 0;
`

export const Contacts: FC<Props> = ({ user, setSummary }) => {
  const { t } = useTranslation('user')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [progress, contacts = [], refetchContacts] = useSingleEntity<UserContact[]>(endpoints.userContacts(user.id))

  useEffect(() => {
    if (progress === Progress.SUCCESS) setSummary(contacts.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts.length])

  return (
    <>
      {!!contacts?.length && (
        <Wrapper>
          {contacts.map((contact) => (
            <ContactItem key={contact.id} contact={contact} refetchContacts={refetchContacts} user={user} />
          ))}
        </Wrapper>
      )}
      {showModal && (
        <AddContactModal refetchContacts={refetchContacts} user={user} setShowModal={() => setShowModal(false)} />
      )}

      <AButton id="addContact" Icon={Icons.IconAdd} intent="approve" size="medium" onClick={() => setShowModal(true)}>
        {t('common:add')}
      </AButton>
    </>
  )
}
