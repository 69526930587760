import { Carrier } from 'modules/domain/logisticsKR/types'
import { User } from 'modules/domain/user/types'
import { DocumentFile, Overload } from 'modules/domain/types'

export enum BidStatus {
  new = 'new',
  car_search = 'car_search',
  shipping = 'shipping',
  shipment_complete = 'shipment_complete',
}

export enum RequestStatus {
  on_approval = 'on_approval',
  approved = 'approved',
}

export enum RequestCondition {
  open = 'open',
  closed = 'closed',
}

export enum TripStatus {
  approved = 'approved',
  on_approval = 'on_approval',
}

export enum LogisticStatuses {
  new = 'new',
  in_progress = 'in_progress',
  waiting_for_documents = 'waiting_for_documents',
  shipment_complete = 'shipment_complete',
  cancelled = 'cancelled',
}

export enum DealTypes {
  direct = 'direct',
  agent = 'agent',
  forwarding_company = 'forwarding_company',
}

export enum RequestType {
  internal = 'internal',
  external = 'external',
  archived = 'archived',
}

export enum LoaderType {
  kuhn = 'kuhn',
  manitou = 'manitou',
  grain_thrower = 'grain_thrower',
  auger = 'auger',
  hopper = 'hopper',
}

export enum VehicleType {
  tonar = 'tonar',
  coupling = 'coupling',
  semi_trailer = 'semi_trailer',
  grain_truck = 'grain_truck',
}

export enum UnloadingType {
  left = 'left',
  right = 'right',
  rear = 'rear',
  right_rear = 'right_rear',
  left_rear = 'left_rear',
  right_left = 'right_left',
  three_sides = 'three_sides',
}

export enum CarStatuses {
  active = 'active',
  archived = 'archived',
  draft = 'draft',
}

export enum CarrierStatuses {
  active = 'active',
  archived = 'archived',
  draft = 'draft',
}

export enum CarrierType {
  local = 'local',
  global = 'global',
}

export type LogisticsKRCarOwner = {
  id: string
  phone: string
  full_name: string
}

export type LogisticsKRCar = {
  id: string
  brand_model: string
  owner: LogisticsKRCarOwner | User
  licence_number: string
  car_type?: VehicleType
  unloading_type?: UnloadingType
  side_height?: number
  is_in_charter: boolean | null
  is_dump_truck: boolean | null
  drivers_count: number
  status: CarStatuses
  carrier: Carrier
  has_trailer: boolean
  current_trailer: Trailer
  trips_count?: number
  ready_for_active: boolean
  car_type_matched?: boolean
  unloading_type_matched?: boolean
  visited_loading_warehouse_matched?: boolean
  visited_unloading_warehouse_matched?: boolean
  travelled_from_loading_to_unloading_warehouse_matched?: boolean
  travelled_from_loading_to_unloading_region_matched?: boolean
  travelled_from_unloading_to_loading_region_matched?: boolean
  favorite_region_matched?: boolean
  is_available_matched?: boolean
  carrier_type_matched?: boolean
}

export type Car = {
  id: string
  brand_model: string
  owner: LogisticsKRCarOwner | User
  licence_number: string
  car_type?: VehicleType
  unloading_type?: UnloadingType
  side_height?: number
  is_in_charter: boolean | null
  is_dump_truck: boolean | null
  default_trailer?: {
    id: string
    licence_number: string
    brand_model: string | null
  }
  drivers_count: number
  status: CarStatuses
  company: {
    id: number
    inn: string
    name: string
  }
  has_trailer: boolean
  trips_count?: number
}

export type LogisticsKRCarrier = {
  id: string
  created: string
  inn: string
  name: string
  phone: number
  contact_name: boolean
  is_in_charter: boolean
  cars_count: number
}

export interface LogisticsKRWarehouse {
  id: number
  address: string
  latitude: number
  longitude: number
  geo_object?: Geolocation
  gates_height: number
  scales_capacity: number
  scales_remoteness: number
  scales_length: number
  volume_per_day: number
  vehicle_type: VehicleType[]
  schedule_from: string
  schedule_to: string
  overload: Overload
  works_on_weekend: boolean
  car_delivery: boolean
  railway_delivery: boolean
}

export interface LogisticsKRLoadingWarehouse extends LogisticsKRWarehouse {
  loader_type: LoaderType[]
}

export interface LogisticsKRUnloadingWarehouse extends LogisticsKRWarehouse {
  unloading_type: UnloadingType[]
  is_dump_truck: boolean
}

export const isUnloadingWarehouse = (
  warehouse: LogisticsKRLoadingWarehouse | LogisticsKRUnloadingWarehouse,
): warehouse is LogisticsKRUnloadingWarehouse => warehouse.hasOwnProperty('unloading_type')

export type Driver = {
  id: number
  carrier: number
  name: string
  phone: string
}

export type Trailer = {
  id: string | number
  carrier: string
  licence_number: string | number
  side_height: number | null
  unloading_type: UnloadingType | null
  brand_model?: string | null
  status: CarStatuses
}

export type Logistician = {
  full_name: string
  id: string
  short_name: string
}

export interface LogisticsKRTrip {
  // "null" for related entities is possible in rare cases
  // when a trip is created via 1c and the entities data is received later
  id: string
  agent: Carrier | null
  carrier: Carrier | null
  car: LogisticsKRCar | null
  trailer: Trailer | null
  request: number
  driver: Driver | null
  load_date: string
  load_quantity: number
  unload_date: string
  unload_quantity: number
  price_logistics: number
  logistician?: Logistician
  agent_commission?: number
  has_agent_vat?: boolean
  has_vat?: boolean
  deal_type?: DealTypes
  transit: boolean
}

export enum TripOfferStatuses {
  new = 'new',
  closed = 'closed',
  approved = 'approved',
}

export enum TripOfferClosedReasons {
  request_closed = 'request_closed',
  another_request = 'another_request ',
}

export interface TripOffer {
  id: string
  status: TripOfferStatuses
  carrier: Carrier
  agent: Carrier
  agent_commission: number
  car: LogisticsKRCar
  trailer: Trailer
  request: string
  driver: Driver
  load_quantity: number
  load_date: string
  closed_reason: TripOfferClosedReasons
  is_viewed: boolean
  created: string
}

export enum TripTtnDocType {
  load = 'ttn_1',
  unload = 'ttn_2',
}

export type TtnDocument = DocumentFile & {
  trip: number
  ttn_number: string
  uploaded_at: string
  uploadedfile: number
}
