import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icons, Progress } from '@agro-club/agroclub-shared'
import { TrailerItem } from './TrailerItem'
import { AddTrailerModal } from 'views/pages/LogisticsKR/components/TrailerModals/AddTrailerModal'
import styled from 'styled-components'
import { ListFooterWrapper } from 'views/styled/common'
import { usePagination, useTableData } from 'modules/domain/common/hooks'
import { ACCORDION_ITEM_PAGE_SIZE } from 'modules/constants'
import { AButton } from 'views/components/Analytics'
import { ClientPaginationSmall } from 'views/components/ClientSmallPagination/ClientPaginationSmall'
import { endpoints } from 'modules/endpoints'
import { ListRequestParams } from 'modules/domain/types'

const ItemList = styled.div`
  margin-bottom: 12px;
`

interface Props {
  listRequestParams: ListRequestParams
  carrierId: string
  setSummary: (arg: number) => void
}

export const Trailers: FC<Props> = ({ carrierId, listRequestParams, setSummary }) => {
  const { t } = useTranslation('logisticsKR')
  const [isModalOpened, setIsModalOpened] = useState(false)

  const {
    data: trailers = [],
    refetch,
    progress,
  } = useTableData(endpoints.LogisticsKR.activeTrailers(), listRequestParams, !!listRequestParams.filter.carrier)
  const [trailersCurrentPage, setTrailersCurrentPage, trailersCurrentItems] = usePagination(
    trailers,
    ACCORDION_ITEM_PAGE_SIZE,
  )

  useEffect(() => {
    if (progress === Progress.SUCCESS) setSummary(trailers.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trailers.length])

  return (
    <div>
      {!!trailersCurrentItems?.length && (
        <ItemList>
          {trailersCurrentItems.map((trailer) => (
            <TrailerItem key={trailer.id} trailer={trailer} carrierId={carrierId} refetch={refetch} />
          ))}
        </ItemList>
      )}
      <ListFooterWrapper>
        <AButton id="addTrailer" intent="approve" onClick={() => setIsModalOpened(true)} Icon={Icons.IconAdd}>
          {t('carsDetails.buttons.addCar')}
        </AButton>
        <ClientPaginationSmall
          total={trailers?.length || 0}
          currPage={trailersCurrentPage}
          setCurrPage={setTrailersCurrentPage}
        />
      </ListFooterWrapper>

      {isModalOpened && (
        <AddTrailerModal setIsOpen={setIsModalOpened} carrierId={carrierId} onSuccess={() => refetch()} />
      )}
    </div>
  )
}
