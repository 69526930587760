import { FormikProps } from 'formik'
import { FC } from 'react'
import { EnumSelect } from 'views/components/form/EnumSelect'
import { FarmerLoadWorkTypes } from 'modules/domain/deal/types'
import { t } from 'i18next'

type Props = {
  formik: FormikProps<any>
}

export const FarmerWorkTypeSelect: FC<Props> = ({ formik }) => {
  return (
    <EnumSelect
      formik={formik}
      fieldName="work_type"
      translationNameSpace={'deal:accordion.loading'}
      required
      allowClear
      label={t('deal:accordion.loading.operationType')}
      enumType={FarmerLoadWorkTypes}
      placeholder={t('common:selectWorkType')}
    />
  )
}
