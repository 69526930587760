import { Radio, RadioItem, Text, defaultTheme } from '@agro-club/agroclub-shared'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { FormikFieldsType } from 'modules/domain/types'
import { User } from 'modules/domain/user/types'
import { Task } from 'modules/domain/task/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import styled from 'styled-components'
import { FC } from 'react'

const Wrapper = styled.div`
  padding: 12px;
  background-color: ${defaultTheme.color.accentNotify100};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px 0;
`

const StyledRadio = styled(RadioItem)`
  margin-top: 8px;
  button {
    background: transparent;
    border: 1px solid ${defaultTheme.color.secondary400};
  }
`

type Props = {
  formik: FormikFieldsType
  task: Task
  user?: User
}

export const InfoBoard: FC<Props> = ({ formik, task, user }) => {
  const { t } = useTranslation('my_tasks')
  const [, callState] = useSingleEntity<{ detail: number }>(endpoints.taskCallState(task.id))

  if (!callState || callState.detail < 4) {
    return null
  }
  const userName = task.linked_user?.full_name || user?.profile?.full_name || user?.phone
  return (
    <Wrapper>
      <>
        <Text typography="accentLarge">{t('note')}</Text>
        <Text>
          {t('infoText', { user: userName })} <b>{t('unsuccessfulCalls', { attempts: callState?.detail })}</b>{' '}
          {t('answerLocked')}
        </Text>
        <Radio onChange={(value) => formik.setFieldValue('commChoice', value)} value={formik.values.commChoice}>
          <StyledRadio value={true} label={t('continueCommunication')} />
          <StyledRadio value={false} label={t('locked')} />
        </Radio>
      </>
    </Wrapper>
  )
}
