import { FC, useMemo } from 'react'
import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { TableFiltersProps } from 'modules/types'
import { MonthFilter } from 'views/components/MonthFilter/MonthFilter'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import { RequestsTypeFilter } from './RequestsTypeFilter'
import { TeamsFilter } from './TeamsFilter'
import { AssigneeFilter } from './AssigneeFilter'
import { AClearFiltersButton } from 'views/components/Analytics'
import { RequestWithTripOfferFilter } from './RequestWithTripOfferFilter'
import { RequestCondition } from 'views/pages/LogisticsKR/types'

type BoardFiltersProps = TableFiltersProps & { activeTab: string }

export const RequestsBoardFilters: FC<BoardFiltersProps> = ({
  setListRequestParams,
  listRequestParams,
  updateFilterState,
  isFiltersLoaded,
  activeTab,
  defaultFilterValues,
}) => {
  const allowClear = activeTab !== RequestCondition.closed
  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
    defaultFilters: defaultFilterValues,
  })

  const filterForAssigneе = useMemo(
    () => (listRequestParams.filter?.assigned_team ? { team: listRequestParams.filter?.assigned_team } : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listRequestParams.filter?.assigned_team, isFiltersLoaded],
  )

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <MonthFilter
          value={listRequestParams.filter.month}
          updateFilterState={updateFilterState}
          allowClear={allowClear}
        />
        <RequestsTypeFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
        <RequestWithTripOfferFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
        <TeamsFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
        <AssigneeFilter
          filter={filterForAssigneе}
          listRequestParams={listRequestParams}
          updateFilterState={updateFilterState}
        />
        {displayClearBtn && <AClearFiltersButton onClick={() => setListRequestParams({ ...defaultFilterValues })} />}
      </FiltersWrapper>
      <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </FiltersAndSearchWrapper>
  )
}
