import { defaultTheme, Text } from '@agro-club/agroclub-shared'
import { FC } from 'react'
import styled from 'styled-components'

export const Hr = styled.div`
  border-top: 1px solid ${defaultTheme.color.secondary50};
  width: 100%;
  margin: 10px 0;
`

export const HrWithHeader: FC<{ text: string; marginBottom?: string }> = ({ text, marginBottom }) => (
  <div style={{ display: 'flex', width: '100%', gap: '9px', marginBottom: marginBottom ?? '0' }}>
    <Hr />
    <Text typography="accentSmall" style={{ whiteSpace: 'nowrap', padding: '4px 0px' }}>
      {text}
    </Text>
    <Hr />
  </div>
)
