import { useAnalyticsContext } from 'analytics/hooks.ts'
import { InternalUploadFileButton, UploadFileButtonProps } from './InternalUploadFileButton.tsx'
import { FC } from 'react'

export const AUploadFileButton: FC<UploadFileButtonProps & { eventName: string }> = ({
  onUpload,
  accept,
  text,
  icon,
  size,
  multiple,
  progress,
  eventName,
}) => {
  const { track } = useAnalyticsContext()

  const uploadHandler = (files: File | File[]) => {
    onUpload(files)

    track(`upload|${eventName}`)
  }

  return (
    <InternalUploadFileButton
      onUpload={uploadHandler}
      text={text}
      progress={progress}
      multiple={multiple}
      icon={icon}
      size={size}
      accept={accept}
    />
  )
}
