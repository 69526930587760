import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { generatePath, useNavigate, useParams } from 'react-router'
import { DealStatusTypes, ListDeal, ListDealBid } from 'modules/domain/deal/types'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { DealTags } from '../DealDetail/DealTags/DealTags'
import { isBidContractsEnabled } from 'env'
import { AntTag, AntTooltip, AntTooltipProps, Text } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { FC, ReactNode } from 'react'
import DealRoutes from '../routes'
import {
  CompanyWrapper,
  SignedMargin,
  ActualMargin,
  PriceWrapper,
  CardWrapper,
  MarginOnWay,
  FixedMargin,
  StatusIcons,
  Company,
  Product,
  Link,
  StatusesWrapper,
  NoBid,
} from './styled'
import {
  CompanyFactoringIcon,
  CoordinatorVerifiedUserIcon,
  KeyUserIcon,
  KycVerifiedUserIcon,
  SecurityVerifiedUserIcon,
  UserHasDealsIcon,
  VerifiedUserIcon,
} from 'views/components/TooltipIcons/TooltipIcons'
import styled from 'styled-components'
import useFormatDate from 'hooks/useFormatDate'

type Props = {
  deal: ListDeal
  activeTab: string
}

const NoBidBlock = ({ children }) => {
  if (!isBidContractsEnabled) return null
  return <NoBid>{children}</NoBid>
}
const DealCardToolTip: FC<AntTooltipProps> = (props) => (
  <AntTooltip variant="secondary" placement="topLeft" {...props} />
)

const CompanyTooltip = styled(DealCardToolTip).attrs({ overlayInnerStyle: { maxWidth: '320px' } })(() => '')

export const DealCard: FC<Props> = ({ deal, activeTab }) => {
  const { t } = useTranslation('deal')
  const formatDate = useFormatDate()

  const statusCompany = (bid: ListDealBid) => {
    return (
      <StatusIcons>
        <KeyUserIcon isVisible={bid.owner_is_significant} />
        <VerifiedUserIcon isVisible={bid.owner_is_verified} />
        <CoordinatorVerifiedUserIcon isVisible={bid.owner_is_verified_by_coordinator && !bid.owner_is_verified} />
        <SecurityVerifiedUserIcon isVisible={bid.owner_is_verified_by_security && !bid.owner_is_verified} />
        <KycVerifiedUserIcon isVisible={bid.owner_is_verified_by_kyc && !bid.owner_is_verified} />
        <UserHasDealsIcon isVisible={bid.owner_has_shipped_deals} />
        <CompanyFactoringIcon factoringStatus={bid.company.factoring_status} />
      </StatusIcons>
    )
  }

  const isFixedMarginVisible = [
    DealStatusTypes.DOCUMENTS_ACCEPTED,
    DealStatusTypes.SHIPMENT_COMPLETE,
    DealStatusTypes.PAYMENT_SHIPMENT,
    DealStatusTypes.PAYMENT_COMPLETE,
    DealStatusTypes.PICK_UP_COMPLETE,
    DealStatusTypes.CONTRACT_SIGNED,
  ].includes(deal.status as DealStatusTypes)

  const navigate = useNavigate()

  // write to the browser history url with the id of selected deal
  const addIdActiveDealToUrl = () => {
    navigate(
      {
        pathname: generatePath(DealRoutes.ListWithTabAndDeal, { activeTab: activeTab, selectedDeal: deal.id }),
        search: location.search,
      },
      { replace: true },
    )
  }

  let reasonTag: ReactNode = null
  if (deal.closed_reason) {
    reasonTag = (
      <AntTag style={{ marginBottom: 8, alignSelf: 'flex-start' }} color="grey">
        {`${t('isClosed')} / ${t(`closed_reasons.${deal.closed_reason}`)}`}
      </AntTag>
    )
  }

  const { selectedDeal: selectedDealParams } = useParams()
  const activedeal = Number(selectedDealParams) === Number(deal.id)

  return (
    <Link
      eventName="deal"
      itemId={deal.id}
      to={generatePath(DealRoutes.Details, { id: deal.id })}
      onClick={addIdActiveDealToUrl}
    >
      <CardWrapper id={activedeal ? `${selectedDealParams}active` : ''} $isActive={activedeal}>
        {reasonTag}
        {deal.sale_bid ? (
          <CompanyWrapper>
            <CompanyTooltip tooltipContent={`${t('seller')} ${deal.sale_bid.company.name}`}>
              <Company>{deal.sale_bid.company.name}</Company>
            </CompanyTooltip>
            <StatusesWrapper>
              {statusCompany(deal.sale_bid)}
              {deal.sale_contract && (
                <Text color="secondary" typography="bodyMedium">{`№${deal.sale_contract.number}`}</Text>
              )}
            </StatusesWrapper>
            {deal.sale_contract && (
              <Text color="secondary" typography="bodyMedium">
                {`${t('bid:contracts.sellerPaymentDate')} ${formatDate(deal.sale_contract.payment_due_date)}`}
              </Text>
            )}
          </CompanyWrapper>
        ) : (
          <NoBidBlock>{t('rematch.noSeller')}</NoBidBlock>
        )}

        {deal.purchase_bid ? (
          <CompanyWrapper>
            <CompanyTooltip tooltipContent={`${t('buyer')} ${deal.purchase_bid.company.name}`}>
              <Company>{deal.purchase_bid.company.name}</Company>
            </CompanyTooltip>
            <StatusesWrapper>
              {statusCompany(deal.purchase_bid)}
              {deal.purchase_contract && (
                <Text color="secondary" typography="bodyMedium">{`№${deal.purchase_contract.number}`}</Text>
              )}
            </StatusesWrapper>
            {deal.purchase_contract && (
              <Text color="secondary" typography="bodyMedium">
                {`${t('bid:contracts.purchasePaymentDate')} ${formatDate(deal.purchase_contract.payment_due_date)}`}
              </Text>
            )}
          </CompanyWrapper>
        ) : (
          <NoBidBlock>{t('rematch.noBuyer')}</NoBidBlock>
        )}

        <PriceWrapper>
          <DealCardToolTip tooltipContent={t('expected_margin')}>
            <SignedMargin margin={deal.margin_remaining}>
              {`${formatPrice(deal.margin_remaining, true, false)} / ${deal.margin_percent}%`}
            </SignedMargin>
          </DealCardToolTip>
          <DealCardToolTip tooltipContent={t('margin_on_way')}>
            <MarginOnWay>{formatPrice(deal.margin_on_way, true, false)}</MarginOnWay>
          </DealCardToolTip>

          <DealCardToolTip tooltipContent={t('actual_margin')}>
            <ActualMargin>
              {`${formatPrice(deal.actual_margin, true, false)} / ${deal.actual_margin_percent}%`}
            </ActualMargin>
          </DealCardToolTip>

          {isFixedMarginVisible && (
            <DealCardToolTip tooltipContent={t('was_in_contract')}>
              <FixedMargin>
                {`${formatPrice(deal.margin_on_contract_signed, true, false)} / ${
                  deal.margin_percent_on_contract_signed
                }%`}
              </FixedMargin>
            </DealCardToolTip>
          )}
        </PriceWrapper>
        <DealCardToolTip
          tooltipContent={[t('product_volume'), deal.season ? t('season').toLowerCase() : null]
            .filter(Boolean)
            .join(', ')}
        >
          <Product>
            {`${deal.product.title}, ${formatQuantity(deal.quantity)}`}
            {deal.season && `, ${deal.season.name}`}
          </Product>
        </DealCardToolTip>
        <DealTags deal={deal} />
      </CardWrapper>
    </Link>
  )
}
