import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { CellRenders } from 'views/components/Table/renderers'
import { USBid } from 'modules/domain/bid/types'
import { ColumnsType } from '@agro-club/agroclub-shared'
import { DatePeriodFormatted } from 'views/components/DatePeriodFormatted'
import { formatDistance } from 'modules/utils/numbers/formatDistance'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { generatePath } from 'react-router-dom'
import BidRoutes from 'views/pages/Bid/routes'
import { CompanyLink } from 'views/components/CompanyLink/CompanyLink'

export const useClosestColumns = (): ColumnsType<USBid> => {
  const { t } = useTranslation('bid')

  return useMemo(
    () => [
      {
        title: t('usShortBid.cropSummary'),
        dataIndex: 'product',
        render: (product, bid) => {
          return (
            <>
              <TableTextLink
                bold
                target="_blank"
                to={generatePath(BidRoutes.Details, { id: bid.id })}
                tableItemId={bid.id}
                id="bid"
              >
                {product.title}, <span style={{ whiteSpace: 'nowrap' }}>{bid.symbol}</span>
              </TableTextLink>
              <div>
                <CompanyLink company={bid.company} target="_blank" bodysize="bodyMedium" />
              </div>
              <div>{bid.address.short_address}</div>
            </>
          )
        },
      },
      {
        title: t('usShortBid.delivery'),
        width: 100,
        dataIndex: 'end_date',
        render: (date, bid: USBid) => (
          <DatePeriodFormatted wrap period={{ start_date: bid.start_date, end_date: bid.end_date }} />
        ),
      },
      {
        title: t('common:remoteness'),
        width: 80,
        dataIndex: 'distance',
        align: 'right',
        render: (d) => formatDistance(d),
      },
      {
        title: t('usShortBid.futures'),
        dataIndex: 'futures_price',
        width: 72,
        align: 'right',
        render: CellRenders.price,
      },
      {
        title: t('usShortBid.basis'),
        dataIndex: 'basis',
        width: 64,
        align: 'right',
        render: CellRenders.price,
      },

      {
        title: t('usShortBid.cash'),
        dataIndex: 'price',
        width: 64,
        align: 'right',
        render: CellRenders.price,
      },
    ],
    [t],
  )
}
