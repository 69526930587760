import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'
import { UserCompanyLink } from './UserCompanyLink/UserCompanyLink'
import { MoratoriumTag } from 'views/components/Tags/CompanyTag'
import { AntTag } from '@agro-club/agroclub-shared'
import * as Header from 'views/ui/Header/Header'
import { User } from 'modules/domain/user/types'
import { useTranslation } from 'react-i18next'
import APhone from 'views/ui/Phone/APhone'
import { FC } from 'react'
import { CompanySizeTag } from './CompanySizeTag'
import { CompanyCommercialTag } from 'views/pages/Company/CompanyCommercialTag'

type Props = {
  user: User
}

export const HeaderSubTitleValue: FC<Props> = ({ user }) => {
  const { t } = useTranslation('user')
  const profile = user.profile
  const company = user.profile?.company

  const displayParts = [
    company?.id && <UserCompanyLink companyId={company.id} companyName={company.name} />,
    company?.inn && <span>{`${getPersonalNumberLabel()}: ${company.inn}`}</span>,
    user.phone && <APhone phone={user.phone} isMobile={user.is_mobile_phone} />,
    user.email && <span>{user.email}</span>,
  ].filter(Boolean)

  return (
    <Header.InlineContainer>
      <CompanyCommercialTag commercial={user.profile?.company?.commercial} />
      <CompanySizeTag companySize={profile?.company_size} />
      <AntTag color="white">
        {profile?.profile_type ? t(`profile_type.${profile.profile_type}`) : t('no_profile')}
      </AntTag>
      <MoratoriumTag isVisible={company?.soy_restriction} />
      <div>{displayParts.map((part, index) => [index > 0 && ' / ', part])}</div>
    </Header.InlineContainer>
  )
}
