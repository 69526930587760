import { FC } from 'react'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { useTranslation } from 'react-i18next'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { AnalyticsFilterVariant } from 'analytics/types'

type Props = {
  listRequestParams: ListRequestParams
  updateFilterState: any
  filter?: { team: string } | null
}

export const AssigneeFilter: FC<Props> = ({ filter, updateFilterState, listRequestParams }) => {
  const { t } = useTranslation()
  return (
    <ABaseTableFilter
      id={AnalyticsFilterVariant.ASSIGNEE}
      onChange={(assignee) => updateFilterState({ assignee: assignee })}
      value={listRequestParams.filter.assignee}
      getLabel={(label) => label.full_name}
      endpoint={endpoints.taskAssignees()}
      placeholder={t('common:assignee')}
      filter={filter}
      searchField="search"
      simpleApiFormat
    />
  )
}
