import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { getTripsReconciliationRequestValues } from './helpers'
import { AgentControls } from './AgentControls'
import { RadioDealType } from './RadioDealType'
import { CarrierControls } from './CarrierControls'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { Carrier, DealType, LogisticRequest } from 'modules/domain/logisticsKR/types'
import { useAFormHandler } from 'analytics/hooks'
import env from 'env'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { replaceErrorsDotNotation } from 'modules/utils/helpers'
import { ListResponse } from 'types/api'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import FormSelectShared from 'views/components/FormSelectShared'
import { SeparatorWithTitle } from 'views/components/SeparatorWithTitle/SeparatorWithTitle'
import { ShortFormControl } from 'views/styled/common'
import { DatePicker } from 'views/components/DatePicker/DatePicker'
import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { markEventAsRead } from 'modules/socket/managers'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { DraftTripsInitial } from 'views/pages/LogisticsKR/LogisticRequests/RequestsBoard/OpenRequestsBoardColumns/DraftTrips/types'
import { LogisticsKRTrip } from 'views/pages/LogisticsKR/types'

type Props = {
  onClose: (arg: boolean) => void
  activeRequest: LogisticRequest | undefined
  onSuccess: (arg: boolean) => Promise<void> | void
  carId?: string
  trailerId?: string | number
  currTrailerId?: string | number
  driverId?: string | number
  carrier?: LogisticsKRTrip['carrier']
  agent?: LogisticsKRTrip['agent']
  carrierHasVat?: boolean
  agentHasVat?: boolean
  agentCommission?: number
  tripOfferId?: string
  //Скорее всего временный пропс,
  // тк Егор попросил не дизейблить поля в модалке из откликов(поработают и видно будет)
  isCancelControlDisable?: boolean
}

export const AddDraftTripModal: FC<Props> = ({
  onClose,
  activeRequest,
  onSuccess,
  carrier,
  agent,
  carId,
  currTrailerId,
  trailerId,
  driverId,
  carrierHasVat,
  agentHasVat,
  tripOfferId,
  agentCommission,
  isCancelControlDisable,
}) => {
  const { t } = useTranslation('logisticBids')
  const { formProgress, formHandler } = useAFormHandler('carToTrip', { id: activeRequest?.id })
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const [isExistingCarrierInDB, setIsExistingCarrierInDB] = useState(true)
  const [isExistingAgentInDB, setIsExistingAgentInDB] = useState(true)

  let controlsDisable: Record<string, boolean> | undefined = {
    agent: !!agent?.id,
    carrier: !!carrier?.id,
    car: !!carId,
  }

  if (isCancelControlDisable) controlsDisable = undefined

  useNotificationProgress(formProgress)

  const formik = useFormik<DraftTripsInitial>({
    initialValues: {
      logistician: null,
      carrier_id: carrier?.id ?? '',
      carrier_inn: '',
      carrier_phone: carrier?.phone ?? '',
      carrier_name: carrier?.name ?? '',
      has_vat: carrierHasVat ?? false,
      need_contract: false,
      car_id: carId ?? '',
      car_brand_model: '',
      car_licence_number: '',
      trailer: currTrailerId ?? trailerId ?? '',
      trailer_brand_model: '',
      trailer_licence_number: '',
      without_trailer: false,
      request: activeRequest?.id,
      driver: driverId ? String(driverId) : '',
      agent_id: agent?.id ?? '',
      agent_inn: '',
      agent_phone: '',
      agent_name: agent?.name ?? '',
      price_logistics: '',
      load_date: '',
      deal_type: DealType.direct,
      has_agent_vat: agentHasVat ?? false,
      agent_commission: agentCommission ?? 0,
      trip_offer: tripOfferId,
    },

    onSubmit: formHandler(
      async () => {
        await apiClient.post(endpoints.LogisticsKR.trips(), getTripsReconciliationRequestValues(formik.values))
        tripOfferId && (await markEventAsRead([{ model: 'tripoffer', object_id: tripOfferId }]))
      },
      {
        onSuccess: async () => {
          await onSuccess(true)
          onClose(false)
        },

        onError: (error) => {
          const { errors } = error
          formik.setErrors(replaceErrorsDotNotation(errors))
        },
      },
    ),
  })

  const isMediator =
    formik.values.deal_type === DealType.agent || formik.values.deal_type === DealType.forwarding_company

  useEffect(() => {
    if (formik.values.carrier_id) return
    if (formik.values?.carrier_inn) {
      const findCarrier = async () => {
        const response = await apiClient.get<ListResponse<Carrier>>(
          endpoints.LogisticsKR.findCarrierByInn(formik.values.carrier_inn),
        )

        if (response.results.length) {
          const results = response?.results[0]

          formik.setFieldValue('carrier_id', results?.id)
          formik.setFieldValue('carrier_inn', results?.inn)
          formik.setFieldValue('carrier_phone', results?.phone)
          formik.setFieldValue('carrier_name', results?.name ?? null)
        } else {
          setIsExistingCarrierInDB(false)
        }
      }
      findCarrier()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.carrier_inn])

  useEffect(() => {
    if (formik.values?.agent_inn) {
      const findAgent = async () => {
        const response = await apiClient.get<ListResponse<Carrier>>(
          endpoints.LogisticsKR.findCarrierByInn(formik.values.agent_inn),
        )

        if (response.results.length) {
          const results = response?.results[0]

          formik.setFieldValue('agent_id', results?.id)
          formik.setFieldValue('agent_inn', results?.inn)
          formik.setFieldValue('agent_phone', results?.phone)
          formik.setFieldValue('agent_name', results?.name ?? null)
        } else {
          setIsExistingAgentInDB(false)
        }
      }
      findAgent()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.agent_inn])

  return (
    <ASharedModal onClose={() => onClose(false)} id={AnalyticsPlaces.REQUESTS_BOARD.CAR_TO_TRIP_RECONCILIATION_MODAL}>
      <SharedModalTitle>{t('form.titles.add_trips_reconciliation')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <FormSelectShared
            label={t('form.labels.added')}
            formik={formik}
            endpoint={endpoints.dispatchingLogisticians()}
            fieldName="logistician"
            getLabel={(dto) => dto.full_name}
            searchField="search"
          />
          <FormGroup label={t('form.labels.from')}>
            <Input value={activeRequest?.loading_warehouse.address} disabled />
          </FormGroup>
          <FormGroup label={t('form.labels.to')}>
            <Input value={activeRequest?.unloading_warehouse.address} disabled />
          </FormGroup>

          <RadioDealType formik={formik} />

          {isMediator && (
            <AgentControls
              isExistingAgentInDB={isExistingAgentInDB}
              setIsExistingAgentInDB={setIsExistingAgentInDB}
              formik={formik}
              controlsDisable={controlsDisable}
            />
          )}

          <CarrierControls
            isExistingCarrierInDB={isExistingCarrierInDB}
            setIsExistingCarrierInDB={setIsExistingCarrierInDB}
            formik={formik}
            controlsDisable={controlsDisable}
          />

          <SeparatorWithTitle title={t('form.block_titles.common_info')} />
          <ShortFormControl>
            <FormGroup label={t('form.labels.loading_date')} error={formik.errors.load_date} required>
              <DatePicker
                onChange={(data) => formik.setFieldValue('load_date', data['date'])}
                error={!!formik.errors.load_date}
                placeholder={t('common:datePlaceholder')}
                allowClear={true}
              />
            </FormGroup>
            <FormGroup
              label={t('form.labels.logistic_price', { currency })}
              error={formik.errors.price_logistics}
              required
            >
              <Input {...formik.getFieldProps('price_logistics')} invalid={!!formik.errors.price_logistics} />
            </FormGroup>
          </ShortFormControl>
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        progress={formProgress}
        onClose={() => onClose(false)}
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:add')}
      />
    </ASharedModal>
  )
}
