import { defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const RadioWrapper = styled.div`
  .ant-radio-group {
    display: flex;
    align-items: center;
  }
`

export const RadioItemWrapper = styled.div<{ $color: string; $borderColor?: string; $checkColor?: string }>`
  .ant-radio-wrapper {
    display: flex;
    margin-right: 4px;
  }

  span.ant-radio + * {
    padding: 0;
  }

  .ant-radio {
    &::after {
      top: -1px;
      left: -1px;
      border-color: ${({ $borderColor, $color }) => ($borderColor ? $borderColor : $color)};
    }

    &-checked {
      .ant-radio-inner {
        border-color: ${({ $borderColor, $color }) => ($borderColor ? $borderColor : $color)};
      }

      & + span {
        opacity: 1 !important;
        visibility: visible !important;
      }
    }

    & + span {
      display: flex;
      position: absolute;
      top: 7.5px;
      left: 4.5px;
      padding: 0;
      transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      opacity: 0;
      visibility: hidden;

      svg {
        width: 11px;
        height: 11px;

        path {
          fill: ${({ $checkColor }) => ($checkColor ? $checkColor : defaultTheme.color.backgroundSecondary)};
        }
      }
    }
  }

  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${({ $borderColor, $color }) => ($borderColor ? $borderColor : $color)};
  }

  .ant-radio-inner {
    width: 20px;
    height: 20px;

    &::after {
      width: 14px;
      height: 14px;
      background-color: ${({ $color }) => $color};
      border: 1px solid ${({ $borderColor, $color }) => ($borderColor ? $borderColor : $color)};
      transform: unset;
      opacity: 1;
    }
  }
`
