import { NetworkErrorType } from 'modules/errors'
import { ProductTag } from './user/types'
import { FormikHelpers, FormikState, FormikValues } from 'formik'

export type NetworkError = NetworkErrorType | 'unknown'

export enum InstanceIDs {
  RU = 'ru',
  BR = 'br',
  US = 'us',
}

export type InstanceID = `${InstanceIDs}`

export enum UnloadingType {
  left = 'left',
  right = 'right',
  rear = 'rear',
  right_rear = 'right_rear',
  left_rear = 'left_rear',
  right_left = 'right_left',
  three_sides = 'three_sides',
}

export enum VehicleType {
  tonar = 'tonar',
  coupling = 'coupling',
  semi_trailer = 'semi_trailer',
  grain_truck = 'grain_truck',
}

export enum YesNoUnknown {
  yes = 'yes',
  no = 'no',
  unknown = 'unknown',
}

export enum LoaderType {
  kuhn = 'kuhn',
  manitou = 'manitou',
  grain_thrower = 'grain_thrower',
  auger = 'auger',
  hopper = 'hopper',
}

export enum Overload {
  full = 'full',
  normal = 'normal',
}

export type Address = {
  id: string
  title: string
  address: string
  latitude: number
  longitude: number
  is_main: boolean
  geo_object: unknown
  short_address: string

  // warehouse properties
  gates_height?: number | null
  is_dump_truck?: boolean
  loader_type?: LoaderType[]
  scales_capacity?: number | null
  scales_length?: number | null
  scales_remoteness?: number | null
  schedule_from?: string | null
  schedule_to?: string | null
  unloading_type?: UnloadingType
  vehicle_type?: VehicleType[]
  volume_per_day?: number | null
  works_on_weekend?: YesNoUnknown
  car_delivery?: YesNoUnknown
  railway_delivery?: YesNoUnknown
  overload: Overload
  legal_type?: string
}

export type WarehouseDTO = Omit<
  Address,
  'id' | 'title' | 'address' | 'latitude' | 'longitude' | 'is_main' | 'geo_object' | 'short_address'
>

export type RespFile = {
  original_filename: string
  id: string
  uploaded_file: string
  uploaded_at?: string
  extension?: string
}

export type Product = {
  id: string
  slug: string
  title: string
  order: number
}

export type UserShort = {
  id: string
  phone: string
  is_mobile_phone: boolean
  short_name: string
  full_name: string
  product_tags?: ProductTag[]
}

export enum DriverStatuses {
  active = 'active',
  archived = 'archived',
}

export type Driver = {
  id: string
  name: string
  phone: string
  status?: DriverStatuses
}

export type BasicStatus<SlugType extends string = string> = {
  slug: SlugType
  label: string
}

export type DocumentType = BasicStatus

export type DocumentFile = {
  id: string
  uploaded_file: string
  original_filename: string
  document_type?: string
}

export type DocumentWithTypeFile = {
  document_type: string
  uploaded_file: string
  original_filename?: string
}

export enum ParamLoadSteps {
  Initial,
  Parsed,
  Loaded,
}

export type ListRequestParams = {
  filter?: any
  page: number
  sorting?: any
  pageSize?: number
  sort?: string
  loadStep?: ParamLoadSteps
  // transform data before send to server. usually you don't need it.
  transformBeforeSend?: Record<string, (any) => any>
}

export type Declaration = {
  number: string
  start_date: string
  files: RespFile[]
}

export interface Coordinator {
  id: string
  phone: string
  full_name: string
  is_active: boolean
}

export interface Rating {
  editable: boolean
  label: string
  rated_by: Coordinator
  rating_type: string
  tooltip: string
  value: number
}

export type FormikFieldsType<T = any> = FormikState<T> & FormikHelpers<T> & FormikValues
