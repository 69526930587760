import { Icons, Progress } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'
import { uploadDocuments } from 'modules/domain/common/managers.ts'
import { endpoints } from 'modules/endpoints.ts'
import { TripTtnDocType } from 'views/pages/LogisticsKR/types.ts'
import { refetchFunc } from 'modules/domain/common/hooks.ts'
import { useNotification } from 'hooks/useNotification.tsx'
import { AUploadFileButton } from 'views/components/UploadFileButton/AUploadFileButton.tsx'

type Props = {
  loadType: 'load' | 'unload'
  tripId: string
  ttnRefetch: refetchFunc
  tripsRefetch: () => void
}

export const UploadTtnButton: FC<Props> = ({ loadType, tripId, ttnRefetch, tripsRefetch }) => {
  const { t } = useTranslation('logisticsKR')
  const [uploadProgress, setUploadProgress] = useState<Progress>(Progress.IDLE)
  const notify = useNotification()
  const docType = loadType === 'load' ? TripTtnDocType.load : TripTtnDocType.unload

  const handleUpload = (files) => {
    const uploadPromises = files.map((file) =>
      uploadDocuments(endpoints.LogisticsKR.tripsDocuments(tripId), docType, file),
    )

    setUploadProgress(Progress.WORK)
    return Promise.all(uploadPromises)
      .then(() => {
        setUploadProgress(Progress.SUCCESS)
        tripsRefetch()
        ttnRefetch(true)
      })
      .catch(() => {
        setUploadProgress(Progress.ERROR)
        notify(Progress.ERROR, { title: t('errors:something_went_wrong') })
      })
  }

  return (
    <AUploadFileButton
      eventName="ttn_files"
      progress={uploadProgress}
      onUpload={handleUpload}
      size="small"
      text={t('details.loadTtn')}
      icon={Icons.IconAttach}
      multiple
    />
  )
}
