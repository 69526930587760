import { useCallback, useEffect, useState } from 'react'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { RequestsBoardFilters } from './RequestsBoardFilters/RequestsBoardFilters'
import { ContentWrapper } from '../styled'
import { Icons, useHelmet } from '@agro-club/agroclub-shared'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../../analyticsPlaces'
import { RequestCondition, RequestType } from '../../types'
import DealsKRRoutes from '../routes'
import { AAntdTabs, AButton } from 'views/components/Analytics'
import { generatePath, useNavigate, useParams } from 'react-router'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { format } from 'date-fns'
import { YEAR_MONTH } from 'modules/constants'
import { getMonthList } from 'modules/utils/helpers'
import { useAnalyticsContext } from 'analytics/hooks'
import { OpenRequestsBoardColumns } from './OpenRequestsBoardColumns/OpenRequestsBoardColumns'
import { ClosedRequestBoardColumns } from './ClosedRequestBoardColumns/ClosedRequestBoardColumns'
import { Tab, useTab, useTabsDataV2 } from 'hooks/useTabsDataV2'
import { ListRequestParams } from 'modules/domain/types'

/** @deprecated */
export const defaultListRequestParams: ListRequestParams = {
  page: 1,
  filter: {},
  pageSize: 10000,
}

const nonEditableParams = { pageSize: 10000 }
const currMonth = format(getMonthList()[0], YEAR_MONTH)

export const RequestsBoard = () => {
  const { t } = useTranslation('logisticBids')
  const { setOptions } = useAnalyticsContext()
  const { activeTab: activeTabParam, selectedRequest } = useParams()
  const [activeTab, setActiveTab] = useState(activeTabParam || RequestCondition.open)
  const navigate = useNavigate()

  const tabs: Record<string, Tab> = {}
  tabs[RequestCondition.open] = useTab({
    label: t('tabs.open'),
    nonEditableParams,
  })
  tabs[RequestCondition.closed] = useTab({
    label: t('tabs.closed'),
    initialParams: { filter: { month: currMonth } },
    nonEditableParams,
  })
  const { isFiltersLoaded } = useTabsDataV2({
    activeTab,
    tabs,
    initialCommonRequestParams: { filter: { source: RequestType.internal } },
    indepdentTabs: true,
  })
  const [activeRequest, setActiveRequest] = useState<LogisticRequest | undefined>(undefined)
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)

  useHelmet({ title: t('logisticsKR:list:heading') })

  const openRequests = {
    label: t('tabs.open'),
    key: RequestCondition.open,
    children: (
      <ContentWrapper>
        <OpenRequestsBoardColumns
          setActiveRequest={setActiveRequest}
          activeRequest={activeRequest}
          listRequestParams={tabs[RequestCondition.open].params}
          drawerIsOpen={drawerIsOpen}
          setDrawerIsOpen={setDrawerIsOpen}
          isFiltersLoaded={isFiltersLoaded}
        />
      </ContentWrapper>
    ),
  }

  const closedRequests = {
    label: t('tabs.closed'),
    key: RequestCondition.closed,
    children: (
      <ContentWrapper>
        <ClosedRequestBoardColumns
          setActiveRequest={setActiveRequest}
          activeRequest={activeRequest}
          listRequestParams={tabs[RequestCondition.closed].params}
          drawerIsOpen={drawerIsOpen}
          setDrawerIsOpen={setDrawerIsOpen}
          isFiltersLoaded={isFiltersLoaded}
        />
      </ContentWrapper>
    ),
  }

  const [prevActiveTab, setPrevActiveTab] = useState(activeTab)

  const changeRoute = useCallback(() => {
    if (!selectedRequest || prevActiveTab !== activeTab) {
      navigate(
        {
          pathname: generatePath(DealsKRRoutes.ListRequestsWithTab, { activeTab: activeTab }),
          search: location.search,
        },
        { replace: true },
      )
    } else {
      navigate(
        {
          pathname: generatePath(DealsKRRoutes.ListRequestsWithTabAndRequest, {
            activeTab: activeTab,
            selectedRequest,
          }),
          search: location.search,
        },
        { replace: true },
      )
    }

    setOptions({
      place: activeTab,
    })
  }, [activeTab, navigate, prevActiveTab, selectedRequest, setOptions])

  useEffect(() => {
    changeRoute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  const changeActiveTab = useCallback(
    (tab) => {
      setPrevActiveTab(activeTab)
      setActiveTab(tab)
      setActiveRequest(undefined)
    },
    [activeTab],
  )

  return (
    <APageWrapper page={AnalyticsPages.REQUESTS} place={AnalyticsPlaces.REQUESTS_BOARD.PAGE}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                { breadcrumbName: t('menu:newLogistics') },
                { path: DealsKRRoutes.List, breadcrumbName: t('logisticsKR:list:heading') },
              ]}
            />
            <Header.AbsoluteButtonWrapper>
              {activeRequest?.id && (
                <AButton id="drawer" intent="white" Icon={Icons.IconHidemenu} onClick={() => setDrawerIsOpen(true)} />
              )}
            </Header.AbsoluteButtonWrapper>
          </Layout.TopHeader>
          <Layout.PageName>{t('page_title')}</Layout.PageName>
          <RequestsBoardFilters
            isFiltersLoaded={isFiltersLoaded}
            defaultFilterValues={tabs[activeTab].initialParams}
            listRequestParams={tabs[activeTab].params}
            // TODO rename param
            setListRequestParams={tabs[activeTab].clearTabState}
            updateFilterState={tabs[activeTab].updateFilterState}
            activeTab={activeTab}
          />
        </Layout.Header>
        <AAntdTabs id="tabs" items={[openRequests, closedRequests]} activeKey={activeTab} onChange={changeActiveTab} />
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
