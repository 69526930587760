import { FormGroup, Select } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { FormikFieldsType } from 'modules/domain/types'

type Props = {
  formik: FormikFieldsType
  translationNameSpace?: string
  allowClear?: boolean
  required?: boolean
  fieldName: string
  label?: string
  enumType?: any
  propsOptions?: {
    value: string
    label: string
  }[]
  disabled?: boolean
  multiple?: boolean
  placeholder?: string
}

export const EnumSelect: FC<Props> = ({
  translationNameSpace,
  allowClear = false,
  propsOptions,
  fieldName,
  required,
  enumType,
  disabled,
  formik,
  label,
  multiple,
  placeholder = '',
}) => {
  const { t } = useTranslation()

  const options =
    (enumType && Object.keys(enumType).map((item) => ({ value: item, label: t(`${translationNameSpace}.${item}`) }))) ||
    propsOptions

  return (
    <FormGroup error={formik.errors[fieldName]} label={label} required={required}>
      <Select
        onChange={(value) => formik.setFieldValue(fieldName, value)}
        status={!!formik.errors[fieldName] ? 'error' : ''}
        value={formik.values[fieldName]}
        defaultValue={options[0]}
        allowClear={allowClear}
        disabled={disabled}
        showSearch={false}
        options={options}
        mode={multiple ? 'multiple' : undefined}
        fullWidth
        placeholder={placeholder}
      />
    </FormGroup>
  )
}
