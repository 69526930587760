import { REACT_APP_CURRENCY_SYMBOL } from 'env'
import formatNumber from './formatNumber'

export const labelPrice = (label: string) => {
  return `${label}, ${REACT_APP_CURRENCY_SYMBOL}`
}

const formatPrice = (value?: number | string | null, showCurrency = false, keepDigitsAfterPoint = true) => {
  const number = formatNumber(value, keepDigitsAfterPoint)
  if (showCurrency) {
    return `${number} ${REACT_APP_CURRENCY_SYMBOL}`
  } else {
    return number
  }
}

export default formatPrice
