import formatPhone from 'helpers/formatPhone'
import { User } from 'modules/domain/user/types'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC } from 'react'
import { Card } from 'views/components/Card/Card'
import { UserLinkWithIcons } from 'views/components/UserLinkWithIcons/UserLinkWithIcons'

const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

export const CompanyUsers: FC<{ users: User[] }> = ({ users }) => {
  const { t } = useTranslation()
  return (
    <Card.GapableContent>
      <Card.Title>{t('user:usersMetaTitle')}</Card.Title>
      {users.map((user) => (
        <UserInfoWrapper key={user.id}>
          <UserLinkWithIcons
            user={user}
            isSignificant={user.profile?.is_significant}
            isVerified={user.profile?.is_verified}
            hasShippedDeals={user.profile?.has_shipped_deals}
          />
          <div>{t(`user:profile_type.${user.profile?.profile_type}`)}</div>
          <div>{formatPhone(user.phone)}</div>
        </UserInfoWrapper>
      ))}
    </Card.GapableContent>
  )
}
