import { usePagination, useSingleEntity } from 'modules/domain/common/hooks'
import { AButton } from 'views/components/Analytics'
import { Icons, Progress } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { SampleItem } from './SampleItem'
import { SamplesForBid } from '../types'
import { AddSample } from './AddSample'
import styled from 'styled-components'
import { FC, useEffect, useState } from 'react'
import { ACCORDION_ITEM_PAGE_SIZE } from 'modules/constants'
import { ListFooterWrapper } from 'views/styled/common'
import { ClientPaginationSmall } from 'views/components/ClientSmallPagination/ClientPaginationSmall'
import { endpoints } from 'modules/endpoints'

type Props = {
  bidId: string
  setSummary: (arg: number) => void
}

const Wrapper = styled.div`
  margin-bottom: 12px;
`

export const SampleBlock: FC<Props> = ({ bidId, setSummary }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [progress, samples = [], refetch] = useSingleEntity<SamplesForBid[]>(endpoints.samplesForBid(bidId))
  const [samplesCurrentPage, setSamplesCurrentPage, samplesCurrentItems] = usePagination(
    samples,
    ACCORDION_ITEM_PAGE_SIZE,
  )

  useEffect(() => {
    if (progress === Progress.SUCCESS) setSummary(samples.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samples.length])

  return (
    <div>
      <Wrapper>
        {!!samples?.length &&
          samplesCurrentItems.map((item) => <SampleItem key={item.id} sample={item} refetch={refetch} />)}
      </Wrapper>

      <ListFooterWrapper>
        <AButton id="add_sample" intent="approve" Icon={Icons.IconPlus} onClick={() => setShowModal(true)}>
          {t('common:add')}
        </AButton>
        <ClientPaginationSmall
          total={samples?.length}
          currPage={samplesCurrentPage}
          setCurrPage={setSamplesCurrentPage}
        />
      </ListFooterWrapper>
      {showModal && <AddSample onClose={() => setShowModal(false)} refetch={refetch} bidId={bidId} />}
    </div>
  )
}
