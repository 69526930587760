import { endpoints } from 'modules/endpoints'
import { useTranslation } from 'react-i18next'
import { DashboardTab } from '../types'
import { dashboardGeneratedFilter, dashboardRegionDetailedFilter } from 'env'
import { Tab, useTab, useTabsDataV2 } from 'hooks/useTabsDataV2'
import { getUserTeamId } from 'modules/domain/user/utils'
import { User } from 'modules/domain/user/types'

const PAGE_SIZE = 1000

const pageSize1k = {
  pageSize: PAGE_SIZE,
}

export const useDashboardTabsConfig = (activeTab: string, currentUser?: User) => {
  const { t } = useTranslation('dashboard')

  const tabs: Record<string, Tab> = {}
  tabs[DashboardTab.responses] = useTab({
    label: t('responses'),
    endpoint: endpoints.dashboardResponses(),
    nonEditableParams: pageSize1k,
  })
  tabs[DashboardTab.matches] = useTab({
    label: t('matches'),
    endpoint: endpoints.dashboardMatches(),
    nonEditableParams: pageSize1k,
    initialParams: dashboardGeneratedFilter ? { filter: { exclude_dev_sale_bids: true } } : undefined,
  })
  tabs[DashboardTab.calls] = useTab({
    label: t('callBack'),
    endpoint: endpoints.dashboardCalls(),
  })
  tabs[DashboardTab.newUsers] = useTab({
    label: t('newUsers'),
    endpoint: endpoints.dashboardNewUsers(),
  })
  tabs[DashboardTab.newBids] = useTab({
    label: t('newBids'),
    endpoint: endpoints.dashboardNewBids(),
    nonEditableParams: pageSize1k,
  })

  tabs[DashboardTab.overdueBids] = useTab({
    label: t('expired'),
    endpoint: endpoints.dashboardOverdueBids(),
    nonEditableParams: pageSize1k,
  })

  const team = `${getUserTeamId(currentUser) || ''}`
  const commonInitial = {
    filter: team ? { team } : {},
  }
  const matchesUniqFilters = [
    'is_user_significant',
    'sale_region',
    'sale_district',
    'purchase_region',
    'purchase_district',
    'exclude_dev_sale_bids',
  ]
  const excludeFilters = {}
  Object.values(DashboardTab).forEach((tabKey) => {
    let filterNames: string[] | undefined
    if (tabKey === DashboardTab.matches) {
      filterNames = dashboardRegionDetailedFilter ? ['region'] : undefined
    } else {
      // spreed to prevent reference
      filterNames = [...matchesUniqFilters]
    }
    excludeFilters[tabKey] = filterNames
  })

  const tabsData = useTabsDataV2({
    activeTab,
    tabs,
    initialCommonRequestParams: commonInitial,
    excludeFilters,
  })

  return {
    ...tabsData,
    tabs,
  }
}
