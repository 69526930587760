import { useTranslation } from 'react-i18next'

import { FC } from 'react'
import { AnalyticsFilterVariant } from 'analytics/types'
import { ACheckboxV2 } from 'views/components/Analytics'
import { DashboardTab } from '../types'
import { ListRequestParams } from 'modules/domain/types'

type Props = {
  activeTab: DashboardTab
  listRequestParams: ListRequestParams
  updateFilterState: (ListRequestParams) => void
}

export const UserStatusFilter: FC<Props> = ({
  activeTab,
  listRequestParams: activeRequestParams,
  updateFilterState,
}) => {
  const { t } = useTranslation('dashboard')

  if (activeTab !== DashboardTab.matches) return

  const updateChecked = (val: boolean) => {
    updateFilterState({ is_user_significant: val ? true : undefined })
  }

  return (
    <ACheckboxV2
      onChange={(e) => updateChecked(e.target.checked)}
      checked={
        activeRequestParams.filter.is_user_significant
          ? JSON.parse(activeRequestParams.filter.is_user_significant)
          : undefined
      }
      label={t('is_significantFilter')}
      id={AnalyticsFilterVariant.USER_STATUS}
    />
  )
}
