import { DispatchingStatusesTags } from 'views/components/DispatchingStatusesTags/DispatchingStatusesTags'
import { AMobileModal } from 'views/components/MobileComponents/Modal/AMobileModal'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import formatPrice from 'modules/utils/numbers/formatPrice'
import AComments from 'views/components/Comments/AComments'
import { CommentType } from 'modules/domain/comment/types'
import { Text } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { DispatchingType } from '../../types'
import { endpoints } from 'modules/endpoints'
import { InfoBoard } from './InfoBoard'
import styled from 'styled-components'
import { Payment } from './Payment'
import { FC } from 'react'

type Props = {
  data: DispatchingType
  refetch: () => void
  datesArray: string[]
  onClose: () => void
}

const StyledHeaderText = styled(Text)`
  text-align: center;
`
const WrapperHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px 0;
`
const Companies = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledComentsTitle = styled(Text)`
  text-align: center;
  margin: 16px 0;
`

const Header: FC<{ data: DispatchingType }> = ({ data }) => {
  return (
    <WrapperHeader>
      <DispatchingStatusesTags status={data.status} />
      <div>
        <StyledHeaderText typography="titleH4">
          {`${data.product_title}, ${formatQuantity(data.quantity)},`}
        </StyledHeaderText>
        <StyledHeaderText typography="titleH4">
          {`${formatTarif(data.price_exw)}, (${formatPrice(data.cost, true)})`}
        </StyledHeaderText>
      </div>
      <Companies>
        <Text typography="bodySmall">{data.seller.name}</Text>
        <Text typography="bodySmall">{data.customer.name}</Text>
      </Companies>
    </WrapperHeader>
  )
}

export const Detail: FC<Props> = ({ onClose, data, datesArray, refetch }) => {
  const { t } = useTranslation('dispatching')
  return (
    <AMobileModal header={<Header data={data} />} id="dispatchDetail" headerHeight={158} onClose={onClose}>
      <>
        <InfoBoard data={data} />
        <Payment data={data} datesArray={datesArray} />
        <StyledComentsTitle typography="titleH4">{t('table.comments')}</StyledComentsTitle>
        <AComments
          url={endpoints.dealComment(data.deal_id)}
          type={CommentType.deal}
          onSuccess={refetch}
          id={data.deal_id}
          maxHeight={250}
        />
      </>
    </AMobileModal>
  )
}
