import { useCallback, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { DetailPageName, Header, TopHeader, WrapperContent } from 'views/layouts/NewLayout'
import { Breadcrumbs } from 'views/ui/Header/Header'
import { Progress, useHelmet } from '@agro-club/agroclub-shared'
import { useParams } from 'react-router-dom'
import { useSingleEntity, useTableData } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import DealsKRRoutes from '../routes'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import styled from 'styled-components'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { RequestDetailsAccordion } from './components/RequestsDetailsAccordion'
import { TitleBadges } from './components/TitleBadges'
import { TitleWithBadges } from './components/styled'
import { TablesSectionsWrapper } from './components/RequestTripsTables/styled'
import { RegularTripsTableSection } from './components/RequestTripsTables/RegularTripsTableSection/RegularTripsTableSection'
import { OnApprovalTripsTableSection } from './components/RequestTripsTables/OnApprovalTripsTableSection/OnApprovalTripsTableSection'
import { ListRequestParams } from 'modules/domain/types'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { RequestStatus } from '../../types'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../../analyticsPlaces'
import { TripOffersSection } from './components/RequestTripsTables/TripOffersSection/TripOffersSection'
import { ACCORDION_ITEM_PAGE_SIZE } from 'modules/constants.ts'

const RequestContent = styled.div`
  display: grid;
  grid-template-columns: 1038px 566px;
  column-gap: 24px;
  height: 100%;
  overflow: auto;
`

const RequestDetails = () => {
  const { t } = useTranslation('logisticsKR')
  const { id } = useParams()
  const [fetchProgress, request, refetchRequest] = useSingleEntity<LogisticRequest>(endpoints.LogisticsKR.requests(id))
  const [ttnListParams, setTtnListParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
    pageSize: ACCORDION_ITEM_PAGE_SIZE,
  })
  const {
    progress: ttnListProgress,
    data: ttnList,
    total: ttnListTotal,
    refetch: ttnListRefetch,
  } = useTableData(endpoints.LogisticsKR.getRequestTtnList(id), ttnListParams, true)
  const header = `${request?.product?.name}, ${request?.quantity} ${t('common:t')}, ${request?.distance} ${t(
    'common:km',
  )} `
  useHelmet({ title: `${t('details.heading')} - ${header}` })

  const [approvedParams, setApprovedParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
    filter: { status: RequestStatus.approved },
  })
  const [onApprovalParams, setOnApprovalParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
    filter: { status: RequestStatus.on_approval },
  })

  const refetchTripsTable = useCallback(
    () =>
      setApprovedParams({
        ...defaultListRequestParams,
        filter: { status: RequestStatus.approved },
      }),
    [],
  )
  const refetchApprovingTable = useCallback(
    () =>
      setOnApprovalParams({
        ...defaultListRequestParams,
        filter: { status: RequestStatus.on_approval },
      }),
    [],
  )

  if (fetchProgress === Progress.WORK || !request) return <SpinnerLayout />

  return (
    <APageWrapper page={AnalyticsPages.DETAILS} place={AnalyticsPlaces.DETAILS.PAGE}>
      <WrapperContent>
        <Header>
          <TopHeader>
            <Breadcrumbs
              routes={[
                { breadcrumbName: t('menu:newLogistics') },
                { path: DealsKRRoutes.List, breadcrumbName: t('list.heading') },
                { breadcrumbName: `${t('list.requestN')} ${request.id}` },
              ]}
            />
          </TopHeader>
          <TitleWithBadges>
            <DetailPageName>{header}</DetailPageName>
            <TitleBadges request={request} />
          </TitleWithBadges>
        </Header>
        <RequestContent>
          <TablesSectionsWrapper>
            <RegularTripsTableSection
              listRequestParams={approvedParams}
              setListRequestParams={setApprovedParams}
              ttnListRefetch={ttnListRefetch}
              request={request}
              refetchApprovingTable={refetchApprovingTable}
              refetchTripsTable={refetchTripsTable}
            />

            <TripOffersSection draftTripsRefetch={refetchApprovingTable} request={request} />

            <OnApprovalTripsTableSection
              request={request}
              listRequestParams={onApprovalParams}
              setListRequestParams={setOnApprovalParams}
            />
          </TablesSectionsWrapper>
          <RequestDetailsAccordion
            request={request}
            refetchRequest={refetchRequest}
            setTtnListParams={setTtnListParams}
            ttnListTotal={ttnListTotal}
            ttnListParams={ttnListParams}
            ttnList={ttnList}
            ttnListProgress={ttnListProgress}
            ttnListRefetch={ttnListRefetch}
            refetchTripsTable={refetchTripsTable}
          />
        </RequestContent>
      </WrapperContent>
    </APageWrapper>
  )
}

export default RequestDetails
