import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '@agro-club/agroclub-shared'

type Props = {
  accent?: boolean
  children: ReactNode
}

export const Wrapper = styled.div<{ accent?: boolean }>`
  background-color: ${({ accent }) => (accent ? defaultTheme.color.accentNotify16a : 'transparent')};
  width: fit-content;
`

export const AddressTitle: FC<Props> = ({ accent, children }) => {
  return <Wrapper accent={accent}>«{children}»</Wrapper>
}
