import { FC } from 'react'
import { RadioItemWrapper, RadioWrapper } from './styled'
import { PaymentInfoItems } from 'views/pages/Dispatching/helpers'
import { AntRadio, Icons } from '@agro-club/agroclub-shared'

interface Props {
  value: string
  onChange: (val: string) => void
}

export const PaymentRadioButtons: FC<Props> = ({ value, onChange }) => {
  return (
    <RadioWrapper>
      <AntRadio.Group onChange={(e) => onChange(e.target.value)} value={value}>
        {PaymentInfoItems.map((item) => (
          <RadioItemWrapper
            $color={item.color}
            $borderColor={item.borderColor}
            $checkColor={item.checkMarkColor}
            key={item.value}
          >
            <AntRadio value={item.value}>
              <Icons.IconDone />
            </AntRadio>
          </RadioItemWrapper>
        ))}
      </AntRadio.Group>
    </RadioWrapper>
  )
}
