import { Icons, Progress, Text, antNotification, defaultTheme } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { ReactNode } from 'react'

antNotification.config({
  placement: 'top',
  duration: 3,
  maxCount: 5,
  closeIcon: <Icons.IconClose />,
})

export const useNotification = () => {
  const { t } = useTranslation('common')

  const notify = (status: Progress, { title, text }: { title?: ReactNode; text?: ReactNode } = {}) => {
    const success = status === Progress.SUCCESS
    const error = status === Progress.ERROR
    const messageTitle = title || (success && t('notifySuccess')) || (error && t('notifyError'))
    const backgroundColor =
      ((success && defaultTheme.color.accentApprove100) as string) ||
      ((error && defaultTheme.color.accentDestruct100) as string)

    antNotification.open({
      message: (
        <Text typography="accentLarge" color="primary">
          {messageTitle}
        </Text>
      ),
      description: text && (
        <Text color="secondary" typography="bodyMedium">
          {text}
        </Text>
      ),
      duration: 4,
      style: {
        backgroundColor,
        borderRadius: '8px',
      },
    })
  }
  return notify
}
