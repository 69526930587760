import { formatPriceLogistics } from 'modules/utils/numbers/formatPriceLogistics'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import BidContractsRoutes from 'views/pages/BidContracts/routes'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { BidStatus } from 'modules/domain/bid/types'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { FC, Fragment, ReactNode } from 'react'

export type ChangesHistoryField = 'price_exw' | 'price_cpt' | 'price_logistics' | 'quantity' | 'status' | 'executors'

interface Props {
  changes: Record<ChangesHistoryField | 'sale_bid' | 'purchase_bid', string | boolean | BidStatus>
}

export const HistoryChangesCell: FC<Props> = ({ changes }) => {
  const { t } = useTranslation('deal')

  const formatLink = (id: string) => (
    <TableTextLink to={generatePath(BidContractsRoutes.Details, { id })} id="contract" target="_blank">
      {t('rematch.newContract')}
    </TableTextLink>
  )

  const formatters: Record<string, (value: any) => ReactNode> = {
    sale_price: (v) => `${t('common:salePrice')}: ${formatTarif(v)}`,
    purchase_price: (v) => `${t('common:purchasePrice')}: ${formatTarif(v)}`,
    price_carrier: (v) => `${t('fields.carrier_price')}: ${formatPriceLogistics(v)}`,
    price_logistics: (v) => `${t('fields.freight_rate_max')}: ${formatPriceLogistics(v)}`,
    quantity: (v) => `${t('common:volume')}: ${formatQuantity(v)}`,
    status: (v) => `${t('common:status')}: ${t(`statuses.${v}`)}`,
    executors: () => t('common:assignees'),
    sale_bid: formatLink,
    purchase_bid: formatLink,
  }

  const formattedChanges = Object.entries(changes)
    .filter(([key]) => formatters[key])
    .map(([key, value]) => formatters[key](value))

  return (
    <div>
      {formattedChanges.map((entry, index) => (
        <Fragment key={index}>
          {entry}
          {index < formattedChanges.length - 1 ? ', ' : ''}
        </Fragment>
      ))}
    </div>
  )
}
