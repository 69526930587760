import { Navigate, Route, Routes } from 'react-router-dom'
import BidPage from 'views/pages/Bid/BidPage'
import BidRoutes from 'views/pages/Bid/routes'
import CallsPage from 'views/pages/Calls/CallsPage'
import CallsRoutes from 'views/pages/Calls/routes'
import CallStatisticsPage from 'views/pages/CallStatistics/CallStatisticsPage'
import CallStatisticsRoutes from 'views/pages/CallStatistics/routes'
import DashboardRoutes from 'views/pages/Dashboard/routes'
import DealPage from 'views/pages/Deal/DealPage'
import DealRoutes from 'views/pages/Deal/routes'
import SpecificationsRoutes from 'views/pages/Specifications/routes'
import SpecificationsPage from 'views/pages/Specifications/SpecificationsPage'
/* import TaskRoutes from 'views/pages/Task/routes'
import TaskPage from 'views/pages/Task/TaskPage' */
import TeamsPage from 'views/pages/Teams/TeamsPage'
import UsersRoutes from 'views/pages/User/routes'
import UserPage from 'views/pages/User/UserPage'
import CompanyRoutes from 'views/pages/Company/routes'
import CompanyPage from 'views/pages/Company/CompanyPage'

import TeamsRoutes from 'views/pages/Teams/routes'
import { TasksPage } from 'views/pages/MyTasks/TasksPage'
import TasksRoutes from 'views/pages/MyTasks/routes'
import { DocumentRequestsRoutes } from 'views/pages/DocumentRequests/routes'
import { DocumentRequestsPage } from 'views/pages/DocumentRequests/DocumentRequestsPage'
import DashboardPage from 'views/pages/Dashboard/DashboardPage'
import DispatchingRoutes from 'views/pages/Dispatching/routes'
import DealsKRRoutes from 'views/pages/LogisticsKR/LogisticRequests/routes'
import LogisticRequestsPage from 'views/pages/LogisticsKR/LogisticRequests/LogisticRequestsPage'
import CarsKRPage from 'views/pages/LogisticsKR/CarsKR/CarsKRPage'
import CarsKRRoutes from 'views/pages/LogisticsKR/CarsKR/routes'
import CarriersKRPage from 'views/pages/LogisticsKR/CarriersKR/CarriersKRPage'
import CarriersKRRoutes from 'views/pages/LogisticsKR/CarriersKR/routes'
import CustomersKRPage from 'views/pages/LogisticsKR/CustomersKR/CustomersKRPage'
import CustomersKRRoutes from 'views/pages/LogisticsKR/CustomersKR/routes'
import WarehousesKRPage from 'views/pages/LogisticsKR/WarehousesKR/WarehousesKRPage'
import WarehousesKRRoutes from 'views/pages/LogisticsKR/WarehousesKR/routes'
import WhatsAppRoutes from 'views/pages/WhatsApp/routes'
import WhatsAppPage from 'views/pages/WhatsApp/WhatsAppPage'
import SamplesRoutes from 'views/pages/Samples/routes'
import SamplesPage from 'views/pages/Samples/SamplesPage'
import { ContractsRoutes } from 'views/pages/Contracts/routes'
import { ContractsPage } from 'views/pages/Contracts/ContractsPage'
import RatesRoutes from 'views/pages/LogisticsKR/Rates/routes'
import RatesPage from 'views/pages/LogisticsKR/Rates/RatesPage'
import BidContractsRoutes from 'views/pages/BidContracts/routes'
import BidContractsPage from 'views/pages/BidContracts/BidContractsPage'
import CommonRoutes from 'views/pages/commonRoutes'
import CalculatorRoutes from 'views/pages/Сalculator/routes'
import { CalculatorPage } from 'views/pages/Сalculator/CalculatorPage'
import RoutesRoutes from 'views/pages/Routes/routes'
import { RoutesPage } from 'views/pages/Routes/RoutesPage'
import ShipmentControlRoutes from 'views/pages/ShipmentsControl/routes'
import { ShipmentsControlList } from 'views/pages/ShipmentsControl/ShipmentsControlList'
import DispatchingPage from 'views/pages/Dispatching/DispatchingPage'

export const CoordinatorsRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={CompanyRoutes.List} />} />
      <Route path="*" element={<Navigate to={CommonRoutes.NotFound} />} />
      <Route path={DashboardRoutes.relativePage} element={<DashboardPage />} />
      <Route path={TeamsRoutes.relativePage} element={<TeamsPage />} />
      <Route path={CalculatorRoutes.relativePage} element={<CalculatorPage />} />
      <Route path={CompanyRoutes.relativePage} element={<CompanyPage />} />
      <Route path={TasksRoutes.relativePage} element={<TasksPage />} />
      <Route path={UsersRoutes.relativePage} element={<UserPage />} />
      <Route path={BidRoutes.relativePage} element={<BidPage />} />
      <Route path={BidContractsRoutes.relativePage} element={<BidContractsPage />} />
      <Route path={DealRoutes.relativePage} element={<DealPage />} />
      <Route path={DispatchingRoutes.relativePage} element={<DispatchingPage />} />
      <Route path={ShipmentControlRoutes.relativePage} element={<ShipmentsControlList />} />
      <Route path={CallsRoutes.relativePage} element={<CallsPage />} />
      <Route path={WhatsAppRoutes.relativePage} element={<WhatsAppPage />} />
      <Route path={CallStatisticsRoutes.relativePage} element={<CallStatisticsPage />} />
      <Route path={SpecificationsRoutes.relativePage} element={<SpecificationsPage />} />
      <Route path={DocumentRequestsRoutes.RelativePage} element={<DocumentRequestsPage />} />
      <Route path={ContractsRoutes.relativePage} element={<ContractsPage />} />
      <Route path={DealsKRRoutes.relativePage} element={<LogisticRequestsPage />} />
      <Route path={CarsKRRoutes.relativePage} element={<CarsKRPage />} />
      <Route path={CarriersKRRoutes.relativePage} element={<CarriersKRPage />} />
      <Route path={CustomersKRRoutes.relativePage} element={<CustomersKRPage />} />
      <Route path={WarehousesKRRoutes.relativePage} element={<WarehousesKRPage />} />
      <Route path={SamplesRoutes.relativePage} element={<SamplesPage />} />
      <Route path={RatesRoutes.relativePage} element={<RatesPage />} />
      <Route path={RoutesRoutes.relativePage} element={<RoutesPage />} />
    </Routes>
  )
}
