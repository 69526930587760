import { FormikProps } from 'formik'
import { CloningFormFields } from '../../types'
import FormSelectShared from 'views/components/FormSelectShared'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { ProfileType } from 'modules/domain/user/types'
import { isBrazil } from 'env'

interface Props {
  formik: FormikProps<CloningFormFields>
  onSelectedOptionLoad: (option: any) => void
  currentProfileType: ProfileType
  fieldResetHandler: () => void
}

export const FormSelectUser = ({ formik, onSelectedOptionLoad, currentProfileType, fieldResetHandler }: Props) => {
  const { t } = useTranslation('deal')

  const getUserLabel = (dto) => {
    const name = dto.profile.full_name
    const isNotEmpty = /[^\s]/.test(name)

    if (isNotEmpty) return name

    return `${t('common:withoutName')} ID:${dto.id}`
  }

  return (
    <FormSelectShared
      formik={formik}
      fieldName="user"
      label={t('bidCloningModal.user')}
      endpoint={endpoints.activeMarketUsers(currentProfileType)}
      filter={{ sort: 'last_name', with_company: true, with_contract: isBrazil ? true : undefined }}
      onChange={fieldResetHandler}
      getLabel={getUserLabel}
      onSelectedOptionLoaded={onSelectedOptionLoad}
      searchField="search"
    />
  )
}
