import { SharedModalContent, SharedModalInputs } from 'views/components/SharedModal/styled'
import { SeparatorWithTitle } from 'views/components/SeparatorWithTitle/SeparatorWithTitle'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { FormTeamAndAssignee } from 'views/components/form/FormTeamAndAssignee'
import { Preload, ResponseCoordinatorsTaskStats } from './DistributeBlock'
import { TaskManagementForm } from './components/TaskManagementForm'
import { DateISO, Progress } from '@agro-club/agroclub-shared'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useEntityTaskStat } from './useEntityTaskStat'
import { apiClient } from 'modules/utils/httpClient'
import { BoardItem } from './components/BoardItem'
import { useAFormHandler } from 'analytics/hooks'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import styled from 'styled-components'
import { InfoBoard } from './styled'
import { useFormik } from 'formik'

type Fields = {
  old_assignees: string[]
  new_assignees: string[]
  old_team: string | null
  new_team: string | null
  tasks_per_day: string
  other_task_per_day: string | null
  start_deadline_date: DateISO | undefined
}
type Props = {
  onClose: () => void
  children: ReactNode
  refetch: () => void
}

const OneItemInBoard = styled.div`
  margin: auto;
`

export const CoordinatorsStatBoard = ({
  progress,
  data,
  title,
}: {
  data?: ResponseCoordinatorsTaskStats
  progress: Progress
  title: string
}) => {
  return (
    <InfoBoard>
      {progress === Progress.WORK
        ? Preload
        : data && (
            <OneItemInBoard>
              <BoardItem title={title} value={data.total_tasks} />
            </OneItemInBoard>
          )}
    </InfoBoard>
  )
}

export const RearrangeBlock: FC<Props> = ({ onClose, children, refetch }) => {
  const { t } = useTranslation('my_tasks')
  const [coordinatorsStat, setCoordinatorsStat] = useState<ResponseCoordinatorsTaskStats>()

  const { formProgress, formHandler } = useAFormHandler('distributeTasks')

  const [coordinatorsStatProgress, getCoordinatorsStat] = useEntityTaskStat<ResponseCoordinatorsTaskStats>(
    setCoordinatorsStat,
    endpoints.getCoordinatorTaskStats,
  )

  const formik = useFormik<Fields>({
    initialValues: {
      old_assignees: [],
      new_assignees: [],
      old_team: null,
      new_team: null,
      tasks_per_day: '50',
      start_deadline_date: undefined,
      other_task_per_day: null,
    },

    onSubmit: formHandler(
      async () => {
        const data = {
          old_assignees: formik.values.old_assignees,
          new_assignees: formik.values.new_assignees,
          tasks_per_day:
            formik.values.tasks_per_day === 'other' ? formik.values.other_task_per_day : formik.values.tasks_per_day,
          start_deadline_date: formik.values.start_deadline_date,
        }
        await apiClient.post(endpoints.reassignTasks(), data)
      },
      {
        onSuccess: () => {
          refetch()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  useEffect(() => {
    if (formik.values.old_assignees.length) {
      getCoordinatorsStat({
        assignee: formik.values.old_assignees,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.old_assignees])

  return (
    <>
      <SharedModalContent>
        <SharedModalInputs>
          {children}
          <SeparatorWithTitle title={t('task_distribution.pick_up_tasks')} />
          <FormTeamAndAssignee formik={formik} teamField="old_team" assigneeField="old_assignees" />
          {!!formik.values.old_assignees.length && (
            <CoordinatorsStatBoard
              title={t('task_distribution.total_tasks')}
              progress={coordinatorsStatProgress}
              data={coordinatorsStat}
            />
          )}

          <SeparatorWithTitle title={t('task_distribution.rearrange_tasks')} />

          <TaskManagementForm formik={formik} teamField="new_team" assigneeField="new_assignees" />
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:save')}
        progress={formProgress}
        onClose={onClose}
      />
    </>
  )
}
