import { BidPrice } from 'modules/domain/bid/types'
import { ProfileType } from 'modules/domain/user/types'
import { useTranslation } from 'react-i18next'
import { FC, useEffect } from 'react'
import { FormikProps } from 'formik'
import { ShortFormControl } from 'views/styled/common'
import { EnumSelect } from 'views/components/form/EnumSelect'
import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { USAddBidFields } from 'views/pages/Bid/BidModals/Bid/USModals/types'
import { isDeliveryAddressRequired } from '../../helpers'

type UsProps = {
  formik: FormikProps<USAddBidFields>
  ownerProfileType: ProfileType
}

export const USATypePriceControl: FC<UsProps> = ({ formik, ownerProfileType }) => {
  const { t } = useTranslation('bid')
  const needDeliveryData = isDeliveryAddressRequired(ownerProfileType, formik.values.price_type)

  useEffect(() => {
    if (needDeliveryData) {
      formik.setFieldValue('owner_delivery_distance', undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needDeliveryData])

  return (
    <ShortFormControl>
      <EnumSelect
        label={t('form.delivery')}
        translationNameSpace="bid:usDeliveryOptions"
        enumType={BidPrice}
        fieldName="price_type"
        formik={formik}
        required
      />

      {ownerProfileType && isDeliveryAddressRequired(ownerProfileType, formik.values.price_type) && (
        <FormGroup label={t('form.distance')} error={formik.errors.owner_delivery_distance}>
          <Input
            {...formik.getFieldProps('owner_delivery_distance')}
            invalid={!!formik.errors.owner_delivery_distance}
            type="number"
          />
        </FormGroup>
      )}
    </ShortFormControl>
  )
}
