import { DealCondition, DealStatusTypes, ListDeal, MarginsforDeals } from 'modules/domain/deal/types'
import { BoardColumn, BoardContent, BoardHeader } from 'views/components/Board/CardsBoard'
import { PreloadCard, PreloadCount } from 'views/components/Board/Preload'
import { AntSkeleton, Progress, Text } from '@agro-club/agroclub-shared'
import { NoDataCard } from 'views/components/Board/NoDataCard'
import { useTableData } from 'modules/domain/common/hooks'
import { ListRequestParams } from 'modules/domain/types'
import { Count } from 'views/components/Board/styled'
import { DataMargin } from '../helpers/DataMargin'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router'
import { FC, useEffect } from 'react'
import { DealCard } from './DealCard'

type Props = {
  column: { statusDeal: DealStatusTypes; label: string }
  listRequestParams: ListRequestParams
  margins: MarginsforDeals | undefined
  revenues?: MarginsforDeals
  dealsLoadingStatus?: Progress
  progressMargin: Progress
  typeDeals: DealCondition
  isFiltersLoaded: boolean
  activeTab: string
}

export const PreloadMargin = () => (
  <AntSkeleton.Button active size="small" style={{ height: 20, width: 200, marginTop: 4 }} />
)

export const Column: FC<Props> = ({
  listRequestParams,
  isFiltersLoaded,
  progressMargin,
  activeTab,
  typeDeals,
  revenues,
  margins,
  column,
}) => {
  const { statusDeal, label } = column

  const getEndpoint = (status: DealStatusTypes) => {
    if (typeDeals === DealCondition.open) {
      return endpoints.dealActualWithStatus(status)
    }

    return endpoints.dealClosedWithStatus(status)
  }

  const { progress: dealsLoadingStatus, data: deals = [] } = useTableData<ListDeal>(
    getEndpoint(statusDeal),
    listRequestParams,
    isFiltersLoaded,
  )
  const loadDeals = dealsLoadingStatus === Progress.WORK

  const moneyBlock =
    !margins || progressMargin === Progress.WORK ? (
      PreloadMargin()
    ) : (
      <DataMargin status={statusDeal} margins={margins} revenues={revenues} />
    )

  const { selectedDeal: selectedDealParams } = useParams()

  useEffect(() => {
    if (selectedDealParams && dealsLoadingStatus === Progress.SUCCESS) {
      const el = document.getElementById(`${selectedDealParams + 'active'}`)
      el?.scrollIntoView({ block: 'center', inline: 'center' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealsLoadingStatus])

  return (
    <BoardColumn>
      <BoardHeader>
        <Text as="span" typography="titleH4">
          {label}
          {loadDeals ? <PreloadCount /> : <Count color={deals.length ? 'orange' : 'grey'}>{deals.length}</Count>}
        </Text>
        {typeDeals == DealCondition.open ? moneyBlock : null}
      </BoardHeader>
      <BoardContent>
        {loadDeals ? (
          <PreloadCard />
        ) : (
          deals.map((deal) => <DealCard key={deal.id} deal={deal} activeTab={activeTab} />)
        )}
        {!loadDeals && !deals.length && <NoDataCard />}
      </BoardContent>
    </BoardColumn>
  )
}
