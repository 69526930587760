import { SetStateAction, useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { ListRequestParams, ParamLoadSteps } from 'modules/domain/types'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import useUpdateLocationQuery from './useUpdateLocationQuery'
import { ListRequestedParams } from 'modules/domain/specification/types'

export type TabProps = {
  label: string
  initialParams?: Partial<ListRequestParams>
  endpoint?: string
  columnsFunc?: any
  /** nonEditableParams - params which used in network, but we don't want to see them in page url or allow to change them */
  nonEditableParams?: Partial<ListRequestParams>
}

export type Tab = TabProps & {
  params: ListRequestParams
  setter: React.Dispatch<SetStateAction<ListRequestParams>>
  updateFilterState: (filterObj: Record<string, any>) => void
  clearTabState: (defaultParams: Partial<ListRequestParams>) => void
  loadStep: ParamLoadSteps
  setLoadStep: (ParamLoadSteps) => void
}

export const useTab = ({ label, initialParams = {}, endpoint, columnsFunc, nonEditableParams = {} }: TabProps) => {
  const [params, setter] = useState<ListRequestParams>({
    ...defaultListRequestParams,
    ...initialParams,
  })
  const [nonEditableMemo] = useState(nonEditableParams)
  const [loadStep, setLoadStep] = useState<ParamLoadSteps>(ParamLoadSteps.Initial)

  const updateFilterState = useCallback((filterObj: Record<string, any>) => {
    setter((prev) => ({
      ...prev,
      page: 1,
      filter: {
        ...prev.filter,
        ...filterObj,
      },
    }))
  }, [])

  const clearTabState = useCallback(
    (defaultParams: Partial<ListRequestParams> = {}) => {
      const nonEditableFilters = nonEditableMemo?.filter ? nonEditableMemo?.filter : {}
      const defaultParamsFilters = defaultParams?.filter ? defaultParams?.filter : {}
      setter({
        ...defaultListRequestParams,
        ...defaultParams,
        ...nonEditableMemo,
        ...{ filter: { ...defaultParamsFilters, ...nonEditableFilters } },
      })
    },
    [nonEditableMemo],
  )

  return {
    params,
    setter,
    label,
    initialParams,
    endpoint,
    columnsFunc,
    nonEditableParams: nonEditableMemo,
    loadStep,
    setLoadStep,
    updateFilterState,
    clearTabState,
  }
}

export type TabsData = {
  isFiltersLoaded: boolean
  commonRequestParams: ListRequestParams
  updateCommonFilterState: (filterObj: Record<string, any>) => void
  clearAllTabsState: () => void
}

type TabsHookProps = {
  activeTab: string
  tabs: Record<string, Tab>
  initialCommonRequestParams?: Partial<ListRequestParams>
  indepdentTabs?: boolean
  /** excludeFilters need when commonParams used(ie not used indepdentTabs), but some params not used in some tabs */
  excludeFilters?: Record<string, string[]>
}

export const useTabsDataV2 = ({
  activeTab,
  tabs,
  initialCommonRequestParams = {},
  indepdentTabs = false,
  excludeFilters,
}: TabsHookProps): TabsData => {
  const [commonRequestParams, setCommonRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
    ...initialCommonRequestParams,
  })
  const [paramsForUrl, setParamsForUrl] = useState<ListRequestParams>({
    ...defaultListRequestParams,
  })

  const [isFiltersLoaded, setIsFiltersLoaded] = useState(false)

  const isFiltersLoadedFromUrl = useUpdateLocationQuery({
    listRequestParams: paramsForUrl,
    setListRequestParams: setParamsForUrl,
  })

  // init all tabs
  useEffect(() => {
    if (!isFiltersLoadedFromUrl) return
    let newCommon: ListRequestedParams
    const isUrlFilterEmpty = !Object.keys(paramsForUrl.filter).length
    if (!indepdentTabs) {
      newCommon = {
        ...commonRequestParams,
        filter: isUrlFilterEmpty ? { ...commonRequestParams.filter } : { ...paramsForUrl.filter },
      }
      setCommonRequestParams(newCommon)
    }
    for (const [key] of Object.entries(tabs)) {
      const isActiveTab = key === activeTab
      const tab = tabs[key]
      const nonEditableParams = tab.nonEditableParams ? tab.nonEditableParams : {}
      const nonEditableFilters = nonEditableParams.filter ? nonEditableParams.filter : {}
      const initialParams = tab.initialParams ? tab.initialParams : {}
      const initialFilters = initialParams.filter ? initialParams.filter : {}
      let tabFilters
      if (isUrlFilterEmpty) {
        tabFilters = { ...initialFilters, ...commonRequestParams.filter, ...nonEditableFilters }
      } else {
        if (indepdentTabs) {
          tabFilters = isActiveTab
            ? paramsForUrl.filter
            : { ...initialFilters, ...commonRequestParams.filter, ...nonEditableFilters }
        } else {
          const toOmit = excludeFilters?.[key]
          let urlParams = paramsForUrl.filter
          if (toOmit) {
            urlParams = _.omit(urlParams, toOmit)
          }
          tabFilters = { ...urlParams, ...nonEditableFilters }
        }
      }
      tabs[key].setter({
        ...initialParams,
        ...nonEditableParams,
        filter: tabFilters,
        page: isActiveTab ? paramsForUrl.page : 1,
        sort: isActiveTab ? paramsForUrl.sort : undefined,
      })
      tabs[key].setLoadStep(ParamLoadSteps.Loaded)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFiltersLoadedFromUrl])

  // last tab setter will be called at the end , so if it initilized, all others should initialized too
  const tabsArr = Object.values(tabs)
  const lastTab = tabsArr[tabsArr.length - 1]
  // setIsFiltersLoaded - tabs is ready to use
  useEffect(() => {
    if (lastTab?.loadStep === ParamLoadSteps.Loaded) {
      setIsFiltersLoaded(true)
    }
  }, [lastTab?.loadStep])

  const activeTabParams = tabs[activeTab].params
  const activeTabNonEditableParams = tabs[activeTab].nonEditableParams

  // sync current tab state to url
  useEffect(() => {
    if (!isFiltersLoaded) return
    const nonEditableParams = activeTabNonEditableParams
    const paramsToOmit = nonEditableParams ? Object.keys(nonEditableParams) : []
    const filtersToOmit = nonEditableParams?.filter ? Object.keys(nonEditableParams.filter) : []
    const urlParams = {
      ..._.omit(activeTabParams, paramsToOmit),
      filter: { ..._.omit(activeTabParams.filter, filtersToOmit) },
    } as ListRequestedParams
    setParamsForUrl(urlParams)
  }, [activeTabNonEditableParams, activeTabParams, isFiltersLoaded])

  const clearAllTabsState = (defaultParams: Record<string, any> = {}) => {
    setCommonRequestParams({ ...defaultListRequestParams, ...defaultParams })
    // we need to reset each table to default to drop sorting
    for (const key of Object.keys(tabs)) {
      tabs[key].clearTabState(defaultParams)
    }
  }

  const updateCommonFilterState = useCallback((filterObj: Record<string, any>) => {
    setCommonRequestParams((prev) => ({
      ...prev,
      page: 1,
      filter: {
        ...prev.filter,
        ...filterObj,
      },
    }))

    for (const [key] of Object.entries(tabs)) {
      const toOmit = excludeFilters?.[key]
      tabs[key].setter((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          ..._.omit(filterObj, toOmit),
        },
        page: 1,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isFiltersLoaded,
    commonRequestParams,
    updateCommonFilterState,
    clearAllTabsState,
  }
}
