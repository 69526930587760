import { Progress } from '@agro-club/agroclub-shared'
import { RequestError } from 'modules/errors'
import { useState } from 'react'
import { useIsMounted } from './useIsMounted'
import { AxiosError } from 'axios'

const ERR_CANCELED = 'ERR_CANCELED'

const isCancelRequestError = (error: AxiosError) => error.code === ERR_CANCELED

export const useProgress = <T, Args extends any[] = any[]>(
  request: (...args: Args) => Promise<T | undefined>,
  optParams?: {
    // This props is needed to not lose the load indication between canceled and new requests
    showLoadingAfterCancel?: boolean
  },
): [Progress, typeof request, ReturnType<typeof RequestError.parseError> | null] => {
  const [progress, setProgress] = useState(Progress.IDLE)
  const [error, setError] = useState<ReturnType<typeof RequestError.parseError> | null>(null)
  const isMounted = useIsMounted()

  const sendRequest = async (...args: Args) => {
    setProgress(Progress.WORK)

    try {
      const response = await request(...args)
      if (isMounted.current) setProgress(Progress.SUCCESS)
      setError(null)

      return response
    } catch (error) {
      if (isCancelRequestError(error as AxiosError) && optParams?.showLoadingAfterCancel) {
        setProgress(Progress.WORK)
      } else {
        setError(RequestError.parseError(error))
        setProgress(Progress.ERROR)
        throw error
      }
    }
  }

  return [progress, sendRequest, error]
}
