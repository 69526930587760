import { defaultTheme, Typography, Icons } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { ARouterLink } from 'views/components/Analytics'
import { CustomScroll, HoverScrolling } from 'views/styled/common'

type Props = {
  margin: number
}

export const ContentDeals = styled.div`
  overflow-x: auto;

  ${CustomScroll}
  .ant-collapse-header {
    cursor: auto !important;
  }

  .ant-collapse-content-box {
    max-height: calc(100dvh - 300px) !important;

    ${HoverScrolling}
  }
`
export const HeaderCollapse = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0 16px 16px;
  background: ${defaultTheme.color.backgroundSecondary};
  border-radius: 8px 8px 0 0;

  span {
    display: flex;
    align-items: center;
  }
`

export const CardWrapper = styled.div<{ $isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  padding: 12px;
  border: 1px solid;
  border-color: ${({ $isActive }) =>
    $isActive ? `${defaultTheme.color.primary50a}` : `${defaultTheme.color.secondary50}`};
  border-radius: 8px;
  transition: background-color ease-in 0.2s;
  min-width: 298px;
  row-gap: 8px;

  &:hover {
    background-color: ${defaultTheme.color.primary8a};
    border-color: ${defaultTheme.color.primary8a};
  }

  ${Typography.bodyLarge}
`
export const CompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StatusesWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`

export const StatusIcons = styled.div`
  display: flex;
  column-gap: 4px;
`
export const Company = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: fit-content;
  ${Typography.accentLarge}
`
export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  align-items: flex-start;
`

export const SignedMargin = styled.div<Props>`
  ${Typography.accentLarge}
  color: ${({ margin }) =>
    margin > 0 ? `${defaultTheme.color.accentApprove600}` : `${defaultTheme.color.accentNotify800}`};
`
export const MarginOnWay = styled.div`
  ${Typography.bodyMedium}
`
export const ActualMargin = styled.div`
  ${Typography.accentMedium}
`
export const FixedMargin = styled.span`
  ${Typography.accentLarge} //в шаред
  background: rgba(255, 153, 0, 0.16);
`
export const Product = styled.div`
  width: 250px;
`

export const IconTrack = styled(Icons.IconPickup)<{ $approve: boolean }>`
  max-width: 16px;
  height: auto;

  path {
    fill: ${({ $approve }) => ($approve ? `${defaultTheme.color.primary600}` : `${defaultTheme.color.secondary300}`)};
  }
`

export const IconUser = styled(Icons.IconAccount)<{ $approve: boolean }>`
  max-width: 16px;
  height: auto;

  path {
    fill: ${({ $approve }) => ($approve ? `${defaultTheme.color.primary600}` : `${defaultTheme.color.secondary300}`)};
  }
`
export const IconSample = styled(Icons.IconSampling)<{ $approve: boolean }>`
  max-width: 16px;
  height: auto;

  path {
    fill: ${({ $approve }) => ($approve ? `${defaultTheme.color.primary600}` : `${defaultTheme.color.secondary300}`)};
  }
`
export const IconInstructions = styled(Icons.IconOrders1)<{ $approve: boolean }>`
  max-width: 16px;
  height: auto;

  path {
    fill: ${({ $approve }) => ($approve ? `${defaultTheme.color.primary600}` : `${defaultTheme.color.secondary300}`)};
  }
`

export const IconSpecification = styled(Icons.IconOrders1)<{ $markSpecification: boolean }>`
  max-width: 16px;
  height: auto;

  path {
    fill: ${({ $markSpecification }) =>
      $markSpecification ? `${defaultTheme.color.primary600}` : `${defaultTheme.color.secondary300}`};
  }
`

export const IconDocuments = styled(Icons.IconGdprData)<{ $markDocumentsRequest: boolean }>`
  max-width: 16px;
  height: auto;

  path {
    fill: ${({ $markDocumentsRequest }) =>
      $markDocumentsRequest ? `${defaultTheme.color.primary600}` : `${defaultTheme.color.secondary300}`};
  }
`

export const IconContracts = styled(Icons.IconDescription)<{ $hasSaleContractRequest: boolean }>`
  max-width: 16px;
  height: auto;

  path {
    fill: ${({ $hasSaleContractRequest }) =>
      $hasSaleContractRequest ? `${defaultTheme.color.primary600}` : `${defaultTheme.color.secondary300}`};
  }
`

export const IconOneC = styled(Icons.IconOnec)<{ $approve: boolean }>`
  max-width: 16px;
  height: auto;

  path {
    fill: ${({ $approve }) => ($approve ? `${defaultTheme.color.primary600}` : `${defaultTheme.color.secondary300}`)};
  }
`

export const Link = styled(ARouterLink)`
  color: ${defaultTheme.color.textPrimary900};

  &:hover {
    text-decoration: none;
  }
`
export const NoBid = styled.div`
  border: 1px dashed ${defaultTheme.color.magenta};
  color: ${defaultTheme.color.magenta};
  border-radius: 8px;
  background: rgba(235, 47, 150, 0.08);
  padding: 12px 8px;
  display: flex;
  justify-content: center;
`
