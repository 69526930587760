import { FC, ReactNode } from 'react'

import styled from 'styled-components'
import { defaultTheme, Typography } from '@agro-club/agroclub-shared'

const InfoTitleWrapper = styled.div`
  display: flex;
  width: 616px;
  align-items: center;
  justify-content: space-between;
`

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${defaultTheme.color.secondary50};
`

const Title = styled.div`
  ${Typography.accentSmall}
  color: ${defaultTheme.color.textPrimary900};
  width: auto;
  margin: 0 8px;
  white-space: nowrap;
`

type Props = {
  title: ReactNode
}

export const SeparatorWithTitle: FC<Props> = ({ title }) => {
  return (
    <InfoTitleWrapper>
      <Line />
      <Title>{title}</Title>
      <Line />
    </InfoTitleWrapper>
  )
}
