import { FC } from 'react'
import { t } from 'i18next'
import { endpoints } from 'modules/endpoints'
import { ListRequestParams } from 'modules/domain/types'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { AnalyticsFilterVariant } from 'analytics/types'

type Props = {
  listRequestParams: ListRequestParams
  updateFilterState: any
}

export const TeamsFilter: FC<Props> = ({ updateFilterState, listRequestParams }) => {
  return (
    <ABaseTableFilter
      id={AnalyticsFilterVariant.TEAMS}
      onChange={(assigned_team) => updateFilterState({ assigned_team, assignee: undefined })}
      value={listRequestParams.filter.assigned_team}
      placeholder={t('bid:filterByTeams')}
      getLabel={(label) => label.label}
      endpoint={endpoints.tasksTeamsChoices()}
      simpleApiFormat
      showSearch={false}
      multiple
    />
  )
}
