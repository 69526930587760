import { AButton } from 'views/components/Analytics'
import { Icons } from '@agro-club/agroclub-shared'
import { Separator, WrapperButton } from './styled'
import { generatePath } from 'react-router-dom'
import UserRoutes from 'views/pages/User/routes'
import { useTranslation } from 'react-i18next'
import { User } from 'modules/domain/user/types'

type Props = {
  user: User
  setBidModal: (arg: boolean) => void
  setEditUserModal: (arg: boolean) => void
  setTaskModal: (arg: boolean) => void
}

export const HeaderControls = ({ setEditUserModal, setTaskModal, setBidModal, user }: Props) => {
  const { t } = useTranslation('user')

  return (
    <WrapperButton>
      <AButton
        id="add"
        Icon={Icons.IconAdd}
        intent="approve"
        size="medium"
        onClick={() => setBidModal(true)}
        disabled={!user.is_active}
      >
        {t('bid:form.add')}
      </AButton>
      <Separator />
      <AButton
        id="priceUpdate"
        intent="secondary"
        size="medium"
        to={generatePath(UserRoutes.BidPrice, { id: user.id })}
      >
        {t('priceUpdating')}
      </AButton>
      <AButton id="edit" intent="secondary" size="medium" onClick={() => setEditUserModal(true)}>
        {t('common:edit')}
      </AButton>
      <AButton id="setTask" intent="secondary" size="medium" onClick={() => setTaskModal(true)}>
        {t('common:setTask')}
      </AButton>
    </WrapperButton>
  )
}
