import { Icons, Progress, Text, defaultTheme } from '@agro-club/agroclub-shared'
import { refetchFunc } from 'modules/domain/common/hooks'
import { NotificationModal } from './NotificationModal'
import { useNotification } from 'hooks/useNotification'
import { apiClient } from 'modules/utils/httpClient'
import { AButton } from 'views/components/Analytics'
import { useAProgress } from 'hooks/useAProgress'
import { User } from 'modules/domain/user/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { SwitchItem } from './SwitchItem'
import styled from 'styled-components'
import { FC, useState } from 'react'
import { useAnalyticsContext } from 'analytics/hooks'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
`
const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 0;
`

const CropItem = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${defaultTheme.color.secondary50};
  display: grid;
  grid-template-columns: 325px auto;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  min-height: 66px;
`
const CropText = styled(Text)<{ disabled: boolean }>`
  color: ${({ disabled }) => disabled && defaultTheme.color.secondaryPlaceholder3};
`

type Props = {
  user: User
  refetch: refetchFunc
}

export const Notifications: FC<Props> = ({ user, refetch }) => {
  const [isShowModal, setIsShowModal] = useState(false)
  const { track } = useAnalyticsContext()
  const { t } = useTranslation('user')
  const notify = useNotification()

  const crops = user.sms_notification_settings.price_sms_products
  const priceSmsEnabled = user.sms_notification_settings.price_sms_enabled

  const productIds = crops?.map((t) => String(t.id)) || []
  const [progress, updateSwitch] = useAProgress(
    (dto: boolean) =>
      apiClient.put(endpoints.userProductsForNotifications(user.id), {
        price_sms_enabled: dto,
        price_sms_products: productIds,
      }),
    {
      eventName: 'update_sms_notifications',
      onSuccess: () => refetch(),
    },
  )

  const onSwitchChange = (status) => {
    track('click|update_sms_notifications', {
      value: {
        from: priceSmsEnabled,
        to: status,
      },
    })

    if (!user.is_mobile_phone) {
      notify(Progress.ERROR, { title: t('smsNotification.error') })
      return
    }

    if (status && !crops.length) {
      setIsShowModal(true)
      return
    }

    if (crops.length) {
      updateSwitch(status)
    }
  }

  return (
    <Wrapper>
      <Block>
        <Text typography="bodyMedium" color="secondary">
          {t('smsNotification.sms')}
        </Text>
        <SwitchItem
          isStopText={user.sms_notification_settings.price_sms_disabled_by_user}
          keyText={t('smsNotification.subscribeText')}
          loading={progress === Progress.WORK}
          disabledText={!priceSmsEnabled}
          onChange={onSwitchChange}
          value={priceSmsEnabled}
          valueText={user.phone}
        />
        {!!crops.length && (
          <CropItem>
            <CropText disabled={!priceSmsEnabled}>{crops.map((item) => item.title).join(', ')}</CropText>
            {priceSmsEnabled && (
              <AButton
                id="edit"
                Icon={Icons.IconEdit}
                intent="white"
                onClick={() => setIsShowModal(true)}
                analyticsPayload={{ place: 'ProductTagsModal' }}
              />
            )}
          </CropItem>
        )}
      </Block>
      {isShowModal && <NotificationModal onClose={() => setIsShowModal(false)} refetch={refetch} user={user} />}
    </Wrapper>
  )
}
