import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Icons, Progress } from '@agro-club/agroclub-shared'
import { Deal } from 'modules/domain/deal/types'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { Trans, useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { ContentBlock } from 'views/pages/LogisticsKR/components/styled'
import DealsKRRoutes from 'views/pages/LogisticsKR/LogisticRequests/routes'
import { LineSeparator } from 'views/ui/Header/Header'
import {
  TableSectionAccentText,
  TableSectionContainer,
  TableSectionHeader,
  TableSectionHeaderRightBlock,
  TableSectionText,
  TableSectionTitle,
  TextWrapper,
} from '../styled'
import { RegularTripsTable } from './RegularTripsTable'
import { AButton } from 'views/components/Analytics'
import { refetchFunc } from 'modules/domain/common/hooks.ts'

interface Props {
  request: LogisticRequest
  refetchApprovingTable: () => void
  refetchTripsTable: () => void
  ttnListRefetch: refetchFunc
  listRequestParams: ListRequestParams
  setListRequestParams: Dispatch<SetStateAction<ListRequestParams>>
}

export const RegularTripsTableSection: FC<Props> = ({
  request,
  refetchApprovingTable,
  ttnListRefetch,
  setListRequestParams,
  listRequestParams,
  refetchTripsTable,
}) => {
  const { t } = useTranslation('logisticsKR')

  const [progress, setProgress] = useState<Progress>(Progress.IDLE)
  const [deal, setDeal] = useState<Deal>()

  useEffect(() => {
    const fetchDeal = async () => {
      if (!request?.deal_id) return

      setProgress(Progress.WORK)
      try {
        const data = await apiClient.get<Deal>(endpoints.deal(request?.deal_id))
        setDeal(data)
        setProgress(Progress.SUCCESS)
      } catch (err) {
        setProgress(Progress.ERROR)
      }
    }

    fetchDeal()
  }, [request?.deal_id])

  return (
    <ContentBlock>
      <TableSectionContainer>
        <TableSectionHeader>
          <TableSectionTitle>{t('details.tableSections.regularHeading')}</TableSectionTitle>

          <TableSectionHeaderRightBlock>
            {progress === Progress.SUCCESS && deal && (
              <>
                <TextWrapper>
                  <Trans
                    i18nKey="logisticsKR:details.tableSections.trips_loaded_quantity"
                    values={{
                      trips_loaded_quantity: formatNumber(deal?.loaded_quantity, true),
                    }}
                    components={[<TableSectionText key={0} />, <TableSectionAccentText key={1} />]}
                  />
                </TextWrapper>
                <TextWrapper>
                  <Trans
                    i18nKey="logisticsKR:details.tableSections.trips_delivered_quantity"
                    values={{
                      trips_delivered_quantity: formatNumber(deal?.delivered_quantity, true),
                    }}
                    components={[<TableSectionText key={0} />, <TableSectionAccentText key={1} />]}
                  />
                </TextWrapper>

                <LineSeparator />
              </>
            )}

            <AButton
              id="tripCars"
              intent="approve"
              Icon={Icons.IconPlus}
              to={generatePath(DealsKRRoutes.TripCars, { id: request.id })}
            />
          </TableSectionHeaderRightBlock>
        </TableSectionHeader>

        <RegularTripsTable
          ttnListRefetch={ttnListRefetch}
          request={request}
          listRequestParams={listRequestParams}
          setListRequestParams={setListRequestParams}
          refetchApprovingTable={refetchApprovingTable}
          refetchTripsTable={refetchTripsTable}
        />
      </TableSectionContainer>
    </ContentBlock>
  )
}
