import { SharedModalContent, SharedModalInputs } from 'views/components/SharedModal/styled'
import { SeparatorWithTitle } from 'views/components/SeparatorWithTitle/SeparatorWithTitle'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { FormTeamAndAssignee } from 'views/components/form/FormTeamAndAssignee'
import { Checkbox, FormGroup, Progress } from '@agro-club/agroclub-shared'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useEntityTaskStat } from './useEntityTaskStat'
import { FormItemWrapper } from 'views/styled/common'
import { apiClient } from 'modules/utils/httpClient'
import { BoardItem } from './components/BoardItem'
import { useAFormHandler } from 'analytics/hooks'
import { InfoBoard, StatBoard } from './styled'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { Preload } from './DistributeBlock'
import { useFormik } from 'formik'

export type ResponseCoordinatorsTaskStats = {
  self_created_tasks: string
  total_tasks: string
}

type Fields = {
  exclude_self_created: boolean
  assignees: string[]
  team: string | null
}
type Props = {
  onClose: () => void
  children: ReactNode
  refetch: () => void
}

export const TakeOffBlock: FC<Props> = ({ onClose, children, refetch }) => {
  const { t } = useTranslation('my_tasks')
  const [coordinatorsStat, setCoordinatorsStat] = useState<ResponseCoordinatorsTaskStats>()

  const { formProgress, formHandler } = useAFormHandler('distributeTasks')

  const [coordinatorsStatProgress, getCoordinatorsStat] = useEntityTaskStat<ResponseCoordinatorsTaskStats>(
    setCoordinatorsStat,
    endpoints.getCoordinatorTaskStats,
  )

  const formik = useFormik<Fields>({
    initialValues: {
      exclude_self_created: false,
      assignees: [],
      team: null,
    },

    onSubmit: formHandler(
      async () => {
        const data = {
          exclude_self_created: formik.values.exclude_self_created,
          assignees: formik.values.assignees,
        }
        await apiClient.post(endpoints.deassignTask(), data)
      },
      {
        onSuccess: () => {
          refetch()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  useEffect(() => {
    getCoordinatorsStat({
      assignee: formik.values.assignees,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.assignees])

  return (
    <>
      <SharedModalContent>
        <SharedModalInputs>
          {children}
          <SeparatorWithTitle title={t('task_distribution.remove_tasks')} />
          <FormTeamAndAssignee formik={formik} teamField="team" assigneeField="assignees" />

          <FormGroup label={t('task_distribution.exclude_from_result')}>
            <FormItemWrapper>
              <Checkbox
                onChange={(_value, isChecked) => formik.setFieldValue('exclude_self_created', isChecked)}
                isChecked={formik.values.exclude_self_created}
                label={t('task_distribution.appointed_by_executor')}
              />
            </FormItemWrapper>
          </FormGroup>
          {!!formik.values.assignees.length && (
            <InfoBoard>
              {coordinatorsStatProgress === Progress.WORK
                ? Preload
                : coordinatorsStat && (
                    <StatBoard>
                      <BoardItem
                        title={t('task_distribution.appointed_by_executor')}
                        disabled={formik.values.exclude_self_created}
                        value={coordinatorsStat.self_created_tasks}
                      />
                      <BoardItem title={t('task_distribution.total_tasks')} value={coordinatorsStat.total_tasks} />
                    </StatBoard>
                  )}
            </InfoBoard>
          )}
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:save')}
        progress={formProgress}
        onClose={onClose}
      />
    </>
  )
}
