import { FormGroup, Input } from '@agro-club/agroclub-shared'
import useFormatDate from 'hooks/useFormatDate'

import { FC } from 'react'
import { PaymentRadioButtons } from './PaymentRadioButtons/PaymentRadioButtons'
import { InputsRow, ShortField } from './styled'
import { FormikFieldsType } from 'modules/domain/types'

interface Props {
  formik: FormikFieldsType
  fieldName: string
}

export const InputRowItem: FC<Props> = ({ formik, fieldName }) => {
  const formatDate = useFormatDate()
  return (
    <FormGroup label={formatDate(fieldName)} error={formik.errors['payments']} disableRightPadding>
      <InputsRow>
        <ShortField>
          <Input
            type="number"
            {...formik.getFieldProps(`payments.${fieldName}.amount`)}
            invalid={!!formik.errors['payments']}
          />
        </ShortField>

        <PaymentRadioButtons
          value={formik.values['payments']?.[fieldName]?.['status']}
          onChange={(value) => formik.setFieldValue(`payments.${fieldName}.status`, value)}
        />
      </InputsRow>
    </FormGroup>
  )
}
