import { ACCORDION_ITEM_PAGE_SIZE } from 'modules/constants'
import { AntdPaginationSmall } from '@agro-club/agroclub-shared'
import { FC } from 'react'

type Props = {
  total: number
  currPage: number
  setCurrPage: (number) => void
  pageSize?: number
}

export const ClientPaginationSmall: FC<Props> = ({
  currPage,
  setCurrPage,
  pageSize = ACCORDION_ITEM_PAGE_SIZE,
  total,
}) => {
  return (
    <>
      {total > pageSize && (
        <AntdPaginationSmall total={total} current={currPage} onChange={setCurrPage} pageSize={pageSize} />
      )}
    </>
  )
}
