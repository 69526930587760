import { Deal, DealDTO, DealType } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import env, { isBrazil, isRussia } from 'env'
import { useFormik } from 'formik'
import { SharedModalContent, SharedModalInputs } from 'views/components/SharedModal'
import { RequestError } from 'modules/errors'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { ShortFormControl } from 'views/styled/common'
import FormSelectShared from 'views/components/FormSelectShared'
import { QuantityInput } from 'views/components/QuantityInput/QuantityInput'
import { useAFormHandler } from 'analytics/hooks'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import { labelDistance } from 'modules/utils/numbers/formatDistance'
import { labelPriceLogistics } from 'modules/utils/numbers/formatPriceLogistics'
import { FC } from 'react'
import { PriceCarrierInput } from './PriceCarrierInput'
import { FormInput } from 'views/components/form/FormInput'
import { refetchFunc } from 'modules/domain/common/hooks'
import styled from 'styled-components'
import { PricesControl } from './PricesControl'
import { BrokerType } from 'modules/domain/user/types'
import { changeErrorsDotsToPipes } from 'modules/utils/helpers'
import { DealEditableFields } from './types'
import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { defaultTheme } from '@agro-club/agroclub-shared'

type Props = {
  deal: Deal
  refetch: refetchFunc
  onClose: () => void
}

const ShortLabelWrapper = styled.div`
  max-width: 147px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    max-width: none;
  }
`

const fillBrokerFields = (values: DealEditableFields) => {
  const {
    'seller_broker|broker': seller_broker_broker,
    'seller_broker|commission': seller_broker_commission,
    'customer_broker|broker': customer_broker_broker,
    'customer_broker|commission': customer_broker_commission,
    ...rest
  } = values
  let seller_broker: Record<string, unknown> | null = null
  let customer_broker: Record<string, unknown> | null = null

  if (seller_broker_broker && (isRussia || rest.deal_type === DealType.broker)) {
    seller_broker = {
      broker: seller_broker_broker,
      commission: seller_broker_commission,
      broker_type: BrokerType.seller,
    }
  }
  if (customer_broker_broker && rest.deal_type === DealType.broker) {
    customer_broker = {
      broker: customer_broker_broker,
      commission: customer_broker_commission,
      broker_type: BrokerType.customer,
    }
  }

  return { seller_broker, customer_broker, rest }
}
export const DealDetailEditModal: FC<Props> = ({ deal, onClose, refetch }) => {
  const { formProgress, formHandler } = useAFormHandler('editDeal', { id: deal.id })
  const { t } = useTranslation(['deal', 'executors', 'bid'])
  const tariffIsDisable = isBrazil && deal.delivery_side !== 'agroclub'

  const currency = env.REACT_APP_CURRENCY_SYMBOL

  const formik = useFormik<DealEditableFields>({
    initialValues: {
      deal_type: deal.deal_type,
      quantity: deal.quantity,
      distance: deal.distance,
      sale_price: deal.sale_price,
      purchase_price: deal.purchase_price,
      price_logistics: deal.price_logistics,
      price_carrier: deal.price_carrier,
      fixed_margin: deal.fixed_margin ?? 0,
      //TODO: remove for Russia and US when the backend is fixed
      paid_sum: deal.paid_sum,
      payment_delay_days: deal.payment_delay_days ?? null,
      'seller_broker|broker': deal.seller_broker ? deal.seller_broker.broker.id : null,
      'seller_broker|commission': deal.seller_broker ? deal.seller_broker.commission : null,
      'customer_broker|broker': deal.customer_broker ? deal.customer_broker.broker.id : null,
      'customer_broker|commission': deal.customer_broker ? deal.customer_broker.commission : null,
    },
    enableReinitialize: true,
    onSubmit: formHandler(
      async () => {
        const values = fillBrokerFields(formik.values)
        const dto = {
          ...values.rest,
          seller_broker: values.seller_broker,
          customer_broker: values.customer_broker,
          /** @deprecated */
          price_exw: formik.values.sale_price,
          /** @deprecated */
          price_cpt: formik.values.purchase_price,
        }
        await apiClient.put<DealDTO>(endpoints.deal(deal.id), dto)
      },
      {
        onSuccess: async () => {
          await refetch()
          onClose()
        },
        onError: (err) => {
          const error = err as RequestError
          let { errors } = error
          errors = changeErrorsDotsToPipes(errors)
          formik.setErrors(errors)
        },
      },
    ),
  })

  const isDisabledFixedMargin = formik.values.deal_type === DealType.agent || formik.values.deal_type === DealType.trade
  return (
    <ResponsiveModal
      onConfirm={() => formik.submitForm()}
      id={AnalyticsPlaces.EDIT_DETAIL_MODAL}
      confirmButtonText={t('common:save')}
      disabled={!formik.dirty}
      title={t('dealDetail')}
      progress={formProgress}
      onClose={onClose}
      size="medium"
    >
      <SharedModalContent>
        <SharedModalInputs>
          <ShortFormControl>
            <FormSelectShared
              label={t('fields.deal_type')}
              formik={formik}
              fieldName="deal_type"
              getLabel={(dto) => dto.label}
              endpoint={endpoints.dealTypes()}
              placeholder=""
              simpleApiFormat
              showSearch={false}
              allowClear={false}
            />
          </ShortFormControl>
          <PricesControl formik={formik} deal={deal} />
          <ShortFormControl>
            <QuantityInput formik={formik} fieldName="quantity" label={t('common:volume')} />
            <FormInput formik={formik} field="distance" label={labelDistance(t('fields.distance'))} type="number" />
            <FormInput
              formik={formik}
              disabled={tariffIsDisable}
              field="price_logistics"
              label={<ShortLabelWrapper>{labelPriceLogistics(t('fields.freight_rate_max'))}</ShortLabelWrapper>}
              type="number"
            />
            <PriceCarrierInput formik={formik} />
            <FormInput
              formik={formik}
              field="fixed_margin"
              label={`${t('fields.fixed_margin')}, ${t('common:rub', { currency })}`}
              type="number"
              disabled={isDisabledFixedMargin}
            />
            {isBrazil && (
              <FormInput
                formik={formik}
                field="paid_sum"
                label={`${t('fields.paid_sum')}, ${t('common:rub', { currency })}`}
                type="number"
              />
            )}

            {isRussia && (
              <FormInput
                label={`${t('fields.paymentDelayForInput')}`}
                field="payment_delay_days"
                formik={formik}
                type="number"
              />
            )}
          </ShortFormControl>
        </SharedModalInputs>
      </SharedModalContent>
    </ResponsiveModal>
  )
}
