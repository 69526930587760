import { useCallback, useEffect, useState } from 'react'

import { Progress } from '@agro-club/agroclub-shared'

import { getTableData } from 'modules/domain/common/managers'
import { ListRequestParams } from 'modules/domain/types'
import { apiClient } from 'modules/utils/httpClient'
import { RequestError } from 'modules/errors'
import { ColumnData } from 'modules/types'
import { convertSortingToAntDFormat } from 'modules/domain/common/tableUtils'
import CommonRoutes from 'views/pages/commonRoutes'
import { useNavigate } from 'react-router-dom'
import { useNoPermissionNotification } from 'hooks/useNoPermissionNotification'

export type refetchFunc = (silent?: boolean) => Promise<void>

export type TableDataObject<T> = {
  progress: Progress
  data: T[] | undefined
  total: number
  pageSize: number
  refetch: refetchFunc
  next?: number
  previous?: number
}

export const useTableData: <T = any>(
  endpoint: string,
  params: ListRequestParams,
  isFiltersLoaded: boolean,
) => TableDataObject<T> = <T = any>(endpoint: string, params: ListRequestParams, isFiltersLoaded: boolean) => {
  const [progress, setProgress] = useState(Progress.IDLE)
  const [data, setData] = useState<T[]>()
  const [total, setTotal] = useState(0)
  const [pageSize, setPageSize] = useState(0)
  const notify = useNoPermissionNotification()

  const fetchData = async (silent = false) => {
    if (!silent) {
      setProgress(Progress.WORK)
    }
    try {
      const { list, total, pageSize = 0 } = await getTableData(endpoint, params)

      setData(list)
      setTotal(total)
      setPageSize(pageSize)
      setProgress(Progress.SUCCESS)
    } catch (err) {
      const error = err as RequestError
      if (error?.code === 403) {
        notify(endpoint)
      }
      // ignore errors due to non existing page
      // if page doesn't exists, server returns 404. usually it happens when user close tasks and the list size reducing
      if (error?.code === 404 && params.page > 1) {
        setData([])
        setProgress(Progress.SUCCESS)
      }
    }
  }

  useEffect(() => {
    if (isFiltersLoaded) fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, isFiltersLoaded, params])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetchData, [endpoint, params])

  return {
    progress,
    data,
    total,
    pageSize,
    refetch: memoizedFetch,
  }
}

export const formatTableColumns = (columns: ColumnData[], columnsToUpdate?: any) => {
  if (!columnsToUpdate) return columns

  return columns.map((column) => {
    if (columnsToUpdate[column.dataIndex]) {
      return {
        ...column,
        ...columnsToUpdate[column.dataIndex],
      }
    }

    return column
  })
}

export const getTableColumns = async (
  endpoint: string,
  sortParam?: ListRequestParams['sort'],
  columnsToUpdate?: any,
) => {
  const schema = await apiClient.get<any>(endpoint)

  const columns: Array<ColumnData & { align: string }> = []
  Object.keys(schema).forEach((key) => {
    const columnInfo = schema[key]
    columns.push({
      dataIndex: key,
      title: columnInfo.label,
      sorter: columnInfo.sortable,
      sortOrder: convertSortingToAntDFormat(key, String(sortParam)),
      align: columnInfo.align,
      width: columnInfo.width,
    })
  })
  return formatTableColumns(columns, columnsToUpdate)
}

export const useTableColumns = (endpoint: string, sortParam?: ListRequestParams['sort'], columnsToUpdate?: any) => {
  const [columns, setColumns] = useState<ColumnData[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const columns = await getTableColumns(endpoint, sortParam, columnsToUpdate)

      setColumns(columns)
    }
    fetchData()
  }, [endpoint, columnsToUpdate, sortParam])

  return columns
}

export const useSingleEntity = function <T>(
  url: string,
  auto404 = true,
): readonly [Progress, T | undefined, refetchFunc] {
  const [progress, setProgress] = useState(Progress.IDLE)
  const [data, setData] = useState<T>()
  const navigate = useNavigate()
  const notify = useNoPermissionNotification()

  const getEntity = async (silent = false) => {
    try {
      !silent && setProgress(Progress.WORK)

      const data = await apiClient.get<T>(url)

      setData(data)

      !silent && setProgress(Progress.SUCCESS)
    } catch (err) {
      // TS fires an error that err is type of unknown
      // So, workaround is to convert it to the RequestError manually
      const error = err as RequestError

      if (auto404 && error?.code === 404) {
        navigate({ pathname: CommonRoutes.NotFound })
        return
      }

      if (error?.code === 403) {
        notify(url)
      }

      !silent && setProgress(Progress.ERROR)
    }
  }

  useEffect(() => {
    getEntity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(getEntity, [url])

  return [progress, data, memoizedFetch]
}

export const usePagination = <T>(items: T[], itemsPerPage: number): [number, (number) => void, T[]] => {
  const [currentPage, setCurrentPage] = useState(1)
  const lastIndex = currentPage * itemsPerPage
  const firstIndex = lastIndex - itemsPerPage
  const currentItems = items.slice(firstIndex, lastIndex)

  useEffect(() => {
    if (!currentItems.length && currentPage > 1) {
      setCurrentPage((prev) => prev - 1)
    }
  }, [currentItems.length, currentPage, items.length])

  return [currentPage, setCurrentPage, currentItems]
}
