import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import styled from 'styled-components'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { AnalyticsPlaces } from 'views/pages/User/analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import FormSelectShared from 'views/components/FormSelectShared'

import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'

import { useAFormHandler } from 'analytics/hooks'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { ModalProps } from '../../../types'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

export const BlockUserModal: FC<ModalProps> = ({ onClose, refetch, userId }) => {
  const { t } = useTranslation('user')
  const { formProgress, formHandler } = useAFormHandler('blockUser', { id: userId })

  useNotificationProgress(formProgress, t('blocked'))

  const formik = useFormik<{ blocked_reason: string }>({
    initialValues: {
      blocked_reason: '',
    },
    onSubmit: formHandler(
      async () => {
        await apiClient.post(endpoints.blockUser(userId), formik.values)
      },
      {
        onSuccess: async () => {
          await refetch(true)
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <>
      <ASharedModal id={AnalyticsPlaces.BLOCK_MODAL} onClose={onClose} size="small">
        <SharedModalTitle>{t('block_user')}</SharedModalTitle>
        <SharedModalContent>
          <ContentWrapper>
            <div>{t('blockUserDescription')}</div>
            <div>{t('doYouReallyWantBlockUser')}</div>
            <FormSelectShared
              formik={formik}
              value={formik.values.blocked_reason}
              endpoint={endpoints.blockedReasons()}
              placeholder={t('blockUserPlaceholder')}
              fieldName="blocked_reason"
              simpleApiFormat
              showSearch={false}
              getLabel={(dto) => dto.label}
              hideLabel
              disableRightPadding
              allowClear={false}
            />
          </ContentWrapper>
        </SharedModalContent>
        <ADefaultModalFooter
          progress={formProgress}
          onClose={onClose}
          onConfirm={() => formik.submitForm()}
          confirmButtonText={t('common:block')}
          intent="destruct"
          disabled={!formik.dirty}
        />
      </ASharedModal>
    </>
  )
}
