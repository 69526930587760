import LogisticRequestsRoutes from 'views/pages/LogisticsKR/LogisticRequests/routes'
import { InfoLinkBox } from 'views/components/MobileComponents/InfoLinkBox'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import SpecificationsRoutes from 'views/pages/Specifications/routes'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { Card } from 'views/components/Card/Card'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import { DispatchingType } from '../../types'
import { generatePath } from 'react-router'
import { DASH } from 'modules/constants'
import styled from 'styled-components'
import { FC } from 'react'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  margin-bottom: 16px;
  margin-top: 8px;
`
const CarsSpecWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  margin-top: -4px;
`

const TripInfo: FC<{ data: DispatchingType }> = ({ data }) => {
  const { t } = useTranslation('dispatching')

  const renderTripInfo = (key, label) => {
    return data[key] ? (
      <InfoLinkBox to={generatePath(LogisticRequestsRoutes.Details, { id: data.logistics_request_id })}>
        <KeyValue label={label}>{data[key]}</KeyValue>
      </InfoLinkBox>
    ) : (
      <InfoLinkBox border={false}>
        <KeyValue label={label}>{DASH}</KeyValue>
      </InfoLinkBox>
    )
  }

  return (
    <Card.Grid cols={3}>
      {renderTripInfo('trips_today', t('today'))}
      {renderTripInfo('trips_expected', t('trips_expected'))}
      {renderTripInfo('trips_tomorrow', t('trips_tomorrow'))}
    </Card.Grid>
  )
}

export const InfoBoard: FC<{ data: DispatchingType }> = ({ data }) => {
  const { t } = useTranslation('dispatching')
  const formatDate = useFormatDate()
  return (
    <Wrapper>
      <Card.Grid cols={3}>
        <KeyValue label={t('paid')}>{formatQuantity(data.quantity_paid_for)}</KeyValue>
        <KeyValue label={t('loaded_quantity')}>{formatQuantity(data.loaded_quantity)}</KeyValue>
        <KeyValue label={t('delivered_quantity')}>{formatQuantity(data.delivered_quantity)}</KeyValue>
        <KeyValue label={t('delivered_quantity')}>{formatQuantity(data.remaining_quantity)}</KeyValue>
        <KeyValue label={t('table.planned_remaining_quantity_without_ton')}>
          {formatQuantity(data.planned_remaining_quantity)}
        </KeyValue>
        <KeyValue label={t('table.average_quantity_percent_per_day')}>
          {`${data.average_quantity_percent_per_day} %`}
        </KeyValue>
      </Card.Grid>
      <KeyValue label={t('cars')}>
        <CarsSpecWrapper>
          <TripInfo data={data} />
          <Card.Grid cols={2}>
            {data.purchase_specification_id && !data.without_purchase_specification ? (
              <InfoLinkBox to={generatePath(SpecificationsRoutes.Details, { id: data.purchase_specification_id })}>
                <KeyValue label={t('table.specification_deadline')}>{formatDate(data.specification_deadline)}</KeyValue>
              </InfoLinkBox>
            ) : (
              <InfoLinkBox border={false}>
                <KeyValue label={t('table.specification_deadline')}>
                  {data.without_purchase_specification ? t('without_specification') : DASH}
                </KeyValue>
              </InfoLinkBox>
            )}

            <InfoLinkBox border={false}>
              <KeyValue label={t('documentsData')}>
                {data.without_documents_request
                  ? t('without_doc')
                  : formatDate(data.documents_request_expected_complete_date) || DASH}
              </KeyValue>
            </InfoLinkBox>
          </Card.Grid>
        </CarsSpecWrapper>
      </KeyValue>
    </Wrapper>
  )
}
