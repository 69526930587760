import { WrapperFormControl, DescriptionWrapper, WrapperOption, DescriptionOption } from '../styled'
import { FormSelectProps, OptionType } from '@agro-club/agroclub-shared'
import FormSelectShared from '../../FormSelectShared'
import { KeyValue } from '../../KeyValue/KeyValue'

import { useTranslation } from 'react-i18next'
import { Address } from 'modules/domain/types'
import { FC, useState } from 'react'

export const AddressBrazil: FC<FormSelectProps> = ({ formik, ...props }) => {
  const { t } = useTranslation()

  const [currentValue, setCurrentValue] = useState<Address>()

  const Option: FC<{ option: OptionType }> = ({ option }) => {
    const data = option.dto
    return (
      <WrapperOption>
        <div>{data.address}</div>
        <DescriptionOption>
          {`${t('company:type_company')}: ${t(`address:legal_types.${data.legal_type}`)}`}
        </DescriptionOption>
      </WrapperOption>
    )
  }

  return (
    <WrapperFormControl>
      <FormSelectShared
        onSelectedOptionLoaded={(e) => setCurrentValue(e.dto)}
        placeholder={t('bid:form.userAddressPlaceholder')}
        getLabel={(dto) => dto.address}
        OptionComponent={Option}
        showSearch={false}
        simpleApiFormat
        formik={formik}
        {...props}
      />

      {formik.values.address && currentValue && (
        <DescriptionWrapper>
          <KeyValue label={t('address:selectDescription')}>
            {t(`address:legal_types.${currentValue.legal_type}`)}
            {currentValue.title && `, ${currentValue.title}`}
          </KeyValue>
        </DescriptionWrapper>
      )}
    </WrapperFormControl>
  )
}
