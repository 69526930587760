import VerifyUserStatus from 'views/ui/VerifyUserStatus/VerifyUserStatus'
import { UserRightBlock } from './UserRightPanel/UserRightBlock'
import { useParams } from 'react-router-dom'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { TaskModal } from 'views/components/TaskModal/TaskModal'
import { ContentWrapper } from 'views/components/Board/styled'
import { ProfileType, User } from 'modules/domain/user/types'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { DealReason } from './Board/MatchesColumn/MatchCard'
import { DealStatus } from 'modules/domain/deal/types'
import { useState } from 'react'
import { useHelmet } from '@agro-club/agroclub-shared'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { EditUser } from '../ModalUser/EditUser'
import UserRoutes from 'views/pages/User/routes'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { Board } from './Board/Board'
import { BidCreateModal } from 'views/pages/Bid/BidModals/BidCreateModal'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../analyticsPlaces'
import { HeaderControls } from './HeaderControls'
import { HeaderSubTitleValue } from './HeaderSubTitleValue'
import { useUserTasksManager } from './UserRightPanel/useUserTasksManager'

export function UserDetail() {
  const { id } = useParams()
  const { t } = useTranslation('user')
  const [, user, userRefetch] = useSingleEntity<User>(endpoints.getUserById(id))
  const taskDataManager = useUserTasksManager(id)

  const [, reasons] = useSingleEntity<DealReason[]>(endpoints.dealsCloseReasons())
  const [, statuses] = useSingleEntity<DealStatus[]>(endpoints.dealStatuses())
  const [editUserModal, setEditUserModal] = useState<boolean>(false)
  useHelmet({ title: user?.profile?.full_name || user?.phone })
  const [taskModal, setTaskModal] = useState<boolean>(false)
  const [bidModal, setBidModal] = useState<boolean>(false)

  if (!user) {
    return <SpinnerLayout />
  }

  const showUserStatus =
    user.profile?.profile_type === ProfileType.seller || user.profile?.profile_type === ProfileType.customer

  const onSuccessTaskModal = async () => {
    if (user.profile?.do_not_call_reason) {
      await userRefetch()
    }
    await taskDataManager.refetch()
  }

  return (
    <APageWrapper page={AnalyticsPages.USER_DETAIL} place={AnalyticsPlaces.DETAIL} id={id}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                { path: UserRoutes.List, breadcrumbName: t('list.heading') },
                { breadcrumbName: user.profile?.full_name || '' },
              ]}
            />
            <HeaderControls
              setBidModal={setBidModal}
              setEditUserModal={setEditUserModal}
              setTaskModal={setTaskModal}
              user={user}
            />
          </Layout.TopHeader>
        </Layout.Header>

        <Header.Title noMargin compact title={user.profile?.full_name || ''}>
          {showUserStatus && <VerifyUserStatus user={user} />}
        </Header.Title>
        <Header.SubTitle>
          <HeaderSubTitleValue user={user} />
        </Header.SubTitle>
        {!reasons || !statuses ? (
          <SpinnerLayout />
        ) : (
          <ContentWrapper>
            <Board user={user} reasons={reasons} statuses={statuses} />
            <UserRightBlock
              taskManager={taskDataManager}
              userRefetch={userRefetch}
              user={user}
              openTaskModal={() => setTaskModal(true)}
            />
          </ContentWrapper>
        )}

        {editUserModal && (
          <EditUser user={user} onClose={(status) => setEditUserModal(status)} userRefetch={userRefetch} />
        )}
        {taskModal && (
          <TaskModal
            unclickableUserLink
            onSuccess={onSuccessTaskModal}
            user={user}
            close={() => setTaskModal(false)}
            skipOnSuccessBeforeRedirect
          />
        )}
        {bidModal && <BidCreateModal user={user} onClose={() => setBidModal(false)} />}
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
