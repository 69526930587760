import { FC, ReactNode, useEffect, useState } from 'react'
import { defaultTheme } from '@agro-club/agroclub-shared'
import { mobileMenuHeight } from 'views/styled/common'
import styled from 'styled-components'

const mobileContentPadding = '32px'

const Board = styled.div<{ boundingTop: number }>`
  width: 100%;
  overflow-x: auto;
  height: ${({ boundingTop }) => `calc(100dvh - ${boundingTop}px)`};
  display: flex;
  gap: 0 24px;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    height: ${({ boundingTop }) => `calc(100dvh - ${boundingTop}px - ${mobileMenuHeight})`};
  }
`

export const BoardColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
  max-height: 100%;
  min-height: max-content;
  border-radius: 8px;
  position: relative;

  @media (max-width: ${defaultTheme.queries.mobile}) {
    width: calc(100vw - ${mobileContentPadding});
    flex-shrink: 0;
    scroll-snap-align: start;
  }
`

export const BoardHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0 16px 16px;
  background: ${defaultTheme.color.backgroundSecondary};
  border-radius: 8px 8px 0 0;
  width: 100%;

  span {
    display: flex;
    align-items: center;
  }
`

export const BoardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
  padding: 16px;
  overflow-y: auto;
  box-shadow: rgba(39, 45, 51, 0.08) 0px 20px 5px -15px inset;
  background: ${defaultTheme.color.backgroundSecondary};
  border-radius: 0 0 8px 8px;
  width: 100%;
  scrollbar-width: none;
  &:hover {
    scrollbar-width: auto;
  }
  @media (max-width: ${defaultTheme.queries.mobile}) {
    scrollbar-width: none !important;
  }
`

export const CardsBoard: FC<{ children: ReactNode }> = ({ children }) => {
  const [boundingTop, setBoundingTop] = useState(0)

  const updateBoundingTop = () => {
    const element = document.getElementById('columnBoard')
    const boundingRectTop = element?.getBoundingClientRect().top || 0
    setBoundingTop(boundingRectTop)
  }

  useEffect(() => {
    updateBoundingTop()
    window.addEventListener('resize', updateBoundingTop)
    return () => {
      window.removeEventListener('resize', updateBoundingTop)
    }
  }, [])

  return (
    <Board id="columnBoard" boundingTop={boundingTop}>
      {children}
    </Board>
  )
}
