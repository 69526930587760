import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentInfoItems } from 'views/pages/Dispatching/helpers'
import {
  Callout,
  PaymentStatusInfoContainer,
  PaymentStatusInfoDescription,
  PaymentStatusInfoItem,
  PaymentStatusInfoMarker,
} from '../styled'

export const PaymentStatusesInfo: FC = () => {
  const { t } = useTranslation('dispatching')

  return (
    <Callout>
      <PaymentStatusInfoDescription>{t('modal.paymentDescription')}</PaymentStatusInfoDescription>

      <PaymentStatusInfoContainer>
        {PaymentInfoItems.map((statusItem) => (
          <PaymentStatusInfoItem key={statusItem.value}>
            <PaymentStatusInfoMarker $color={statusItem.color} $borderColor={statusItem?.borderColor} />
            {t(`paymentStatuses.${statusItem.value}`)}
          </PaymentStatusInfoItem>
        ))}
      </PaymentStatusInfoContainer>
    </Callout>
  )
}
