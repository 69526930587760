import { useMemo } from 'react'
import { ColumnData } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { REACT_APP_CURRENCY_SYMBOL } from 'env'
import { DriverCell } from './DriverCell'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { CarToTripCell } from './CarToTripCell'
import { CellRenders } from 'views/components/Table/renderers'
import { LoadUploadCell } from './LoadUploadCell.tsx'
import { DASH } from 'modules/constants'
import { refetchFunc } from 'modules/domain/common/hooks.ts'

export const useRegularColumns = (
  request: LogisticRequest,
  refetchApprovingTable: () => void,
  refetchTripsTable: () => void,
  ttnListRefetch: refetchFunc,
): ColumnData[] => {
  const { t } = useTranslation('logisticsKR')
  const currency = REACT_APP_CURRENCY_SYMBOL

  const onCell = () => ({
    style: {
      verticalAlign: 'top',
    },
  })

  return useMemo(
    () => [
      {
        title: t('details.tableHeaders.load_date'),
        width: 160,
        dataIndex: 'load_date',
        onCell,
        render: (load_date, trip) => (
          <LoadUploadCell
            tripsRefetch={refetchTripsTable}
            ttnRefetch={ttnListRefetch}
            trip={trip}
            date={load_date}
            loadType="load"
          />
        ),
      },
      {
        title: t('details.tableHeaders.unload_date'),
        width: 160,
        dataIndex: 'unload_date',
        onCell,
        render: (unload_date, trip) => (
          <LoadUploadCell
            tripsRefetch={refetchTripsTable}
            trip={trip}
            ttnRefetch={ttnListRefetch}
            date={unload_date}
            loadType="unload"
          />
        ),
      },
      {
        title: t('details.tableHeaders.ttn'),
        width: 80,
        onCell,
        dataIndex: 'ttn_number',
      },
      {
        title: t('details.tableHeaders.driver'),
        dataIndex: 'driver',
        onCell,
        render: (driver, data) => <DriverCell driver={driver} logistics_request={data} />,
      },
      {
        title: t('details.tableHeaders.logistician'),
        width: 100,
        onCell,
        dataIndex: 'logistician',
        render: (logistician) => (logistician ? logistician.short_name || logistician.full_name : DASH),
      },
      {
        title: t('details.tableHeaders.transit'),
        width: 76,
        dataIndex: 'transit',
        onCell,
        render: CellRenders.YesNo,
      },
      {
        title: t('details.tableHeaders.price_logistics', { currency }),
        width: 100,
        dataIndex: 'price_logistics',
        align: 'right',
        onCell,
        render: (price_logistics) => formatNumber(price_logistics, true),
      },
      {
        title: '',
        width: 48,
        dataIndex: 'car',
        onCell,
        render: (car, trip) => (
          <CarToTripCell trip={trip} request={request} refetchTripsReconciliation={refetchApprovingTable} />
        ),
      },
    ],
    [t, currency, ttnListRefetch, request, refetchApprovingTable],
  )
}
