import { useState } from 'react'

import { User } from 'modules/domain/user/types'
import { BidKind, USContractType } from 'modules/domain/bid/types'
import { BidAddForm } from './Bid/BidAddForm'
import { FormGroup, Radio, RadioItem, Text, defaultTheme } from '@agro-club/agroclub-shared'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../analyticsPlaces'
import { isFuturesEnabled, isUS } from 'env'
import { SharedModalTitle } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'
import { FormItemWrapper } from 'views/styled/common'
import { PotentialBidAddForm } from './PotentialBid/PotentialBidAddForm'
import { USAddBidModal } from 'views/pages/Bid/BidModals/Bid/USModals/USAddBidModal'
import { useMediaQuery } from 'react-responsive'
import { AMobileModal } from 'views/components/MobileComponents/Modal/AMobileModal'

export const InitialMainParameter = {
  parameter: undefined,
  parameter_value_from: undefined,
  parameter_value_to: undefined,
}

type Props = {
  user?: User
  onClose: () => void
  onSuccess?: (value) => void
}

export const availableBidKinds = isUS
  ? [BidKind.basis, BidKind.cash_priced, BidKind.potential]
  : ([isFuturesEnabled && BidKind.futures, BidKind.regular, BidKind.potential].filter(Boolean) as BidKind[])

export const BidCreateModal = ({ user, onClose, onSuccess }: Props) => {
  const { t } = useTranslation('bid')
  const [bidKind, setBidKind] = useState<BidKind>(availableBidKinds[0])

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  const bidKindJSX = (
    <FormGroup label={isUS ? t('form.contractType') : t('form.bidType')}>
      <FormItemWrapper>
        <Radio
          value={bidKind}
          onChange={(value) => {
            setBidKind(value as BidKind)
          }}
        >
          {availableBidKinds.map((v) => (
            <RadioItem key={v} value={v} label={t(`kind.${v}`)} />
          ))}
        </Radio>
      </FormItemWrapper>
    </FormGroup>
  )

  let form
  if (bidKind == BidKind.potential) {
    form = (
      <PotentialBidAddForm user={user} onClose={onClose}>
        {bidKindJSX}
      </PotentialBidAddForm>
    )
  } else if (bidKind in USContractType) {
    form = (
      <USAddBidModal
        user={user}
        onClose={onClose}
        onSuccess={onSuccess}
        contractType={bidKind as unknown as USContractType}
      >
        {bidKindJSX}
      </USAddBidModal>
    )
  } else {
    form = (
      <BidAddForm user={user} onClose={onClose} bidKind={bidKind as BidKind} onSuccess={onSuccess}>
        {bidKindJSX}
      </BidAddForm>
    )
  }

  return (
    <>
      {isMobile ? (
        <AMobileModal
          header={<Text typography="titleH4">{t('form.add')}</Text>}
          id={AnalyticsPlaces.CREATE_MODAL}
          onClose={onClose}
        >
          {form}
        </AMobileModal>
      ) : (
        <ASharedModal onClose={onClose} size="medium" id={AnalyticsPlaces.CREATE_MODAL}>
          <SharedModalTitle>{t('form.add')}</SharedModalTitle>
          {form}
        </ASharedModal>
      )}
    </>
  )
}
