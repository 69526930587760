import { UserAddress } from '../userAddress/types'
import { Bid, BidSources, BidStatus, BidTypes } from './types'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { ListResponse } from '@agro-club/agroclub-shared/dist/cjs/types/networkTypes'

const SOY_SLUG = 'soybeans-2-yellow'
const CORN_SLUG = 'corn-2-yellow'
const WHEAT_SLUG = 'wheat-hard-red-winter-kansas-city'

export type UserOffer = { best: Array<Bid | undefined>; closest: Array<Bid | undefined> }

const getUsTopOffers = async (
  address: UserAddress,
  rangeDate: any,
  abortController: AbortController | null,
): Promise<UserOffer> => {
  const commonParams = {
    status: BidStatus.published,
    bid_type: BidTypes.purchase,
    latitude: String(address?.latitude) || '',
    longitude: String(address?.longitude) || '',
    ...rangeDate,
  }

  const signal = abortController ? abortController.signal : undefined

  const offers = [
    // we get full page to allow backend to adjust sorting, but we need only 1 top result
    apiClient.get<ListResponse<Bid>>(
      endpoints.bid(),
      { ...commonParams, sort: 'distance', source: BidSources.BARCHART, product: SOY_SLUG },
      { signal },
    ),
    apiClient.get<ListResponse<Bid>>(
      endpoints.bid(),
      {
        ...commonParams,
        sort: 'distance',
        source: BidSources.BARCHART,
        product: CORN_SLUG,
      },
      { signal },
    ),
    apiClient.get<ListResponse<Bid>>(
      endpoints.bid(),
      {
        ...commonParams,
        sort: 'distance',
        source: BidSources.BARCHART,
        product: WHEAT_SLUG,
      },
      { signal },
    ),
    apiClient.get<ListResponse<Bid>>(
      endpoints.bid(),
      {
        ...commonParams,
        sort: '-price_exw',
        product: SOY_SLUG,
      },
      { signal },
    ),
    apiClient.get<ListResponse<Bid>>(
      endpoints.bid(),
      {
        ...commonParams,
        sort: '-price_exw',
        product: CORN_SLUG,
      },
      { signal },
    ),
    apiClient.get<ListResponse<Bid>>(
      endpoints.bid(),
      {
        ...commonParams,
        sort: '-price_exw',
        product: WHEAT_SLUG,
      },
      { signal },
    ),
  ]
  const [soyClosest, cornClosest, wheatClosest, soyBest, cornBest, wheatBest] = await Promise.all(offers)
  return {
    best: [cornBest.results[0], soyBest.results[0], wheatBest.results[0]].filter(Boolean),
    closest: [cornClosest.results[0], soyClosest.results[0], wheatClosest.results[0]].filter(Boolean),
  }
}

export default getUsTopOffers
