import { labelPriceLogistics } from 'modules/utils/numbers/formatPriceLogistics'
import { FormGroup, Progress, Text } from '@agro-club/agroclub-shared'
import { labelQuantity } from 'modules/utils/numbers/formatQuantity'
import { DatePicker } from 'views/components/DatePicker/DatePicker'
import FormSelectShared from 'views/components/FormSelectShared'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { CalculatorActions, CalculatorInput, SelectWrapper } from './styled'
import { AButton } from 'views/components/Analytics'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { CalculatorRow } from './Row'
import { FC } from 'react'
import { FormikFieldsType } from 'modules/domain/types'

type Props = {
  formik: FormikFieldsType
  formProgress: Progress
}

export const CalculatorLeftBlock: FC<Props> = ({ formik, formProgress }) => {
  const { t } = useTranslation('calculator')

  return (
    <>
      <div>
        <CalculatorRow
          title={
            <Text typography="bodyMedium" color="secondary">
              {t('title')}
            </Text>
          }
        >
          <Text typography="bodyMedium">{t('autoFreight')}</Text>
          <Text typography="bodyMedium">{t('simulation')}</Text>
        </CalculatorRow>
        <CalculatorRow title={<Text typography="accentMedium">{t('cultura')}</Text>}>
          <SelectWrapper>
            <FormSelectShared
              endpoint={endpoints.productChoices()}
              placeholder={t('common:product')}
              getLabel={(dto) => dto.title}
              value={String(formik.values.auto.product_id)}
              fieldName="auto.product_id"
              showSearch={false}
              disableRightPadding
              allowClear={false}
              formik={formik}
              simpleApiFormat
              size="small"
              hideLabel
            />
          </SelectWrapper>
        </CalculatorRow>
        <CalculatorRow title={<Text typography="accentMedium">{labelQuantity(t('volume'))}</Text>}>
          <CalculatorInput formik={formik} field="auto.quantity" />
        </CalculatorRow>
        <CalculatorRow title={<Text typography="accentMedium">{labelTarif(t('purchasePrice'))}</Text>}>
          <CalculatorInput formik={formik} field="auto.purchase_price" />
          <CalculatorInput formik={formik} field="simulation.purchase_price" />
        </CalculatorRow>
        <CalculatorRow title={<Text typography="accentMedium">{t('purchasePaymentDate')}</Text>}>
          <FormGroup error={formik.errors['auto.purchase_payment_date']}>
            <DatePicker
              onChange={(data) => formik.setFieldValue('auto.purchase_payment_date', data['date'])}
              value={formik.values.auto.purchase_payment_date}
              allowClear
              error={!!formik.errors['auto.purchase_payment_date']}
            />
          </FormGroup>
        </CalculatorRow>
        <CalculatorRow title={<Text typography="accentMedium">{labelPriceLogistics(t('freightFullTon'))}</Text>}>
          <CalculatorInput formik={formik} field="auto.freight_cost_per_ton" disabled />
          <CalculatorInput formik={formik} field="simulation.freight_cost_per_ton" />
        </CalculatorRow>
        <CalculatorRow title={<Text typography="bodyMedium">{labelTarif(t('freightFullBag'))}</Text>}>
          <CalculatorInput formik={formik} field="auto.freight_cost_per_bag" disabled />
          <CalculatorInput formik={formik} field="simulation.freight_cost_per_bag" disabled />
        </CalculatorRow>
        <CalculatorRow title={<Text typography="bodyMedium">{labelPriceLogistics(t('pcOnFreight'))}</Text>}>
          <CalculatorInput formik={formik} field="auto.freight_piscofins_per_ton" disabled />
          <CalculatorInput formik={formik} field="simulation.freight_piscofins_per_ton" disabled />
        </CalculatorRow>
        <CalculatorRow title={<Text typography="accentMedium">{labelPriceLogistics(t('freightNetTon'))}</Text>}>
          <CalculatorInput formik={formik} field="auto.freight_net_cost_per_ton" disabled />
          <CalculatorInput formik={formik} field="simulation.freight_net_cost_per_ton" disabled />
        </CalculatorRow>
        <CalculatorRow title={<Text typography="bodyMedium">{labelTarif(t('freightNetBag'))}</Text>}>
          <CalculatorInput formik={formik} field="auto.freight_net_cost_per_bag" disabled />
          <CalculatorInput formik={formik} field="simulation.freight_net_cost_per_bag" disabled />
        </CalculatorRow>
        <CalculatorRow title={<Text typography="accentMedium">{labelTarif(t('sellingPrice'))}</Text>}>
          <CalculatorInput formik={formik} field="auto.selling_price" />
          <CalculatorInput formik={formik} field="simulation.selling_price" />
        </CalculatorRow>
        <CalculatorRow title={<Text typography="accentMedium">{t('sellingPaymentDate')}</Text>}>
          <FormGroup error={formik.errors['auto.selling_payment_date']}>
            <DatePicker
              onChange={(data) => formik.setFieldValue('auto.selling_payment_date', data['date'])}
              error={!!formik.errors['auto.selling_payment_date']}
              value={formik.values.auto.selling_payment_date}
              allowClear
            />
          </FormGroup>
        </CalculatorRow>
        <CalculatorRow title={<Text typography="bodyMedium">{t('daysCashflow')}</Text>}>
          <CalculatorInput formik={formik} field="auto.days_cashflow" disabled />
          <CalculatorInput formik={formik} field="auto.days_cashflow" disabled />
        </CalculatorRow>
      </div>
      <CalculatorActions>
        <AButton
          onClick={formik.handleSubmit}
          disabled={!formik.dirty}
          progress={formProgress}
          intent="primary"
          size="medium"
          id="countUp"
        >
          {t('common:apply')}
        </AButton>
        <AButton intent="white" size="medium" id="clear" onClick={formik.resetForm}>
          {t('calculator:allClear')}
        </AButton>
      </CalculatorActions>
    </>
  )
}
