import { MobileModalFooter } from 'views/components/MobileComponents/Modal/MobileModalFooter'
import { FormGroup, Input, Radio, RadioItem, Text } from '@agro-club/agroclub-shared'
import { AMobileModal } from 'views/components/MobileComponents/Modal/AMobileModal'
import { SharedModalInputs } from 'views/components/SharedModal'
import { labelPrice } from 'modules/utils/numbers/formatPrice'
import { DispatchingItem, PaymentStatus } from '../../types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { tableCellInfoItems } from '../../helpers'
import { useAFormHandler } from 'analytics/hooks'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { PaymentType } from './Payment'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { FC } from 'react'

type Props = {
  dispatchItem: DispatchingItem
  refetch: refetchFunc
  onClose: () => void
  data: PaymentType
}

const StyledRadio = styled(Radio)`
  display: flex;
  flex-direction: column;
  gap: 8px 0;
`
const WrapperRadioItem = styled.div<{ bgColor: any }>`
  padding: 12px;
  border-radius: 12px;
  background: ${({ bgColor }) => bgColor || 'none'};
`

export const EditPayment: FC<Props> = ({ onClose, data, dispatchItem, refetch }) => {
  const { t } = useTranslation('dispatching')
  const formatDate = useFormatDate()

  const { formProgress, formHandler } = useAFormHandler('addCompanyContract')

  const formik = useFormik<{ amount: number | null; status: PaymentStatus | null }>({
    initialValues: {
      amount: data.amount,
      status: data.status,
    },

    enableReinitialize: true,

    onSubmit: formHandler(
      async () => {
        const updatedPayments = {
          ...dispatchItem?.payments,
          [data.date]: {
            amount: formik.values.amount,
            status: formik.values.status,
          },
        }

        const updatedDepartureItem = {
          ...dispatchItem,
          payments: Object.entries(updatedPayments).map(([payment_date, details]) => ({
            ...details,
            payment_date,
          })),
        }

        await apiClient.put<DispatchingItem>(endpoints.dispatchingItem(dispatchItem.id), updatedDepartureItem)
      },
      {
        onSuccess: async () => {
          await refetch()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <AMobileModal
      header={<Text typography="titleH4">{`${t('payment')} ${formatDate(data.date)}`}</Text>}
      onClose={onClose}
      id="editPayment"
    >
      <SharedModalInputs>
        <StyledRadio onChange={(value) => formik.setFieldValue('status', value)} value={formik.values.status}>
          {tableCellInfoItems.map((item) => {
            return (
              <WrapperRadioItem key={item.value} bgColor={formik.values.status === item.value && item.color}>
                <RadioItem
                  label={t(`paymentStatuses.${item.value}`)}
                  borderColor={item.textColor}
                  bgColor={item.textColor}
                  value={item.value}
                />
              </WrapperRadioItem>
            )
          })}
        </StyledRadio>
        <FormGroup label={labelPrice(t('amount'))} error={formik.errors.amount}>
          <Input {...formik.getFieldProps('amount')} invalid={!!formik.errors.amount} type="number" />
        </FormGroup>
      </SharedModalInputs>
      <MobileModalFooter
        disabled={!formik.values.amount || !formik.values.status}
        onConfirm={() => formik.submitForm()}
        progress={formProgress}
      />
    </AMobileModal>
  )
}
