import styled from 'styled-components'
import { defaultTheme, Typography } from '@agro-club/agroclub-shared'

export const Callout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: ${defaultTheme.color.accentNotify16a};
`

export const PaymentStatusInfoDescription = styled.div`
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textPrimary900};
`

export const PaymentStatusInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 25px;
`

export const PaymentStatusInfoItem = styled.div`
  ${Typography.bodyLarge}
  display: flex;
  align-items: center;
  flex-basis: max-content;
  column-gap: 8px;
  color: ${defaultTheme.color.textPrimary900};
`

export const PaymentStatusInfoMarker = styled.span<{ $color: string; $borderColor?: string }>`
  width: 10px;
  height: 10px;
  background-color: ${({ $color }) => $color};
  border: 1px solid ${({ $borderColor, $color }) => ($borderColor ? $borderColor : $color)};
  border-radius: 50%;
`

export const InputsRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 30px;
`

export const ShortField = styled.div`
  width: 168px;
`
