import { isBidProductParamsEnabled, isFuturesEnabled } from 'env'
import { EXPANDABLE_COLUMN_WIDTH } from 'modules/constants'
import { ColumnData } from 'modules/types'
import { labelQuantity } from 'modules/utils/numbers/formatQuantity'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { useTranslation } from 'react-i18next'
import { DatePeriodFormatted } from 'views/components/DatePeriodFormatted'
import * as renderers from 'views/components/Table/renderers'
import formatNumber from 'modules/utils/numbers/formatNumber'
import PriceCell from './PriceCell'
import ProductCell from './ProductCell'
import SwitchCell from './SwitchCell'
import { isBidWithPricePeriods } from 'modules/domain/bid/utils'

const useColumns = ({ refetch, nested }): ColumnData[] => {
  const { t } = useTranslation('user')

  return [
    nested
      ? {
          width: EXPANDABLE_COLUMN_WIDTH,
          render: () => ' ',
        }
      : null,
    {
      title: t('list.tableHeadersBidPrice.created'),
      width: 100,
      dataIndex: 'created',
      render: renderers.date,
    },
    {
      title: t('list.tableHeadersBidPrice.modified'),
      width: 100,
      dataIndex: 'modified',
      render: renderers.date,
    },
    nested
      ? {
          width: isBidProductParamsEnabled ? 350 : 100,
          render: () => ' ',
        }
      : {
          title: t('list.tableHeadersBidPrice.product'),
          width: isBidProductParamsEnabled ? 350 : 100,
          dataIndex: 'product',
          render: (_value, row) => <ProductCell {...row} />,
        },
    {
      title: t('common:address'),
      autoWidth: isFuturesEnabled ? 308 : 508,
      dataIndex: 'address',
      render: (value) => (nested ? ' ' : value?.address),
    },
    isFuturesEnabled
      ? {
          title: t('common:period'),
          width: 200,
          sorter: false,
          render: (_, period) => <DatePeriodFormatted period={period} />,
        }
      : null,
    {
      title: labelQuantity(),
      width: 120,
      dataIndex: 'quantity',
      align: 'right',
      render: (value) => formatNumber(value),
    },
    {
      title: labelTarif(t('common:price.default')),
      width: 120,
      dataIndex: 'price',
      render: (_, bid) => <PriceCell refetch={refetch} currentValue={bid.price} bid={bid} />,
    },
    {
      title: t('list.tableHeadersBidPrice.publish'),
      align: 'left',
      width: 120,
      render: (_, bid) => {
        if (isBidWithPricePeriods(bid)) {
          return ' '
        }
        return <SwitchCell bid={bid} refetch={refetch} />
      },
    },
  ].filter(Boolean) as ColumnData[]
}

export default useColumns
