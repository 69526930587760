import env, { isBrazil, isUS } from 'env'
import formatPrice from './formatPrice'
import { t } from 'i18next'

const currency = env.REACT_APP_CURRENCY_SYMBOL

const unit = () => {
  if (isBrazil) {
    return t('common:ton')
  }
  if (isUS) {
    return t('common:bsh')
  }
  return t('common:kg')
}

export const labelPriceLogistics = (label?: string, comma = true) => {
  return `${label}${comma ? ', ' : ' '}${currency}/${unit()}`
}

export const formatPriceLogistics = (value: number) => {
  return `${formatPrice(value)} ${currency}/${unit()}`
}
