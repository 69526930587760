import { IconDeals, IconVerifiedUser, InfoBlock, KeyUser, Box } from './styled'
import { User } from 'modules/domain/user/types'
import { useTranslation } from 'react-i18next'
import APhone from 'views/ui/Phone/APhone'
import { FC } from 'react'
import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'

type Props = {
  user: User
}

export const UserInfo: FC<Props> = ({ user }) => {
  const { t } = useTranslation(['user'])

  const { profile } = user
  const isVerifiedUser = profile?.is_verified
  const profileType = profile?.profile_type
  const hasShippedDeals = profile?.has_shipped_deals
  const isSignificant = profile?.is_significant
  const userPhone = user.phone
  const teamName = profile?.team?.name
  const userInn = profile?.inn ? `, ${getPersonalNumberLabel()}: ${profile.inn}` : ''
  const email = user.email

  return (
    <InfoBlock>
      <Box>
        {profileType ? `${t(`profile_type.${profileType}`)}, ` : t('common:unknown')}
        {profile?.full_name}
        {isSignificant && <KeyUser />}
        {isVerifiedUser && <IconVerifiedUser />}
        {hasShippedDeals && <IconDeals />}
        {userInn}
      </Box>
      <Box>
        <APhone phone={userPhone} />
        {email && `, ${email}`}
        {teamName && `, ${teamName}`}
      </Box>
    </InfoBlock>
  )
}
