import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { BlockText, TypeAddress, ItemText } from './styled'
import { AddressTitle } from 'views/components/AddressTitle/AddressTitle'
import generateBidLink from 'modules/domain/bid/generateBidLink'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { UserAddress } from 'modules/domain/userAddress/types'
import { Icons } from '@agro-club/agroclub-shared'
import { ProfileType, User } from 'modules/domain/user/types'
import { EditAddressModal } from './EditAddressModal'
import { apiClient } from 'modules/utils/httpClient'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC, useState } from 'react'
import { useAProgress } from 'hooks/useAProgress'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { AddressMenuData } from './types'
import { AddressItemMenu } from './AddressItemMenu'
import { WarehousesEditModal } from 'views/components/WarehouseModal/WarehousesEditModal'
import { refetchFunc } from 'modules/domain/common/hooks'
import { isBrazil } from 'env'

type Props = {
  addressesRefetch: refetchFunc
  showRemoveIcon?: boolean
  address: UserAddress
  user: User
}

export const AddressItem: FC<Props> = ({ addressesRefetch, showRemoveIcon, address, user }) => {
  const { t } = useTranslation('address')
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [showEditParamsModal, setShowEditParamsModal] = useState<boolean>(false)
  const [removeProgress, removeAddress, error] = useAProgress(
    (addressId: string) => apiClient.delete(endpoints.userAddress(user.id, addressId)),
    {
      eventName: 'removeAddress',
      onSuccess: () => {
        addressesRefetch(true)
      },
    },
  )

  useNotificationProgress(removeProgress, { title: t('delete_success') }, { title: error?.detail })

  const dropdownData: AddressMenuData[] = [
    {
      label: t('showPrices'),
      icon: <Icons.IconCalculator />,
      link: {
        to: generateBidLink(user.profile?.profile_type === ProfileType.seller, address),
        eventName: 'calculate',
      },
      isShown: true,
    },
    {
      label: t('copyAddress'),
      icon: <Icons.IconCopy />,
      isShown: true,
      onClick: () => {
        navigator.clipboard.writeText(address.address)
      },
    },
    {
      label: t('editAddressParams'),
      icon: <Icons.IconGear />,
      isShown: !isBrazil,
      onClick: () => setShowEditParamsModal(true),
    },
    { label: t('editAddress'), icon: <Icons.IconEdit />, isShown: true, onClick: () => setShowEditModal(true) },
    {
      label: t('deleteAddress'),
      icon: <Icons.IconDelete />,
      isShown: showRemoveIcon,
      onClick: () => setShowDeleteModal(true),
    },
  ].filter((item) => item.isShown)

  return (
    <>
      <BorderedItem>
        <BlockText>
          {address.title && <AddressTitle accent>{address.title}</AddressTitle>}
          <ItemText>{address.address}</ItemText>
          <TypeAddress>
            {address.is_main ? t('main') : t('additional')}
            {isBrazil && address.legal_type && `, ${t(`legal_types.${address.legal_type}`)}`}
          </TypeAddress>
        </BlockText>
        <AddressItemMenu data={dropdownData} />
      </BorderedItem>

      {showDeleteModal && (
        <ASharedModal id="RemoveAddressModal" size="small" onClose={() => setShowDeleteModal(false)}>
          <SharedModalTitle>{t('address:addressDeleting')}</SharedModalTitle>
          <SharedModalContent>{t('address:areYouSureToDelete')}</SharedModalContent>
          <ADefaultModalFooter onClose={() => setShowDeleteModal(false)} onConfirm={() => removeAddress(address.id)} />
        </ASharedModal>
      )}
      {showEditModal && (
        <EditAddressModal
          setShowModal={setShowEditModal}
          userId={user.id}
          address={address}
          addressesRefetch={addressesRefetch}
        />
      )}
      {showEditParamsModal && (
        <WarehousesEditModal
          onClose={() => setShowEditParamsModal(false)}
          onSuccess={addressesRefetch}
          userAddress={address}
          userId={user.id}
          userType={user.profile?.profile_type}
        />
      )}
    </>
  )
}
