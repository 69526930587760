import { FC } from 'react'
import { Card } from 'views/components/Card/Card'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { isBrazil, isUS } from 'env'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { formatPriceLogistics } from 'modules/utils/numbers/formatPriceLogistics'
import { useTranslation } from 'react-i18next'
import { TextWithSubText } from 'views/components/TextWithSubText/TextWithSubText'
import { useDealContext } from '../DealContext'
import { getPriceTypeLabel } from 'modules/domain/bid/utils'
import { BidPrice } from 'modules/domain/bid/types'
import { PriceWithBroker } from './PriceWithBroker'
import { MapButtons } from '../MappButtons/MapButtons'
import { useMediaQuery } from 'react-responsive'
import { defaultTheme } from '@agro-club/agroclub-shared'

export const GeneralInfoContentUS: FC = () => {
  const { t } = useTranslation('deal')
  const { deal } = useDealContext()

  let freightRateLabel = `${t('fields.freight_rate_max')}`
  if (isUS) {
    freightRateLabel = t('fields.freight_cost')
  } else if (isBrazil && deal.logistics_rate_source) {
    freightRateLabel = `${t('fields.freight_rate_max')} ${
      deal.logistics_rate_source && isBrazil ? `(${t(`tarifSources.${deal.logistics_rate_source}`)})` : ''
    }`
  }
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })
  return (
    <div style={{ width: '616px' }}>
      <Card.Grid cols={2} maxColumnWidth="300px" columnGap={8}>
        <KeyValue label={t('fields.quantityShort')}>{formatQuantity(deal.quantity)}</KeyValue>
        {!isMobile && (
          <KeyValue label={t('fields.distance')}>
            <MapButtons deal={deal} />
          </KeyValue>
        )}
        <KeyValue label={t('fields.basisToBid')}>
          <div style={{ display: 'flex', gap: '16px' }}>
            <TextWithSubText
              text={
                <PriceWithBroker
                  price={deal.estimated_seller_basis}
                  commision={deal.seller_broker ? deal.seller_broker.commission : undefined}
                  sign="+"
                />
              }
              subText={getPriceTypeLabel(BidPrice.EXW, t)}
            />
            <TextWithSubText
              text={
                <PriceWithBroker
                  price={deal.estimated_seller_basis_delivered}
                  commision={deal.customer_broker ? deal.customer_broker.commission : undefined}
                  sign="-"
                />
              }
              subText={getPriceTypeLabel(BidPrice.CPT, t)}
            />
          </div>
        </KeyValue>
        {isMobile && <MapButtons deal={deal} isMobile />}
        <KeyValue label={freightRateLabel}>{formatPriceLogistics(deal.price_logistics)}</KeyValue>
      </Card.Grid>
    </div>
  )
}
