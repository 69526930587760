import { EnumSelect } from './form/EnumSelect'
import { useTranslation } from 'react-i18next'
import { FormikFieldsType, LoaderType } from 'modules/domain/types'
import { ShortFormControl } from 'views/styled/common'

interface Props {
  formik: FormikFieldsType
  fieldName: string
  required?: boolean
  allowClear?: boolean
}

export const LoaderTypeSelect = ({ formik, fieldName, required, allowClear }: Props) => {
  const { t } = useTranslation('address')

  return (
    <ShortFormControl>
      <EnumSelect
        formik={formik}
        fieldName={fieldName}
        translationNameSpace={'address:loaderTypes'}
        required={required}
        allowClear={allowClear}
        label={t('fields.loader_type')}
        enumType={LoaderType}
        multiple
      />
    </ShortFormControl>
  )
}
