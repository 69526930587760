import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { BlockText, Btns, ItemText } from '../Addresses/styled'
import { UserContact } from 'modules/domain/userContact/types'
import { Icons } from '@agro-club/agroclub-shared'
import { EditContactModal } from './EditContactModal'
import { apiClient } from 'modules/utils/httpClient'
import { User } from 'modules/domain/user/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC, useState } from 'react'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { useAProgress } from 'hooks/useAProgress'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { AButton } from 'views/components/Analytics'
import APhone from 'views/ui/Phone/APhone'

type Props = {
  refetchContacts: () => void
  contact: UserContact
  user: User
}

export const ContactItem: FC<Props> = ({ refetchContacts, contact, user }) => {
  const { t } = useTranslation()
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)

  const [removeProgress, removeContact] = useAProgress(
    (contactId: string) => apiClient.delete(endpoints.userContacts(user.id, contactId)),
    {
      eventName: 'removeContact',
      analyticsPayload: {
        id: contact.id,
      },
      onSuccess: refetchContacts,
    },
  )

  return (
    <>
      <BorderedItem>
        <BlockText>
          <ItemText>
            {contact.full_name}, {contact.position}
          </ItemText>
          <APhone phone={contact.phone} isMobile={contact.is_mobile_phone} />
        </BlockText>
        <Btns>
          <AButton id="editСontact" Icon={Icons.IconEdit} intent="white" onClick={() => setShowEditModal(true)} />
          <AButton
            id="deleteContact"
            onClick={() => setShowRemoveModal(true)}
            progress={removeProgress}
            Icon={Icons.IconDelete}
            intent="white"
          />
        </Btns>
      </BorderedItem>
      {showEditModal && (
        <EditContactModal
          refetchContacts={refetchContacts}
          setShowModal={() => setShowEditModal(false)}
          contact={contact}
          user={user}
        />
      )}

      {showRemoveModal && (
        <ASharedModal id="RemoveContactModal" size="small" onClose={() => setShowRemoveModal(false)}>
          <SharedModalTitle>{t('userContact:remove_contact')}</SharedModalTitle>
          <SharedModalContent>{t('userContact:remove_text')}</SharedModalContent>
          <ADefaultModalFooter
            progress={removeProgress}
            onClose={() => setShowRemoveModal(false)}
            onConfirm={() => removeContact(contact.id)}
          />
        </ASharedModal>
      )}
    </>
  )
}
