import { StyledIconPhoneIn, StyledIconPhoneMissed, StyledIconPhoneOut } from 'views/pages/Calls/CallsTable/styled'
import { RightBlockTable } from '../RightBlockTable/RightBlockTable'
import PlayerProvider from 'views/components/Player/PlayerProvider'
import { ListRequestParams } from 'modules/domain/types'
import Player from 'views/components/Player/Player'
import { FC, useMemo, useState } from 'react'
import useFormatDate from 'hooks/useFormatDate'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { defaultTheme, Text, Typography } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import APhone from 'views/ui/Phone/APhone'

const phoneIcon = {
  in: <StyledIconPhoneIn />,
  out: <StyledIconPhoneOut />,
  missed: <StyledIconPhoneMissed />,
}

type Props = {
  setCountCalls: (count: number | undefined) => void
  countCalls?: number
}

const WrapperIcon = styled.span`
  svg {
    width: 16px;
  }
`
const NoCalls = styled.div`
  color: ${defaultTheme.color.secondaryPlaceholder};
`

const UserPhoneLink = styled(APhone)`
  ${Typography.bodyLarge}
`

export const Calls: FC<Props> = ({ setCountCalls, countCalls }) => {
  const { t } = useTranslation('calls')
  const { id } = useParams()
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({ page: 1, pageSize: 5 })
  const formatDate = useFormatDate()

  const columns = useMemo(() => {
    return [
      {
        width: 20,
        render: (_value, row) => <WrapperIcon>{phoneIcon[row.call_type]}</WrapperIcon>,
      },
      {
        title: '',
        width: 349,
        dataIndex: 'coordinator_name',
        render: (_value, row) => (
          <div>
            <Text typography="bodyLarge">{`${formatDate(row?.call_start, true)}, ${row?.coordinator_name}`}</Text>
            <UserPhoneLink phone={row.client_phone} />
          </div>
        ),
      },
      {
        title: 'record_link',
        dataIndex: 'record_link',
        render: (record_link, row) =>
          record_link && (
            <PlayerProvider>
              <Player file={record_link} duration={row.conversation_duration} itemId={row.id} />
            </PlayerProvider>
          ),
      },
    ]
  }, [formatDate])

  return (
    <>
      <RightBlockTable
        setRequestParams={setListRequestParams}
        endpoint={endpoints.userCalls(id)}
        requestParams={listRequestParams}
        setCount={setCountCalls}
        showHeader={false}
        columns={columns}
      />
      {!countCalls && <NoCalls>{t('no_calls')}</NoCalls>}
    </>
  )
}
