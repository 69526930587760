import { FC, useMemo } from 'react'

import formatPhone from 'helpers/formatPhone'
import { ExternalLink, Text } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'

export type InternalPhoneProps = {
  phone: string
  isMobile?: boolean
  isMobileShort?: boolean
  className?: string
  onClick?: () => void
}

export const InternalPhone: FC<InternalPhoneProps> = ({
  phone,
  className,
  onClick,
  isMobile,
  isMobileShort,
  ...otherProps
}) => {
  const formatted = useMemo(() => formatPhone(phone), [phone])
  const { t } = useTranslation('common')

  return (
    <span>
      <ExternalLink
        className={className}
        href={`tel:${phone}`}
        onClick={(e) => {
          e.stopPropagation()
          onClick?.()
        }}
        {...otherProps}
      >
        {formatted}
      </ExternalLink>

      {isMobile && (
        <Text as="span" color="secondary">
          &nbsp;({t('common:mobile').toLocaleLowerCase()})
        </Text>
      )}
      {isMobileShort && (
        <Text as="span" typography="bodySmall" color="secondary">
          &nbsp;({t('common:mobileShort').toLocaleLowerCase()})
        </Text>
      )}
    </span>
  )
}
