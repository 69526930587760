import { ATableFullPageWithoutData } from 'views/components/Table/ATableFullPageWithoutData'
import { DispatchingMobileList } from './Mobile/DispatchingMobileList'
import { useTableData } from 'modules/domain/common/hooks'
import { defaultTheme } from '@agro-club/agroclub-shared'
import { ListRequestParams } from 'modules/domain/types'
import { useMediaQuery } from 'react-responsive'
import { ColumnData } from 'modules/types'
import { DispatchingType } from '../types'
import { TableWrapper } from '../styled'
import { FC } from 'react'

interface Props {
  setListRequestParams: (params: ListRequestParams) => void
  columnsFunc: (...args: any) => ColumnData[]
  setSelectedDealId: (value: string) => void
  setDrawerIsOpen: (value: boolean) => void
  listRequestParams: ListRequestParams
  datesArray: string[] | undefined
  isFiltersLoaded: boolean
  onSuccess: () => void
  dataEndpoint: string
  pageSize: number
}

export const DispatchingContent: FC<Props> = ({
  setListRequestParams,
  listRequestParams,
  setSelectedDealId,
  setDrawerIsOpen,
  isFiltersLoaded,
  dataEndpoint,
  columnsFunc,
  datesArray,
  onSuccess,
  pageSize,
}) => {
  const { data = [], progress, total } = useTableData<DispatchingType>(dataEndpoint, listRequestParams, isFiltersLoaded)
  const columns = columnsFunc(datesArray, data, onSuccess, setDrawerIsOpen, setSelectedDealId, listRequestParams.sort)

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <TableWrapper>
      {isMobile ? (
        <DispatchingMobileList
          setListRequestParams={setListRequestParams}
          listRequestParams={listRequestParams}
          datesArray={datesArray}
          onSuccess={onSuccess}
          pageSize={pageSize}
          progress={progress}
          total={total}
          data={data}
        />
      ) : (
        <ATableFullPageWithoutData
          listParamsUpdated={setListRequestParams}
          listRequestParams={listRequestParams}
          progress={progress}
          pageSize={pageSize}
          columns={columns}
          total={total}
          rowHoverGray
          data={data}
        />
      )}
    </TableWrapper>
  )
}
