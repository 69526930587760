import styled from 'styled-components'
import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import { FC, MouseEventHandler, ReactNode } from 'react'
import { ButtonProps } from 'antd'

const Button = styled.div`
  ${Typography.buttonLarge}
  display: flex;
  column-gap: 8px;
  align-items: center;
  height: 40px;
  color: ${defaultTheme.color.textPrimary900};
  cursor: pointer;
  padding-left: 4px;

  &:hover {
    background-color: ${defaultTheme.color.secondary8a};
    border-radius: 4px;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${defaultTheme.color.textPrimary900};
    }
  }
`

export interface PopoverButtonProps extends ButtonProps {
  Icon?: ReactNode
  text: ReactNode
  onClick?: MouseEventHandler<HTMLElement>
}

export const PopoverButton: FC<PopoverButtonProps> = ({ text, Icon, onClick, ...otherProps }) => {
  return (
    <Button onClick={onClick} {...otherProps}>
      {Icon}
      <div>{text}</div>
    </Button>
  )
}
