import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { Icons, useHelmet } from '@agro-club/agroclub-shared'
import { ListRequestParams } from 'modules/domain/types'
import { UserFilters } from './UserFilters/UserFilters'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { AddUser } from './ModalUser/AddUser'
import { endpoints } from 'modules/endpoints'
import { useFilters } from 'hooks/useFilter'
import { useColumns } from './useColumns'
import { FC, useContext, useState } from 'react'

import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from './analyticsPlaces'
import ATableFullPage from 'views/components/Table/ATableFullPage'
import { AButton } from 'views/components/Analytics'
import { AuthContext } from 'modules/context/AuthContext'
import { ProfileType } from 'modules/domain/user/types'
import { isUS } from 'env'

const UserList: FC = () => {
  const { t } = useTranslation(['user'])
  useHelmet({ title: t('usersMetaTitle') })
  const { profile: currentUser } = useContext(AuthContext)
  const [visibilityModal, setVisibilityModal] = useState(false)

  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
  })
  let defaultParams: Record<string, unknown> = {
    team: currentUser?.profile?.team?.id && String(currentUser?.profile?.team?.id),
  }
  if (isUS) {
    defaultParams = { ...defaultParams, profile_type: ProfileType.seller, is_active: 'true' }
  }
  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
    defaultParams,
  })
  const columns = useColumns()

  return (
    <APageWrapper page={AnalyticsPages.USERS} place={AnalyticsPlaces.LIST}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs routes={[{ breadcrumbName: t('list.heading') }]} />
            <AButton
              id="add"
              Icon={Icons.IconAdd}
              intent="approve"
              size="medium"
              onClick={() => setVisibilityModal(true)}
            >
              {t('common:add')}
            </AButton>
          </Layout.TopHeader>
          <Layout.PageName>{t('list.heading')}</Layout.PageName>
          <UserFilters
            setListRequestParams={setListRequestParams}
            updateFilterState={updateFilterState}
            listRequestParams={listRequestParams}
            isFiltersLoaded={isFiltersLoaded}
          />
        </Layout.Header>

        <ATableFullPage
          listParamsUpdated={setListRequestParams}
          listRequestParams={listRequestParams}
          isFiltersLoaded={isFiltersLoaded}
          dataEndpoint={endpoints.users()}
          columns={columns}
          rowHoverGray
        />

        {visibilityModal && (
          <AddUser
            listRequestParams={listRequestParams}
            setListRequestParams={setListRequestParams}
            onClose={(status) => setVisibilityModal(status)}
          />
        )}
      </Layout.WrapperContent>
    </APageWrapper>
  )
}

export default UserList
