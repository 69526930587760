import styled from 'styled-components'

import { NewTeamFilter } from 'views/components/TeamFilter/NewTeamFilter'
import { ListRequestParams } from 'modules/domain/types'
import { AssigneesFilter } from 'views/components/AssigneesFilter/AssigneesFilter'
import { FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { CompaniesFilter } from 'views/components/CompaniesFilter/CompaniesFilter'
import { AClearFiltersButton } from 'views/components/Analytics'
import { FC, SetStateAction } from 'react'
import { DashboardSortFilter } from './components/DashboardSortFilter'
import { DashboardTab } from './types'
import { DashboardRegionFilter } from './components/DashboardRegionFilter'
import { UserStatusFilter } from './components/UserStatusFilter'
import { GeneratedFilter } from './components/GeneratedFilter'

const Wrapper = styled.div`
  display: flex;
  background-color: inherit;
`

const StyledFiltersWrapper = styled(FiltersWrapper)`
  margin-bottom: 20px;
`

type DashboardFiltersProps = {
  activeTab: DashboardTab
  listRequestParams: ListRequestParams
  updateCommonFilterState: (filterObj: Record<string, any>) => void
  updateFilterState: (filterObj: Record<string, any>) => void
  resetFilters: (value?: Record<string, any>) => void
  tabSetter: React.Dispatch<SetStateAction<ListRequestParams>>
}

export const DashboardFilters: FC<DashboardFiltersProps> = ({
  updateFilterState,
  updateCommonFilterState,
  resetFilters,
  activeTab,
  listRequestParams,
  tabSetter,
}) => {
  const areFiltersSet = useDisplayClearBtn({ listRequestParams })
  const areFiltersSetActiveTab = useDisplayClearBtn({ listRequestParams })
  const displayClearBtn = areFiltersSet || listRequestParams.sort || areFiltersSetActiveTab

  return (
    <Wrapper>
      <StyledFiltersWrapper>
        <NewTeamFilter value={listRequestParams.filter.team} updateFilterState={updateFilterState} />
        <DashboardRegionFilter
          updateCommonFilterState={updateCommonFilterState}
          updateFilterState={updateFilterState}
          listRequestParams={listRequestParams}
          activeTab={activeTab}
        />
        <AssigneesFilter value={listRequestParams.filter.assignee} updateFilterState={updateCommonFilterState} />
        <CompaniesFilter value={listRequestParams.filter.company} updateFilterState={updateCommonFilterState} />
        <DashboardSortFilter activeTab={activeTab} listRequestParams={listRequestParams} tabSetter={tabSetter} />
        <UserStatusFilter
          activeTab={activeTab}
          listRequestParams={listRequestParams}
          updateFilterState={updateFilterState}
        />
        <GeneratedFilter
          activeTab={activeTab}
          listRequestParams={listRequestParams}
          updateFilterState={updateFilterState}
        />

        {displayClearBtn && <AClearFiltersButton onClick={() => resetFilters()} />}
      </StyledFiltersWrapper>
    </Wrapper>
  )
}
