import { ListFooterWrapper } from 'views/styled/common'
import { AButton } from 'views/components/Analytics'
import { Icons } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { TaskManagerReturnType } from '../useUserTasksManager'
import { ClientPaginationSmall } from 'views/components/ClientSmallPagination/ClientPaginationSmall'

type Props = {
  openTaskModal: (boolean?) => void
  taskManager: TaskManagerReturnType
}

export const TaskBlockFooter = ({ openTaskModal, taskManager }: Props) => {
  const { total, listRequestParams, setListRequestParams } = taskManager
  const { t } = useTranslation('task')

  return (
    <ListFooterWrapper>
      <AButton id="add_task" intent="approve" Icon={Icons.IconAdd} onClick={openTaskModal}>
        {t('common:add')}
      </AButton>
      <ClientPaginationSmall
        total={total}
        currPage={listRequestParams.page}
        setCurrPage={(page) => setListRequestParams((prev) => ({ ...prev, page }))}
      />
    </ListFooterWrapper>
  )
}
