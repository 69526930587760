import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { apiClient } from 'modules/utils/httpClient'
import { User } from 'modules/domain/user/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { CommonFields } from './CommonFields'
import { FC } from 'react'
import { useFormik } from 'formik'
import { useAFormHandler } from 'analytics/hooks'
import { refetchFunc } from 'modules/domain/common/hooks'

type Props = {
  setShowModal: () => void
  refetchContacts: refetchFunc
  user: User
}

type Fields = {
  full_name: string | null
  position: string | null
  phone: string | null
  is_mobile_phone: boolean
}

export const AddContactModal: FC<Props> = ({ setShowModal, user, refetchContacts }) => {
  const { t } = useTranslation()
  const { formProgress, formHandler } = useAFormHandler()

  useNotificationProgress(formProgress, t('userContact:add_success'))

  const formik = useFormik<Fields>({
    initialValues: {
      full_name: null,
      position: null,
      phone: null,
      is_mobile_phone: false,
    },
    onSubmit: formHandler(
      async () => {
        await apiClient.post(endpoints.userContacts(user.id), { ...formik.values })
      },
      {
        onSuccess: async () => {
          await refetchContacts()
          setShowModal()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return <CommonFields id="AddContactModal" formik={formik} setShowModal={setShowModal} progress={formProgress} />
}
