import { DealCondition, MarginsforDeals } from 'modules/domain/deal/types'
import { apiClient } from 'modules/utils/httpClient'
import { useProgress } from 'hooks/useProgress'
import { endpoints } from 'modules/endpoints'
import { useEffect, useState } from 'react'
import { isBrazil, isUS } from 'env'

export const useFetchMarginsAndRevenues = (listRequestParams: any, isFiltersLoaded: boolean, typeDeals: string) => {
  const [margins, setMargins] = useState<MarginsforDeals>()
  const [revenues, setRevenues] = useState<MarginsforDeals>()

  const getMarginsAndRevenues = async () => {
    const { filter } = listRequestParams

    if (isBrazil || isUS) {
      const [marginsResponse, revenuesResponse] = await Promise.all([
        apiClient.get<MarginsforDeals>(endpoints.dealMargins(), filter),
        apiClient.get<MarginsforDeals>(endpoints.dealGmvs(), filter),
      ])
      setMargins(marginsResponse)
      setRevenues(revenuesResponse)
      return
    }

    const marginsResponse = await apiClient.get<MarginsforDeals>(endpoints.dealMargins(), filter)
    setMargins(marginsResponse)
  }

  const [progress, requestData] = useProgress(getMarginsAndRevenues)

  useEffect(() => {
    if (!isFiltersLoaded || typeDeals === DealCondition.closed) return
    requestData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRequestParams, isFiltersLoaded])

  return { progress, margins, revenues }
}
