import { FC } from 'react'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity.tsx'
import { UploadTtnButton } from 'views/pages/LogisticsKR/components/UploadTtnButton/UploadTtnButton.tsx'
import { DASH } from 'modules/constants.ts'
import { Text } from '@agro-club/agroclub-shared'
import { CellRenders } from 'views/components/Table/renderers.tsx'
import { refetchFunc } from 'modules/domain/common/hooks.ts'
import { useTranslation } from 'react-i18next'

enum HasTtnFilesType {
  load = 'has_ttn1_files',
  unload = 'has_ttn2_files',
}

enum QuantityType {
  load = 'load_quantity',
  unload = 'unload_quantity',
}

type Props = {
  trip: any
  date: string
  loadType: 'load' | 'unload'
  ttnRefetch: refetchFunc
  tripsRefetch: () => void
}

export const LoadUploadCell: FC<Props> = ({ date, loadType, ttnRefetch, trip, tripsRefetch }) => {
  const { t } = useTranslation('logisticsKR')

  return (
    <div>
      {CellRenders.date(date)}
      <Text style={{ marginBottom: '4px' }} typography="accentMedium">
        {trip[QuantityType[loadType]] ? formatQuantity(trip[QuantityType[loadType]]) : DASH}
      </Text>
      <UploadTtnButton tripsRefetch={tripsRefetch} loadType={loadType} tripId={trip.id} ttnRefetch={ttnRefetch} />
      <Text typography="bodySmall" color="secondary">
        {trip[HasTtnFilesType[loadType]] && t('details.file_uploaded')}
      </Text>
    </div>
  )
}
